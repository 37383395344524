<template>
  <div>
    <div
      v-for="(stage, index) in chemicalStages"
      :key="index"
      class="stage-container mt-10"
    >
      <div class="">
        <span class="mr-16"> {{ stage.name }} </span>
      </div>
      <v-data-table
        :items="stage.mediaTypeItems"
        :headers="stage.mediaTypeHeaders"
        class="base-table text-center no-header-table bold-table"
        hide-default-footer
        hide-default-header
      >
        <template></template>
        <template
          v-for="option in defaultOptions"
          v-slot:[`item.option${option.title}`]
        >
          <v-select
            :key="option.id"
            class="no-outline"
            :items="mediaTypes"
            item-text="mediaTypeName"
            item-value="id"
            @change="calculateMediaCapacity(option.title, $event, index)"
            v-model="stage[`mediaTypeSelections${option.title}`].mediaType"
            :disabled="
              !layoutsStages[option.title] ||
              index + 1 > layoutsStages[option.title]
            "
            clearable
          />
        </template>
      </v-data-table>
      <v-data-table
        :items="stage.mediaCapacityItems"
        :headers="stage.mediaCapacityHeaders"
        class="base-table text-center no-header-table"
        hide-default-footer
        hide-default-header
      >
        <template
          v-for="option in defaultOptions"
          v-slot:[`item.option${option.title}`]
        >
          <div :key="option.id">
            {{
              stagesCapacity[`capacity${option.title}Stage${index + 1}`]
                | unitConvertor($imperials.MASS)
                | roundFormatter
            }}
          </div>
        </template>
      </v-data-table>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import { defaultOptions } from "../../../store/store.js";
import { units } from "../../../constants/measuringUnits.js";
import {
  mediaCapacityHeaders,
  mediaTypeHeaders,
} from "../../../constants/technicalComparison/headers.js";
import { mediaTypePD } from "../../../constants/formulas";
import { EventBus } from "../../../constants/event-bus.js";
export default {
  props: {
    selectedOptions: Object,
  },
  data() {
    return {
      defaultOptions,
      units,
      weightUnits: {
        [units.Metric]: {
          text: "kg",
        },
        [units.Imperial]: {
          text: "lbs",
        },
      },
    };
  },
  computed: {
    ...mapGetters([
      "mediaTypes",
      "stages",
      "selectedGases",
      "gases",
      "totalGasYear",
    ]),

    selectedWeightUnitMeasure: {
      get() {
        if (this.$store.getters.projectUnitMeasure == "Imperial") {
          return this.weightUnits[units.Imperial].text;
        }
        return this.weightUnits[units.Metric].text;
      },
    },

    stagesNr() {
      return Math.max.apply(
        Math,
        Object.keys(this.selectedOptions).map((item) => {
          return this.selectedOptions[item]?.bedsUsed || 0;
        })
      );
    },

    /**
     * constructs an array of objects that will be used for each stage table, using the default options
     * @returns {array}
     */
    chemicalStages() {
      const stages = [];

      for (let i = 0; i < this.stagesNr; i++) {
        stages[i] = {
          name: `Stage ${i + 1}`,
        };

        const mediaTypeItems = {};
        mediaTypeItems.options = "Media type";
        mediaTypeItems.empty1 = "";

        const mediaCapacityItems = {};
        mediaCapacityItems.options = "Media capacity per stage";
        mediaCapacityItems.empty1 = "[" + this.selectedWeightUnitMeasure + "]";

        this.defaultOptions.forEach((option) => {
          stages[i][`mediaTypeSelections${option.title}`] = {};
          stages[i][`mediaTypeSelections${option.title}`].mediaType =
            this.stages[`stage${i + 1}`][`option${option.title}`]?.mediaType;
          stages[i][`mediaTypeSelections${option.title}`].capacity = "";

          mediaTypeItems[`option${option.title}`] = this.mediaTypes;
          mediaCapacityItems[`option${option.title}`] = "";
        });

        stages[i].mediaTypeItems = [mediaTypeItems];
        stages[i].mediaCapacityItems = [mediaCapacityItems];
        stages[i].mediaTypeHeaders = mediaTypeHeaders;
        stages[i].mediaCapacityHeaders = mediaCapacityHeaders;
      }

      return stages;
    },

    layoutsStages() {
      const layoutStages = {};

      this.defaultOptions.forEach((option) => {
        if (this.selectedOptions[`layout${option.title}`]) {
          layoutStages[option.title] =
            this.selectedOptions[`layout${option.title}`].bedsUsed;
        }
      });

      return layoutStages;
    },

    stagesCapacity() {
      const stagesCapacity = {};
      this.defaultOptions.forEach((option) => {
        for (let i = 1; i <= this.stagesNr; i++) {
          stagesCapacity[`capacity${option.title}Stage${i}`] =
            this.stages[`stage${i}`][`option${option.title}`]?.capacity;
        }
      });

      return stagesCapacity;
    },
  },

  mounted() {
    EventBus.$on(
      "calculateMediaCapacity",
      (currentOptionName, mediaId, index) => {
        this.calculateMediaCapacity(currentOptionName, mediaId, index);
      }
    );
  },

  methods: {
    mediaTypePD,

    /**
     * finds and sets in store the multiple values for a given option: media type name, media type id, 
     media type capacity, stage PD, stagebalance, stage removal capacity
     * also if it receives the null value, resets the stage saved data
     * @param {string} item - A, B, C, D
     * @param {number} mediaTypeId
     * @param {number} stageIndex
     */
    calculateMediaCapacity(item, mediaTypeId, stageIndex) {
      if (mediaTypeId === null) {
        this.$store.dispatch("resetStage", {
          stage: `stage${stageIndex + 1}`,
          option: `option${item}`,
        });

        this.$store.dispatch("resetStageCost", {
          option: item,
          stageNo: stageIndex,
        });
      } else {
        const materialListItem = Object.keys(this.selectedOptions).find(
          (selectedOption) =>
            this.selectedOptions[selectedOption]?.option === item
        );

        const mediaTypeValue = this.mediaTypes.find(
          (mediaType) => mediaType.id === mediaTypeId
        )?.mediaTypeValue;

        const mediaTypeName = this.mediaTypes.find(
          (mediaType) => mediaType.id === mediaTypeId
        )?.mediaTypeName;

        const itemBedDepthPerStage =
          this.selectedOptions[materialListItem].bedDepthPerElem;
        const itemfaceVelocityAtBed =
          this.selectedOptions[materialListItem].faceVelocityAtBed;

        const itemPDFactorPerElement =
          this.selectedOptions[materialListItem].pdFactorPerElement;

        const PD = this.mediaTypePD(
          itemfaceVelocityAtBed,
          itemBedDepthPerStage,
          itemPDFactorPerElement,
          mediaTypeName
        );

        const capacity =
          this.selectedOptions[materialListItem].bedVolPerStage *
          mediaTypeValue;

        if (this.chemicalStages[stageIndex]) {
          this.$set(
            this.chemicalStages[stageIndex][`mediaTypeSelections${item}`],
            "capacity",
            capacity
          );
        }

        const balanceAndRemovalCapacity =
          this.calculateBalanceForOptionPerStage(
            `stage${stageIndex + 1}`,
            `option${item}`,
            mediaTypeName,
            capacity,
            stageIndex + 1
          );

        this.$store.dispatch("setStages", {
          stage: `stage${stageIndex + 1}`,
          option: `option${item}`,
          value: {
            optionId: this.selectedOptions[materialListItem].id,
            mediaTypeName: mediaTypeName || "",
            mediaType: mediaTypeId || "",
            capacity: capacity || "",
            PD: PD ? Math.round(PD) : "",
            balance: balanceAndRemovalCapacity
              ? balanceAndRemovalCapacity.balance
              : "",
            removalCapacity: balanceAndRemovalCapacity
              ? balanceAndRemovalCapacity.removal
              : "",
          },
        });

        this.stagesCapacity[`capacity${item}Stage${stageIndex + 1}`] = capacity;

        this.$store.dispatch("setStageCost", {
          option: `option${item}`,
          stageNo: stageIndex,
        });
      }
    },

    /**
     * computes the value of the stage BALANCE for a given option, using stage removal kg value and adsorp capacity
     * calls functions to get the removal kg and adsorp capacity
     * @param {string} stage
     * @param {string} option
     * @param {string} mediaTypeName
     * @param {number} capacity
     * @param {number} stageIndex
     * @returns {object} - removal capacity, balance
     */
    calculateBalanceForOptionPerStage(
      stage,
      option,
      mediaTypeName,
      capacity,
      stageIndex
    ) {
      const balance = {
        gas0: "-",
        gas1: "-",
        gas2: "-",
        gas3: "-",
      };

      let gasBalance = 0;
      const removal = this.calculateRemovalKgForOptionPerStage(
        option,
        mediaTypeName,
        capacity,
        stageIndex
      );

      const result = this.calculateAdsorpCap(mediaTypeName, stageIndex, option);
      Object.keys(balance).forEach((gas, index) => {
        if (removal) {
          gasBalance = result.relevantGases[gas] - removal[gas];
          if (!isNaN(gasBalance)) {
            if (stageIndex === 1) {
              if (result.relevantGases[gas] === 0 && removal[gas] === 0) {
                Vue.set(balance, gas, this.getInlet(index));
              } else {
                Vue.set(balance, gas, Math.round(gasBalance * 100) / 100);
              }
            } else {
              if (result.relevantGases[gas] === 0 && removal[gas] === 0) {
                Vue.set(
                  balance,
                  gas,
                  this.stages[`stage${stageIndex - 1}`][option].balance[gas] -
                    removal[gas]
                );
              } else {
                Vue.set(balance, gas, Math.round(gasBalance * 100) / 100);
              }
            }
          }
        }
      });

      return { balance: balance, removal: removal };
    },

    /**
     * computes the value of REMOVAL KG for each possible existing gas,
     * using the percentage of the prorated gases, the calc capacity and the adsorp capacity
     * calls functions for getting the prorated gas percentage and adsorp capacity
     * @param {string} option
     * @param {string} mediaTypeName
     * @param {number} capacity
     * @param {number} stageIndex
     * @returns {object}
     */
    calculateRemovalKgForOptionPerStage(
      option,
      mediaTypeName,
      capacity,
      stageIndex
    ) {
      const result = this.calculateGasesProratedForOptionPerStage(
        mediaTypeName,
        stageIndex,
        option
      );
      const mediaQty = Math.round(capacity);
      let capac;
      let removal;
      const calcCapacForGases = {
        gas0: "-",
        gas1: "-",
        gas2: "-",
        gas3: "-",
      };

      const removalKg = {
        gas0: "-",
        gas1: "-",
        gas2: "-",
        gas3: "-",
      };

      Object.keys(calcCapacForGases).forEach((gas) => {
        capac = result.adsorpCap[gas] * result.gasesProrated[gas];

        if (!isNaN(capac)) {
          Vue.set(calcCapacForGases, gas, parseFloat(capac));
        }
      });

      Object.keys(removalKg).forEach((gas) => {
        if (!isNaN(calcCapacForGases[gas])) {
          removal = mediaQty * calcCapacForGases[gas];
          Vue.set(removalKg, gas, parseFloat(removal));
        }
      });

      return removalKg;
    },

    /**
     * computes the percentage of GASES PRORATED of each existent gas,
     * using adsorp capaity and relevant gases value/ sum of relevant gases for each gas
     * calls a function for getting the values of relevant gases and the sum of relevant gases
     * @param {string} mediaTypeName
     * @param {number} stageIndex
     * @param {string} option
     * @returns {object}
     */
    calculateGasesProratedForOptionPerStage(mediaTypeName, stageIndex, option) {
      const result = this.calculateAdsorpCap(mediaTypeName, stageIndex, option);
      let gasProratedPercent = 0;

      const gasesProrated = {
        gas0: "-",
        gas1: "-",
        gas2: "-",
        gas3: "-",
      };

      Object.keys(gasesProrated).forEach((gas) => {
        if (result.relevantGases[gas] > 0) {
          gasProratedPercent =
            result.relevantGases[gas] / result.relevantGasesSum;
        } else {
          gasProratedPercent = 0;
        }

        if (!isNaN(gasProratedPercent)) {
          Vue.set(gasesProrated, gas, parseFloat(gasProratedPercent));
        }
      });

      return {
        gasesProrated: gasesProrated,
        adsorpCap: result.adsorpCap,
      };
    },

    /**
     * calculates the values of ADSORP CAPACITY and RELEVANT GASES for each gas, using relevant gases sum from previous stage
     * calls function for getting relevant gases sum from previous stage
     * @param {string} mediaTypeName
     * @param {number} stageIndex
     * @param {string} option
     * @returns {object}
     */
    calculateAdsorpCap(mediaTypeName, stageIndex, option) {
      let mediaTypePercentage = null;
      let relevantGasesSum = 0;
      const adsorpCap = {
        gas0: "-",
        gas1: "-",
        gas2: "-",
        gas3: "-",
      };
      const relevantGases = {
        gas0: "-",
        gas1: "-",
        gas2: "-",
        gas3: "-",
      };

      Object.keys(this.selectedGases).forEach((selectedGas, index) => {
        const gasObj = this.gases.find(
          (gas) => gas.id === this.selectedGases[selectedGas].gasData.id
        );
        if (gasObj) {
          mediaTypePercentage = gasObj[mediaTypeName];
          if (mediaTypePercentage) {
            const relevantGasesNr = this.returnRelevantGases(
              mediaTypePercentage,
              index,
              stageIndex,
              option
            );

            Vue.set(adsorpCap, selectedGas, mediaTypePercentage / 100);
            Vue.set(relevantGases, selectedGas, relevantGasesNr);

            if (!isNaN(relevantGasesNr)) {
              if (parseFloat(relevantGasesNr) > 0) {
                relevantGasesSum =
                  relevantGasesSum + parseFloat(relevantGasesNr);
              } else {
                relevantGasesSum = relevantGasesSum + 0;
              }
            }
          } else {
            Vue.set(adsorpCap, selectedGas, 0);
            Vue.set(relevantGases, selectedGas, 0);
          }
        }
      });

      return {
        adsorpCap: adsorpCap,
        relevantGases: relevantGases,
        relevantGasesSum: parseFloat(relevantGasesSum),
      };
    },

    /**
     * gets the balance values from the previous stage
     *  and returns them as the relevant gases for the current stage
     * for the first stage calls a function for getting the total gas/year
     *  to return it as the relevant gases
     * @param {number} adsorpCap
     * @param {number} gasIndex
     * @param {number} stageIndex
     * @param {string} option
     * @returns {number/string}
     */
    returnRelevantGases(adsorpCap, gasIndex, stageIndex, option) {
      if (adsorpCap > 0) {
        if (stageIndex === 1) {
          return this.getInlet(gasIndex);
        } else {
          if (this.stages[`stage${stageIndex - 1}`][option].balance) {
            return this.stages[`stage${stageIndex - 1}`][option].balance[
              `gas${gasIndex}`
            ];
          } else {
            return "-";
          }
        }
      }
      return "-";
    },

    /**
     * finds total gas/year for a given gas
     * @param {number} gasIndex
     * @returns {number/string}
     */
    getInlet(gasIndex) {
      if (this.totalGasYear[`gas${gasIndex}`] !== "") {
        return parseFloat(this.totalGasYear[`gas${gasIndex}`]);
      }

      return "-";
    },

    valueSimpleTransformations(value, constant) {
      return this.$options.filters.unitConvertor(value, constant);
    },
  },
};
</script>
<style lang="scss" scoped>
.stage-container {
  margin-left: 5rem;
}

.dropdown-table-margins {
  margin-left: 5rem;
}
</style>
