import GasAndMediaSelectionVue from "@/components/gasAndMediaSelection/GasAndMediaSelection.vue";
import AirflowDefinition from "@/components/projectOverview/AirflowDefinition.vue";
import CommercialComparisonVue from "@/components/projectOverview/CommercialComparison.vue";
import ProjectData from "@/components/projectOverview/ProjectData.vue";
import ReportsVue from "@/components/projectOverview/ReportsPage.vue";
import SystemOptionsVue from "@/components/projectOverview/SystemOptions.vue";
import TechnicalComparisonVue from "@/components/projectOverview/technicalComparison/TechnicalComparison.vue";

export const allSteps = [
  {
    title: "Project Data",
    name: "projectData",
    iconActive: require("@/assets/svg/FFT_Icon_ChemGuide_ProjectData.svg"),
    icon: require("@/assets/svg/FFT_Icon_ChemGuide_ProjectData_grau.svg"),
    component: ProjectData,
    isActive: false,
  },
  {
    title: "Airflow definition",
    name: "airflowDefinition",
    iconActive: require("@/assets/svg/FFT_Icon_ChemGuide_Airflow.svg"),
    icon: require("@/assets/svg/FFT_Icon_ChemGuide_Airflow_grau.svg"),
    component: AirflowDefinition,
    isActive: false,
  },
  {
    title: "Gas and media selection",
    name: "gasSelection",
    iconActive: require("@/assets/svg/FFT_Icon_ChemGuide_GasSelection.svg"),
    icon: require("@/assets/svg/FFT_Icon_ChemGuide_GasSelection_grau.svg"),
    component: GasAndMediaSelectionVue,
    isActive: false,
  },
  {
    title: "System options",
    name: "systemOptions",
    iconActive: require("@/assets/svg/FFT_Icon_ChemGuide_UnitsCatalogue.svg"),
    icon: require("@/assets/svg/FFT_Icon_ChemGuide_UnitsCatalogue_grau.svg"),
    component: SystemOptionsVue,
    isActive: false,
  },
  {
    title: "Technical comparison",
    name: "techComparison",
    iconActive: require("@/assets/svg/FFT_Icon_ChemGuide_TechnicalComparison.svg"),
    icon: require("@/assets/svg/FFT_Icon_ChemGuide_TechnicalComparison_grau.svg"),
    component: TechnicalComparisonVue,
    isActive: false,
  },
  {
    title: "Commercial comparison",
    name: "commercialComparison",
    iconActive: require("@/assets/svg/FFT_Icon_ChemGuide_CommercialComparison.svg"),
    icon: require("@/assets/svg/FFT_Icon_ChemGuide_CommercialComparison_grau.svg"),
    component: CommercialComparisonVue,
    isActive: false,
    isHidden: true,
  },
  {
    title: "Reports",
    name: "reports",
    iconActive: require("@/assets/svg/FFT_Icon_ChemGuide_CustomerView.svg"),
    icon: require("@/assets/svg/FFT_Icon_ChemGuide_CustomerView_grau.svg"),
    component: ReportsVue,
    isActive: false,
  },
];
