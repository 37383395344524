<template>
  <div class="d-flex my-10">
    <div
      class="download-box mr-7 d-flex flex-column"
      v-for="reportButton in downloadButtons"
      :key="reportButton.id"
    >
      <div v-html="reportButton.boxTitle" class="box-title bold"></div>
      <div v-html="reportButton.description" class="mb-4"></div>
      <a
        :id="reportButton.htmlId"
        :download="reportButton.pdfTitle"
        style="display: none"
      />

      <a
        href="javascript:void(0)"
        :title="reportButton.pdfTitle"
        class="mb-0 mr-0 ml-auto mt-auto"
      >
        <custom-button
          type="primary"
          :disabled="
            downloadingTechnicalSolution || downloadingTechnicalComparison
          "
          :title="reportButton.buttonTitle"
          @click="handleDownloadPdf(reportButton.id, reportButton.htmlId)"
      /></a>
    </div>
    <div class="download-box mr-7 d-flex flex-column" v-if="drawingLink">
      <div class="box-title bold">Equipment’s drawing</div>
      <p>Unit Model: {{ this.model }}</p>
      <p>Drawing: {{ this.drawingModel }}</p>
      <a :href="drawingLink" target="_blank" class="mb-0 mr-0 ml-auto mt-auto"
        ><custom-button type="primary" title="Open equipment’s drawing"
      /></a>
    </div>
    <div :value="notification"></div>
  </div>
</template>
<script>
import CustomButton from "../base/CustomButton";
import { enumReports } from "../../constants/reports";
import Vue from "vue";

import { mapGetters } from "vuex";

export default {
  name: "ReportsPage",

  components: {
    CustomButton,
  },

  data() {
    return {
      enumReports,
      stopDownload: false,
      casingMaterials: [],
      downloadingTechnicalSolution: false,
      downloadingTechnicalComparison: false,
      reports: {
        [enumReports.TS]: {
          title: "TECHNICAL SOLUTION SHEET",
        },
        [enumReports.TC]: {
          title: "TECHNICAL COMPARISON",
        },
        [enumReports.CC]: {
          title: "COMMERCIAL COMPARISON",
        },
      },
    };
  },

  computed: {
    ...mapGetters([
      "unitSelectedOption",
      "commercialComparison",
      "comparisonOptions",
      "airflow",
      "stages",
      "mediaTypes",
      "operatingTimeYear",
      "selectedGases",
      "gases",
      "selectedGasConcentrations",
      "totalGasYear",
      "projectCustomer",
      "projectName",
      "projectLocation",
      "filtersData",
      "gasesGClass",
      "project",
      "projects",
      "masterlist",
      "projectHasChanges",
      "projectUnitMeasure",
      "dwgsCosts",
      "account",
      "recommendedAirChangesPerHour",
      "selectedInletType",
      "selectedOutletType",
      "selectedPositionInlet",
      "selectedPositionOutlet",
      "selectedPd",
      "btsFactor",
      "selectedCassingMaterialsCosts",
      "selectedLayouts",
      "remarks",
      "projectId",
    ]),

    airflowType() {
      return this.metric ? "m³/h" : "ft³/min";
    },

    volumeType() {
      return this.metric ? "m³" : "ft³";
    },

    weightType() {
      return this.metric ? "kg" : "lbs";
    },

    velocityType() {
      return this.metric ? "m/s" : "ft/min";
    },

    pressureType() {
      return this.metric ? "Pa" : "in w.g.";
    },

    lengthMetric() {
      return this.metric ? true : false;
    },

    constants() {
      return {
        AIRFLOW: this.metric
          ? 1
          : this.$imperialConstants[this.$imperials.AIRFLOW],
        PRESSURE: this.metric
          ? 1
          : this.$imperialConstants[this.$imperials.PRESSURE],
        VELOCITY: this.metric
          ? 1
          : this.$imperialConstants[this.$imperials.VELOCITY],
        MASS: this.metric ? 1 : this.$imperialConstants[this.$imperials.MASS],
      };
    },

    metric() {
      if (this.projectUnitMeasure === "Metric") {
        return true;
      }

      return false;
    },

    downloadButtons() {
      return [
        {
          id: 2,
          buttonTitle: this.downloadingTechnicalComparison
            ? "Downloading..."
            : "Download",
          htmlId: "download-technical-comparison",
          pdfTitle: `${this.projectName} - Technical Comparison.pdf`,
          description: "Table comparing technical performance up to 4 options",
          boxTitle: " Technical Comparison ",
        },
        {
          id: 1,
          buttonTitle: this.downloadingTechnicalSolution
            ? "Downloading..."
            : "Download",
          htmlId: "download-technical-solution",
          pdfTitle: `${this.projectName} - ${this.model} - Technical Solution Sheet.pdf`,
          description:
            "Report of the selected option showing details about the Equipment, Particle filtration, Chemical Filtration and estimated life time based on the provided environment conditions.",
          boxTitle: "Technical Solution Sheet",
        },
        // {
        //   id: 3,
        //   buttonTitle: "Download commercial comparison report",
        //   htmlId: "download-commercial-comparison",
        //   pdfTitle: "Commercial Comparison.pdf",
        // },
      ];
    },

    drawingLink() {
      const dwgsItem = this.dwgsCosts.find(
        (item) =>
          item.concatenateModel ===
          this.commercialComparison[`option${this.unitSelectedOption}`]?.code
      );

      const drawingLink = dwgsItem?.drawingLink;
      if (drawingLink) {
        return drawingLink;
      }
      return false;
    },

    notification() {
      if (this.projectHasChanges === true) {
        return Vue.$toast.warning(
          "Please refresh data from Technical Comparison step"
        );
      }
      return null;
    },

    model() {
      const masterlistItem = this.selectedLayouts.find(
        (item) => item.option === this.unitSelectedOption
      );

      return masterlistItem?.unitModel;
    },

    drawingModel() {
      const modelCode = this.dwgsCosts.find(
        (item) =>
          item.concatenateModel ===
          this.commercialComparison[`option${this.unitSelectedOption}`]?.code
      );

      return modelCode?.salesDrawing;
    },
  },

  mounted() {
    this.getCasingMaterials();
  },

  methods: {
    handleDownloadPdf(id, htmlId) {
      let data = {};
      let fileName = "";
      const masterlistItem = this.selectedLayouts.find(
        (item) => item.option === this.unitSelectedOption
      );

      const reportToDownload = Object.keys(this.enumReports).find(
        (key) => this.enumReports[key] === id
      );

      const modelDescription = masterlistItem?.modelDescription;
      const systemType = masterlistItem?.systemType;

      let technicalSolutionTitle = "";

      if (modelDescription) {
        technicalSolutionTitle = `${modelDescription}(${systemType})`;
      } else {
        technicalSolutionTitle = `${systemType}(${systemType})`;
      }

      if (id === enumReports.TS) {
        if (!this.unitSelectedOption && this.stopDownload) {
          this.$toast.warning(
            "Please select a unit from the Technical Comparison step"
          );
          return;
        } else {
          data = this.constructDataForTechnicalSolution();
        }
      } else if (id === enumReports.TC) {
        data = this.constructDataForTechnicalComparison();
      } else if (id === enumReports.CC) {
        data = this.constructDataForCommercialComparison();
      }

      if (!this.stopDownload) {
        if (id === enumReports.TS) {
          fileName = `${this.projectName} - ${this.model} - Technical Solution Sheet.pdf`;
          this.downloadingTechnicalSolution = true;
        } else if (id === enumReports.TC) {
          fileName = `${this.projectName} - Technical Comparison.pdf`;
          this.downloadingTechnicalComparison = true;
        }

        this.$store
          .dispatch("postPdfData", {
            model: id,
            technicalSolutionTitle: technicalSolutionTitle,
            title: this.reports[enumReports[reportToDownload]].title,
            output: data,
            fileName,
            projectId: this.projectId,
          })
          .then((res) => {
            console.log(enumReports[reportToDownload]);
            this.downloadingTechnicalSolution = false;
            this.downloadingTechnicalComparison = false;
            this.downloadPdf(res, htmlId);
          });
      } else {
        this.$toast.warning(
          "Please refresh data from Gas and Media selection and Technical Comparison steps"
        );
      }
    },

    downloadPdf(res, id) {
      const string = "data:application/octet-stream;base64," + res;
      const dlnk = document.getElementById(id);

      dlnk.href = string;
      dlnk.click();
    },

    /**
     * creates the object that will contains project options and settings that has to be displayed in pdf - TECHNICAL SOLUTION
     * @returns {Object}
     */
    constructDataForTechnicalSolution() {
      const selectedItem = this.getSelectedComparisonItem();

      const dateOptions = {
        month: "short",
        day: "numeric",
        year: "numeric",
      };

      const masterlistItem = this.selectedLayouts.find(
        (item) => item.option === this.unitSelectedOption
      );

      let pictureFile = null;
      let casingMaterial = this.getSelectedCasingMaterial();
      let dwgItem = "";
      if (masterlistItem) {
        if (casingMaterial) {
          dwgItem = this.dwgsCosts.find(
            (item) =>
              item.concatenateModel ===
              masterlistItem?.unitModel + "-" + this.getSelectedCasingMaterial()
          );
        } else {
          dwgItem = this.dwgsCosts.find(
            (item) => item.concatenateModel === masterlistItem?.unitModel
          );
        }

        if (dwgItem) {
          pictureFile = dwgItem.pictureFile;
        }
      }

      const data = {
        projectName: this.projectName,
        modelImage: pictureFile || null,
        salesDrawing: this.drawingModel,
        customer: this.projectCustomer,
        date: new Date().toLocaleString("default", dateOptions),
        accountName: this.account?.name || "-",
        location: this.projectLocation,
        modelLocation: masterlistItem?.modelLocation || "-",
        model: masterlistItem?.unitModel || "-",
        airChangesPerHour: this.recommendedAirChangesPerHour || "-",
        flowRate: this.roundedUnitTransform(
          this.airflow,
          this.$imperials.AIRFLOW
        ),
        prefilterModel: selectedItem?.prefilterModel || "-",
        finalFilterModel: selectedItem?.finalFilterModel || "-",
        prefilterSize: this.getFilterSize(selectedItem?.prefilterModel) || "-",
        finalFilterSize:
          this.getFilterSize(selectedItem?.finalFilterModel) || "-",
        preFilterQuantity: selectedItem?.prefilterQty || "-",
        finalFilterQuantity: selectedItem?.finalFilterQty || "-",
        preFilterClass: selectedItem?.prefilterClass || "-",
        finalFilterClass: selectedItem?.finalFilterClass || "-",
        preFilterAirflow: this.roundedUnitTransform(
          this.airflow,
          this.$imperials.AIRFLOW
        ),
        finalFilterAirflow: this.roundedUnitTransform(
          this.airflow,
          this.$imperials.AIRFLOW
        ),
        preFilterFinalPD: this.roundedUnitTransform(
          selectedItem?.prefilterConsideredFinalPD,
          this.$imperials.PRESSURE
        ),
        preFilterImgUrl: selectedItem?.prefilterImgUrl || "-",
        finalFilterFinalPD: this.roundedUnitTransform(
          selectedItem?.finalFilterConsideredFinalPD,
          this.$imperials.PRESSURE
        ),
        gpfElementImgUrl: selectedItem?.generalInfo?.gpfElementImgUrl || "-",
        gpfElement: selectedItem?.generalInfo?.gpfElement || "-",
        finalFilterImgUrl: selectedItem?.finalImgUrl || "-",
        stages: selectedItem?.generalInfo?.chemicalStages || "-",
        element: selectedItem?.generalInfo?.elementsPerStage || "-",
        residence:
          selectedItem?.generalInfo?.residenceTimePerBed?.toFixed(2) || "-",
        faceVelocityAtBed: this.floatUnitTransform(
          selectedItem.generalInfo?.faceVelocityAtBed,
          this.$imperials.VELOCITY,
          2
        ),
        totalResidence:
          selectedItem?.totalResidenceTimePerUnit?.toFixed(2) || "-",
        opTimeY: this.operatingTimeYear || "-",
        selectedGases: this.getSelectedGasesData() || "-",
        calculations: this.getSelectedGasesCalculations() || "-",
        inletType:
          this.selectedInletType?.inletType || this.selectedInletType || "-",
        outletType:
          this.selectedOutletType?.outletType || this.selectedOutletType || "-",
        inletPosition:
          this.selectedPositionInlet?.position ||
          this.selectedPositionInlet ||
          "-",
        outletPosition:
          this.selectedPositionOutlet?.position ||
          this.selectedPositionOutlet ||
          "-",
        casingMaterial: this.getSelectedCasingMaterial() || "-",
        casingType: this.getSelectedCasingType() || "-",
        equipmentPositioning: "-",
        fan: selectedItem?.generalInfo?.fanType || "-",
        pdMeasurement: this.selectedPd?.pdMeasurement || this.selectedPd || "-",
        pressureDrop: "-",
        airflowType: this.airflowType,
        volumeType: this.volumeType,
        weightType: this.weightType,
        velocityType: this.velocityType,
        pressureType: this.pressureType,
        lengthMetric: this.lengthMetric,
        safetyFactor: this.btsFactor,
        remarks: this.remarks,
      };

      ["stage1", "stage2", "stage3", "stage4"].forEach((key) => {
        const stageData = this.getStageData(key);
        data[key] = {
          mediaType: stageData?.mediaType || "-",
          qty:
            this.projectUnitMeasure === "Metric" && stageData?.qty
              ? stageData?.qty?.toFixed(0)
              : stageData?.qty
              ? (stageData?.qty * this.constants.MASS).toFixed(0)
              : "-",
          imgUrl: stageData?.imgUrl || "-",
        };
      });

      return data;
    },

    /**
     * creates the object that will contain the data saved on the technical comparison page - TECHNICAL COMPARISON
     * @returns {Object}
     */
    constructDataForTechnicalComparison() {
      let maxStageNr = 0;
      const data = {
        pdfInfo: {},
        options: [],
        stages: [],
        threeStagesClass: "",
        airflowType: this.airflowType,
        volumeType: this.volumeType,
        velocityType: this.velocityType,
        pressureType: this.pressureType,
        lengthType: this.lengthType,
      };

      const dateOptions = {
        month: "short",
        day: "numeric",
        year: "numeric",
      };

      Object.keys(this.comparisonOptions).forEach((option) => {
        let item = this.comparisonOptions[option];

        if (!item.generalInfo && !item.airflowCheck) {
          this.stopDownload = true;
        }

        if (item.generalInfo?.chemicalStages > maxStageNr) {
          maxStageNr = item.generalInfo?.chemicalStages;
        }

        data.pdfInfo = {
          projectName: this.projectName,
          accountName: this.account?.name || "-",
          customer: this.projectCustomer,
          date: new Date().toLocaleString("default", dateOptions),
          location: this.projectLocation,
        };

        data.options.push({
          option: option.replace("option", ""),
          casingMaterial: this.getSelectedCasingMaterial(option) || "-",
          constructionModel: item.generalInfo?.constructionModel || "-",
          filterMatrix: item.generalInfo?.filterMatrix || "-",
          chosenAirflow: this.roundedUnitTransform(
            item.generalInfo?.chosenAirflow,
            this.$imperials.AIRFLOW
          ),
          gpfElement: item.generalInfo?.gpfElement || "-",
          elementsPerStage: item.generalInfo?.elementsPerStage || "-",
          chemicalStages: item.generalInfo?.chemicalStages || "-",
          residenceTimePerBed:
            item.generalInfo?.residenceTimePerBed?.toFixed(2) || "-",
          faceVelocityAtBed: this.floatUnitTransform(
            item.generalInfo?.faceVelocityAtBed,
            this.$imperials.VELOCITY,
            2
          ),
          prefilterModel: item.prefilterModel || "-",
          prefilterQty: item.prefilterQty || "-",
          prefilterClass: item.prefilterClass || "-",
          prefilterConsideredFinalPD: this.metric
            ? this.roundedUnitTransform(
                item.prefilterConsideredFinalPD,
                this.$imperials.PRESSURE
              )
            : this.floatUnitTransform(
                item.prefilterConsideredFinalPD,
                this.$imperials.PRESSURE,
                2
              ),
          finalFilterModel: item.finalFilterModel || "-",
          finalFilterQty: item.finalFilterQty || "-",
          finalFilterClass: item.finalFilterClass || "-",
          finalFilterConsideredFinalPD: this.metric
            ? this.roundedUnitTransform(
                item.finalFilterConsideredFinalPD,
                this.$imperials.PRESSURE
              )
            : this.floatUnitTransform(
                item.finalFilterConsideredFinalPD,
                this.$imperials.PRESSURE,
                2
              ),
          totalResidenceTimePerUnit: !isNaN(item.totalResidenceTimePerUnit)
            ? item.totalResidenceTimePerUnit.toFixed(2)
            : "-" || "-",
          estimatedFinalPD: this.metric
            ? this.roundedUnitTransform(
                item.estimatedFinalPD,
                this.$imperials.PRESSURE
              )
            : this.floatUnitTransform(
                item.estimatedFinalPD,
                this.$imperials.PRESSURE,
                2
              ),
          airflowCheck: item.airflowCheck || " -",
          finalPDCheck: item.finalPDCheck || "-",
        });
      });

      Object.keys(this.stages).forEach((stage, index) => {
        if (index < maxStageNr) {
          if (Object.keys(this.stages[stage]).length !== 0) {
            data.stages.push({
              index: index + 1,
              mass: this.weightType,
              options: [
                {
                  mediaTypeName:
                    this.stages[stage].optionA?.mediaTypeName || "-",
                  capacity: this.floatUnitTransform(
                    this.stages[stage].optionA?.capacity,
                    this.$imperials.MASS,
                    0
                  ),
                },
                {
                  mediaTypeName:
                    this.stages[stage].optionB?.mediaTypeName || "-",
                  capacity: this.floatUnitTransform(
                    this.stages[stage].optionB?.capacity,
                    this.$imperials.MASS,
                    0
                  ),
                },
                {
                  mediaTypeName:
                    this.stages[stage].optionC?.mediaTypeName || "-",
                  capacity: this.floatUnitTransform(
                    this.stages[stage].optionC?.capacity,
                    this.$imperials.MASS,
                    0
                  ),
                },
                {
                  mediaTypeName:
                    this.stages[stage].optionD?.mediaTypeName || "-",
                  capacity: this.floatUnitTransform(
                    this.stages[stage].optionD?.capacity,
                    this.$imperials.MASS,
                    0
                  ),
                },
              ],
            });
          }
        }
      });

      data.threeStagesClass =
        data.stages.length === 3 ? "margin-for-three-stages" : "";

      return data;
    },

    roundedUnitTransform(value, constant) {
      return this.$options.filters.roundFormatter(
        this.$options.filters.unitConvertor(value, constant)
      );
    },

    floatUnitTransform(value, constant, decimals) {
      return this.$options.filters.decimalsFormatter(
        this.$options.filters.unitConvertor(value, constant),
        decimals
      );
    },

    /**
     * not implemented
     * @returns {object}
     */
    constructDataForCommercialComparison() {
      const data = {
        options: [
          { option: "A" },
          { option: "B" },
          { option: "C" },
          { option: "D" },
        ],
      };

      return data;
    },

    /**
     * finds casing material for a specific option || for the selected option from technical comparison page
     * @param {string} option (A, B, C, D)
     * @returns {string} - CS, SS, DW
     */
    getSelectedCasingMaterial(option) {
      if (option) {
        return this.selectedCassingMaterialsCosts[option]?.casingMaterial;
      } else {
        return this.$store.getters[
          `selectedCasingMaterial${this.unitSelectedOption}`
        ];
      }
    },

    /**
     * fetching all casing materials
     * @returns {array}
     */
    getCasingMaterials() {
      this.$store
        .dispatch("fetchCasingMaterials")
        .then((response) => {
          this.casingMaterials = response.data;
        })
        .catch((err) => {
          console.log(err);
          this.casingMaterials = [];
        });
    },

    /**
     * contructs the casing type description
     * @returns {string} -  casing material full name + material description
     */
    getSelectedCasingType() {
      const casingMaterial = this.getSelectedCasingMaterial();
      let foundMaterial = this.casingMaterials.find(
        (el) => el.materialCode === casingMaterial
      );
      if (foundMaterial) {
        const { material, materialType } = foundMaterial;
        return `${materialType} - ${material}`;
      }

      return "-";
    },

    getSelectedComparisonItem() {
      const finalSelectedOption =
        this.comparisonOptions[`option${this.unitSelectedOption}`];
      return finalSelectedOption;
    },

    getFilterSize(model) {
      if (this.projectUnitMeasure === "Metric") {
        return this.filtersData?.find((filter) => filter.model == model)
          ?.sizeMM;
      } else {
        return this.filtersData?.find((filter) => filter.model == model)
          ?.sizeIN;
      }
    },

    getStageData(stage) {
      let mediaTypeName =
        this.stages[stage][`option${this.unitSelectedOption}`]?.mediaTypeName;

      const qty =
        this.stages[stage][`option${this.unitSelectedOption}`]?.capacity;

      if (Object.keys(this.stages[stage]).length !== 0) {
        return {
          mediaType: mediaTypeName,
          qty: qty,
          imgUrl: `${mediaTypeName}.jpg`,
        };
      }

      return "-";
    },

    /**
     * finds for each gas selected on gas&media page: concetrations(mg/mg3, ppm, ppb) and the G class
     * @returns {array}
     */
    getSelectedGasesData() {
      const gasesArray = [];
      this.stopDownload = false;
      const empty = "";

      Object.keys(this.selectedGases).forEach((selectedGas) => {
        if (
          Object.keys(this.selectedGases[selectedGas].gasData).length > 0 &&
          this.selectedGases[selectedGas].unit !== empty
        ) {
          const mgm3 = this.selectedGasConcentrations[selectedGas]["mg/m3"];
          const ppm = this.selectedGasConcentrations[selectedGas]["ppm"];
          const ppb = this.selectedGasConcentrations[selectedGas]["ppb"];
          if (mgm3 === empty || ppm === empty || ppb === empty) {
            this.stopDownload = true;
          } else {
            gasesArray.push({
              name: this.selectedGases[selectedGas].gasData.gasName,
              mg: parseFloat(mgm3)?.toFixed(2) || "-",
              ppm: parseFloat(ppm)?.toFixed(2) || "-",
              ppb: parseInt(ppb) || "-",
              gClass: this.gasesGClass[selectedGas] || "-",
            });
          }
        }
      });

      return gasesArray;
    },

    /**
     * finds for each gas selected on gas&media page: gasName, total inlet quantity,
     * system's removal capacity and the time for breakthrough
     * @returns {array} - build of objects with the gas and its details
     */
    getSelectedGasesCalculations() {
      const gasesCalculations = [];
      if (this.stopDownload) {
        return;
      }

      Object.keys(this.selectedGases).forEach((selectedGas) => {
        if (Object.keys(this.selectedGases[selectedGas].gasData).length > 0) {
          if (
            this.comparisonOptions[`option${this.unitSelectedOption}`]
              .totalRemovalCapacity
          ) {
            this.stopDownload = false;
            gasesCalculations.push({
              name: this.selectedGases[selectedGas].gasData.gasName,
              inlet: this.floatUnitTransform(
                parseFloat(this.totalGasYear[selectedGas]),
                this.$imperials.MASS,
                2
              ),
              removalCapacity: this.floatUnitTransform(
                parseFloat(
                  this.comparisonOptions[`option${this.unitSelectedOption}`]
                    ?.totalRemovalCapacity[selectedGas]
                ),
                this.$imperials.MASS,
                2
              ),
              breakthrough:
                this.comparisonOptions[`option${this.unitSelectedOption}`]
                  .totalTimeBreakThrough[selectedGas],
            });
          } else {
            this.stopDownload = true;
          }
        }
      });

      return gasesCalculations;
    },
  },
};
</script>
<style lang="scss" scoped>
.download-box {
  width: 26rem;
  background-color: $basic-blue;
  padding: 20px;
  color: $white;

  .box-title {
    text-align: center;
    margin-bottom: 30px;
  }
}
</style>
