<template>
  <div class="d-flex flex-column">
    <label v-if="label" class="input-label">{{
      label + labelSemicolon()
    }}</label>
    <input
      class="text-input"
      type="text"
      :style="styleObject"
      :value="value"
      :maxlength="maxlength"
      @input="handleInputValueChange"
      :disabled="disabled === true"
    />
  </div>
</template>
<script>
export default {
  name: "TextInput",

  props: {
    value: [String, Number],
    maxlength: Number,
    label: String,
    width: String,
    backgroundColor: String,
    disabled: Boolean,
    notSemicolon: Boolean,
  },

  data() {
    return {
      styleObject: {
        width: this.width || "auto",
        backgroundColor: this.backgroundColor || "auto",
      },
    };
  },

  methods: {
    handleInputValueChange(event) {
      const newValue = event.target.value;
      this.$emit("change", newValue);
    },

    labelSemicolon() {
      if (this.hideSemicolon === true) {
        return "";
      } else {
        return ":";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.text-input {
  height: 36px;
  background-color: rgba(214, 225, 236, 1);
  border: 1px solid $border-grey;
  padding: 0 10px;
}

.input-label {
  color: $dark-blue;
}
</style>
