import axios from "axios";
import { currencyEnum } from "../../constants/currencies";
import {
  annualizeCost,
  elementCosts,
  filterCost,
  mediaCost,
  unitCost,
} from "../../constants/formulas.js";
import handleError from "../../constants/handleError.js";

const state = {
  prices: [],
  selectedCassingMaterial: {
    optionA: "",
    optionB: "",
    optionC: "",
    optionD: "",
  },
  commercialComparison: {
    optionA: {
      isSelected: false,
      code: "-",
      unitCost: 0,
      elementsCost: 0,
      mediaCostStage1: 0,
      mediaCostStage2: 0,
      mediaCostStage3: 0,
      mediaCostStage4: 0,
      preFiltersCost: 0,
      finalFiltersCost: 0,
      totalInvestmentCost: 0,
      filtersAndPelletsCost: 0,
      changeoutYear: 0,
      annualizesCalc: 0,
    },
    optionB: {
      isSelected: false,
      code: "-",
      unitCost: 0,
      elementsCost: 0,
      mediaCostStage1: 0,
      mediaCostStage2: 0,
      mediaCostStage3: 0,
      mediaCostStage4: 0,
      preFiltersCost: 0,
      finalFiltersCost: 0,
      totalInvestmentCost: 0,
      filtersAndPelletsCost: 0,
      changeoutYear: 0,
      annualizesCalc: 0,
    },
    optionC: {
      isSelected: false,
      code: "-",
      unitCost: 0,
      elementsCost: 0,
      mediaCostStage1: 0,
      mediaCostStage2: 0,
      mediaCostStage3: 0,
      mediaCostStage4: 0,
      preFiltersCost: 0,
      finalFiltersCost: 0,
      totalInvestmentCost: 0,
      filtersAndPelletsCost: 0,
      changeoutYear: 0,
      annualizesCalc: 0,
    },
    optionD: {
      isSelected: false,
      code: "-",
      unitCost: 0,
      elementsCost: 0,
      mediaCostStage1: 0,
      mediaCostStage2: 0,
      mediaCostStage3: 0,
      mediaCostStage4: 0,
      preFiltersCost: 0,
      finalFiltersCost: 0,
      totalInvestmentCost: 0,
      filtersAndPelletsCost: 0,
      changeoutYear: 0,
      annualizesCalc: 0,
    },
  },
  currencies: [],
  selectedCurrency: currencyEnum.EUR,
  exchangeRate: null,
  completePrices: [],
};

const getters = {
  selectedCassingMaterial: (state) => state.selectedCassingMaterial,
  commercialComparison: (state) => state.commercialComparison,
  prices: (state) => state.prices,
  currencies: (state) => state.currencies,
  selectedCurrency: (state) => state.selectedCurrency,
  exchangeRate: (state) => state.exchangeRate,
  completePrices: (state) => state.completePrices,
};

const mutations = {
  setCompletePrices(state, payload) {
    state.completePrices = payload;
  },

  setSelectedCassingMaterial(state, payload) {
    state.selectedCassingMaterial = payload;
  },

  setCommercialComparison(state, payload) {
    state.commercialComparison = payload;
  },

  setPrices(state, payload) {
    state.prices = payload;
  },

  setCurrencies(state, payload) {
    state.currencies = payload;
  },

  setSelectedCurrency(state, payload) {
    state.selectedCurrency = payload;
  },

  setExchangeRate(state, payload) {
    state.exchangeRate = payload;
  },
};

const actions = {
  setSelectedCassingMaterial(context, payload) {
    const { value, option: selOption } = payload;
    const option = `option${selOption}`;
    const newSelectedCassingMaterial = context.getters.selectedCassingMaterial;
    newSelectedCassingMaterial[option] = value;

    context.commit("setSelectedCassingMaterial", newSelectedCassingMaterial);
  },

  setChangeoutYear(context, payload) {
    const { value, option: selOption } = payload;
    const option = `option${selOption}`;
    const newAnnualizedCalc = context.getters.commercialComparison;

    newAnnualizedCalc[option].changeoutYear = value;

    context.commit("setCommercialComparison", newAnnualizedCalc);
  },

  setAnnualizesCalc(context, payload) {
    const { value, option: selOption } = payload;
    const option = `option${selOption}`;
    const newAnnualizedCalc = context.getters.commercialComparison;

    newAnnualizedCalc[option].annualizesCalc = value;

    context.commit("setCommercialComparison", newAnnualizedCalc);
  },

  setUnitCost(context, payload) {
    const { option } = payload;

    const {
      selectedCassingMaterialsCosts,
      commercialComparison: newCommercialComparison,
    } = context.getters;

    const previousUnitCost = newCommercialComparison[option].unitCost;

    const selectedOption = selectedCassingMaterialsCosts[option];
    const selectedUnitCost = unitCost(selectedOption);

    newCommercialComparison[option].unitCost = selectedUnitCost;
    newCommercialComparison[option].totalInvestmentCost +=
      selectedUnitCost - previousUnitCost;
    context.commit("setCommercialComparison", newCommercialComparison);
  },

  setCode(context, payload) {
    const { option, value } = payload;
    const { commercialComparison: newCommercialComparison } = context.getters;

    if (
      value.length !== 0 &&
      value[0] &&
      value[1] &&
      value[0] !== "" &&
      value[1] !== ""
    ) {
      newCommercialComparison[`option${option}`].code = value.join("-");
    } else {
      newCommercialComparison[`option${option}`].code = value.join("");
    }

    context.commit("setCommercialComparison", newCommercialComparison);
  },

  setElementsCost(context, payload) {
    const { option, type } = payload;
    const optionItem = option.replace("option", "");
    const { selectedLayouts, commercialComparison: newCommercialComparison } =
      context.getters;

    const previousElementCost = newCommercialComparison[option].elementsCost;

    if (type === "insert") {
      const selectedOption = selectedLayouts.filter(
        (layout) => layout.option === optionItem
      );

      const selectedElementCost = elementCosts(selectedOption[0]);
      newCommercialComparison[option].elementsCost = selectedElementCost;
      newCommercialComparison[option].totalInvestmentCost +=
        selectedElementCost - previousElementCost;

      newCommercialComparison[option].filtersAndPelletsCost +=
        selectedElementCost - previousElementCost;
    } else {
      newCommercialComparison[option].totalInvestmentCost -=
        newCommercialComparison[option].elementsCost;

      newCommercialComparison[option].filtersAndPelletsCost -=
        newCommercialComparison[option].elementsCost;
      newCommercialComparison[option].elementsCost = 0;
    }

    context.commit("setCommercialComparison", newCommercialComparison);
  },

  setStageCost(context, payload) {
    const { option, stageNo } = payload;
    const { stages, commercialComparison: newCommercialComparison } =
      context.getters;
    const selectedStage = stages[`stage${stageNo + 1}`];
    if (selectedStage[option]) {
      const previousStageCost =
        newCommercialComparison[option][`mediaCostStage${stageNo + 1}`];

      const stageCost = mediaCost(selectedStage, option);

      newCommercialComparison[option][`mediaCostStage${stageNo + 1}`] =
        parseFloat(stageCost);

      newCommercialComparison[option].totalInvestmentCost +=
        parseFloat(stageCost) - parseFloat(previousStageCost);

      newCommercialComparison[option].filtersAndPelletsCost +=
        parseFloat(stageCost) - parseFloat(previousStageCost);
    } else {
      newCommercialComparison[option].totalInvestmentCost += 0;
      newCommercialComparison[option].filtersAndPelletsCost += 0;
    }
    context.commit("setCommercialComparison", newCommercialComparison);
  },

  resetStageCost(context, payload) {
    const { option, stageNo } = payload;
    const optionText = `option${option}`;
    const { commercialComparison: newCommercialComparison } = context.getters;

    const previousStageCost =
      newCommercialComparison[optionText][`mediaCostStage${stageNo + 1}`];

    const stageCost = 0;

    newCommercialComparison[optionText][`mediaCostStage${stageNo + 1}`] =
      parseFloat(stageCost);

    newCommercialComparison[optionText].totalInvestmentCost -=
      parseFloat(previousStageCost);

    newCommercialComparison[optionText].filtersAndPelletsCost -=
      parseFloat(previousStageCost);

    context.commit("setCommercialComparison", newCommercialComparison);
  },

  setFiltersCost(context, payload) {
    const { option, prefilter } = payload;
    const {
      comparisonOptions,
      commercialComparison: newCommercialComparison,
      filtersData,
    } = context.getters;

    const selectedComparison = comparisonOptions[option];
    const selectedFilterCost = filterCost(
      selectedComparison,
      filtersData,
      prefilter
    );

    const filterType =
      prefilter === true ? "preFiltersCost" : "finalFiltersCost";
    const previousFilter = newCommercialComparison[option][filterType];
    newCommercialComparison[option][filterType] =
      parseFloat(selectedFilterCost);

    newCommercialComparison[option].totalInvestmentCost +=
      parseFloat(selectedFilterCost) - parseFloat(previousFilter);

    newCommercialComparison[option].filtersAndPelletsCost +=
      parseFloat(selectedFilterCost) - parseFloat(previousFilter);

    context.commit("setCommercialComparison", newCommercialComparison);
  },

  setAnnualCost(context, payload) {
    const { option, value } = payload;
    const { commercialComparison: newCommercialComparison } = context.getters;

    const filterAndPelletsCost =
      newCommercialComparison[`option${option}`].filtersAndPelletsCost;

    const annualCost = annualizeCost(filterAndPelletsCost, value);
    newCommercialComparison[`option${option}`].annualizesCalc =
      parseFloat(annualCost);

    context.commit("setCommercialComparison", newCommercialComparison);
  },

  setIsOptionSelected(context, payload) {
    const { isSelected, option } = payload;
    const { commercialComparison: newCommercialComparison } = context.getters;

    if (isSelected === false) {
      newCommercialComparison[`option${option}`] = {
        isSelected: false,
        code: "-",
        unitCost: 0,
        elementsCost: 0,
        mediaCostStage1: 0,
        mediaCostStage2: 0,
        mediaCostStage3: 0,
        mediaCostStage4: 0,
        preFiltersCost: 0,
        finalFiltersCost: 0,
        totalInvestmentCost: 0,
        filtersAndPelletsCost: 0,
        changeoutYear: 0,
        annualizesCalc: 0,
      };
    } else {
      newCommercialComparison[`option${option}`].isSelected = isSelected;
    }
    context.commit("setCommercialComparison", newCommercialComparison);
  },

  async fetchPrices({ commit, getters }) {
    try {
      const region = getters.projectRegion;
      const res = await axios.get("/api/prices", {
        params: { branch: region },
      });

      commit("setPrices", res.data);
    } catch (err) {
      console.log(err, "err");
    }
  },

  async fetchCurrencies(context) {
    try {
      const res = await axios.get("/api/currencies");
      if (context.state.currencies.length === 0) {
        context.commit("setCurrencies", res.data);
      }
    } catch (err) {
      console.log(err, "err");
    }
  },

  updateSelectedCurrency(context, payload) {
    context.commit("setSelectedCurrency", payload);
  },

  updateExchangeRate(context, payload) {
    context.commit("setExchangeRate", payload);
  },

  async getCompletedPrices(context, branch) {
    console.log(context);
    try {
      const res = await axios.get("/api/complete-prices", {
        params: { branch },
      });
      console.log(res.data);

      return res;
    } catch (err) {
      console.log(err, "err");
    }
  },

  async updateCompletePrices(context, payload) {
    console.log(context);
    try {
      let response = undefined;
      if (payload["id"] || payload["id"] !== "") {
        response = await axios.put(`/api/complete-prices`, payload);
      }
      return response.data;
    } catch (err) {
      const errorMessage = handleError(err)?.data;
      return errorMessage;
    }
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
