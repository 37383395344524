<template>
  <div class="mt-8 justify-space-between">
    <div>
      <v-data-table
        :headers="gasesTableOnlyHeaders"
        class="base-table text-center top-bordered-table removal-table header-only"
        hide-default-footer
      >
        <template v-slot:[`header.gasBreak`]="{ header }">
          <div class="d-flex flex-column gasBreak-container">
            <div class="py-2">{{ header.text }}</div>
            <v-row>
              <v-col v-for="(stage, i, index) in stages" :key="i"
                ><p>Stage {{ index + 1 }}</p></v-col
              >
            </v-row>
          </div>
        </template>
      </v-data-table>
      <v-data-table
        :items="gasesTableItems"
        :headers="gasesTableHeaders"
        class="base-table text-center gases-table top-bordered-table"
        hide-default-footer
        hide-default-header
      >
        <template v-slot:[`item.inlet`]="{ item }">
          <p
            :class="gasesRowClass(item.inlet)"
            class="d-flex justify-center align-center"
          >
            {{
              item.inlet | unitConvertor($imperials.MASS) | decimalsFormatter(2)
            }}
          </p>
        </template>
        <template v-slot:[`item.empty1`]="{ item }">
          <p
            :class="gasesRowClass(item.empty1)"
            class="d-flex justify-center align-center"
          >
            {{
              item.empty1
                | unitConvertor($imperials.MASS)
                | decimalsFormatter(2)
            }}
          </p>
        </template>
        <template v-slot:[`item.empty2`]="{ item }">
          <p
            :class="gasesRowClass(item.empty2)"
            class="d-flex justify-center align-center"
          >
            {{
              item.empty2
                | unitConvertor($imperials.MASS)
                | decimalsFormatter(2)
            }}
          </p>
        </template>
        <template v-slot:[`item.empty3`]="{ item }">
          <p
            :class="gasesRowClass(item.empty3)"
            class="d-flex justify-center align-center"
          >
            {{
              item.empty3
                | unitConvertor($imperials.MASS)
                | decimalsFormatter(2)
            }}
          </p>
        </template>
        <template v-slot:[`item.empty4`]="{ item }">
          <p
            :class="gasesRowClass(item.empty4)"
            class="d-flex justify-center align-center"
          >
            {{
              item.empty4
                | unitConvertor($imperials.MASS)
                | decimalsFormatter(2)
            }}
          </p>
        </template>
        <template v-slot:[`item.totalRemovalCap`]="{ item }">
          <p>
            {{
              item.totalRemovalCap
                | unitConvertor($imperials.MASS)
                | decimalsFormatter(1)
            }}
          </p>
        </template>
        <template v-slot:[`item.timeForBreakThrough`]="{ item }">
          <p>
            {{ item.timeForBreakThrough }}
          </p>
        </template>
      </v-data-table>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

import { gasesTableHeaders } from "../../../constants/technicalComparison/headers.js";
export default {
  data() {
    return {
      gasesTableHeaders,
      totalRemovalCapacityObj: {
        gas1: "-",
        gas2: "-",
        gas3: "-",
        gas4: "-",
      },

      totalTimeBreakThrough: {
        gas1: "-",
        gas2: "-",
        gas3: "-",
        gas4: "-",
      },
    };
  },

  computed: {
    ...mapGetters([
      "projectUnitMeasure",
      "selectedGases",
      "totalGasYear",
      "unitSelectedOption",
      "stages",
      "btsFactor",
      "gases",
    ]),

    metric() {
      if (this.projectUnitMeasure === "Metric") {
        return true;
      }

      return false;
    },

    weightUnit() {
      if (this.metric) {
        return "kg";
      }
      return "lbs";
    },

    gasesTableOnlyHeaders() {
      return [
        {
          text: "Gas",
          align: "center",
          class: "align bold",
          value: "gas",
          sortable: false,
          width: "15%",
        },
        {
          text: "Inlet " + this.weightUnit + "/y",
          align: "center",
          class: "align bold",
          value: "inlet",
          sortable: false,
          width: "9%",
        },
        {
          text: "Gas breakthrough per year per stage [" + this.weightUnit + "]",
          align: "center",
          class: "align bold",
          value: "gasBreak",
          width: "28%",
          sortable: false,
        },
        {
          text: "Total removal capacity [" + this.weightUnit + "/y]",
          align: "center",
          class: "align bold",
          value: "totalRemovalCap",
          width: "15%",
          sortable: false,
        },
        {
          text: "Time for break through [months]",
          align: "center",
          class: "align bold",
          value: "timeForBreakThrough",
          width: "15%",
          sortable: false,
        },
      ];
    },

    /**
     * constructs the gases array that will be displayed in table (using each gas selected in gas&media page and using the selected option from Unit selected dropdown)
     * @returns {array} - gases array of objects (gasName, inlet, balance for stage 1, balance for stage 2, balance for stage 3, total removal capacity, time for break through )
     */
    gasesTableItems() {
      const gasesArray = [];
      Object.keys(this.selectedGases).forEach((selectedGas, index) => {
        const gasName = this.gases.find(
          (gas) => gas.id === this.selectedGases[selectedGas].gasData.id
        )?.gasName;

        if (gasName) {
          gasesArray.push({
            gas: gasName,
            inlet: this.getInlet(index),
            empty1: this.getGasStage(index, this.stages.stage1),
            empty2: this.getGasStage(index, this.stages.stage2),
            empty3: this.getGasStage(index, this.stages.stage3),
            empty4: this.getGasStage(index, this.stages.stage4),
            totalRemovalCap: this.calculateTotalRemovalCapacity(index),
            timeForBreakThrough: this.calculateTimeForBreakThrough(index),
          });
        }
      });

      return gasesArray;
    },
  },

  methods: {
    /**
     * returns the value of total gas/year for a given gas
     * @param {number} gasIndex - index of the given gas
     * @returns {number} the value of the total gas/year of the given gas || "-"
     */
    getInlet(gasIndex) {
      if (this.totalGasYear[`gas${gasIndex}`] !== "") {
        return parseFloat(this.totalGasYear[`gas${gasIndex}`]);
      }

      return "-";
    },

    /**
     * returns the balance for a given gas from a specific stage
     * @param {number} gasIndex - index of the given gas
     * @param {object} stage - given stage object
     * @returns {number/string}  - balance value || in case the balance < 0 returns 0 as a string || "-"
     */
    getGasStage(gasIndex, stage) {
      let gasBalance;

      if (Object.keys(stage).length > 0) {
        const selectedOptionStage = stage[`option${this.unitSelectedOption}`];
        const stageBalance = selectedOptionStage?.balance;

        gasBalance =
          selectedOptionStage && stageBalance
            ? stageBalance[`gas${gasIndex}`]
            : "";
      }

      if (gasBalance && !isNaN(gasBalance)) {
        if (parseFloat(gasBalance) >= 0) {
          return gasBalance;
        } else {
          return "0.00";
        }
      }

      return "-";
    },

    /**
     * calculates for all stages the total removal capacity for each gas and adds the value in totalRemovalCapacityObj
     * @param {Number} index specifies each gas index
     * @returns {Number} || {String}
     */
    calculateTotalRemovalCapacity(index) {
      let totalRemovalCapacity = 0;

      Object.values(this.stages).forEach((stage) => {
        const selectedOptionStage = stage[`option${this.unitSelectedOption}`];
        const stageRemovalCapacity = selectedOptionStage?.removalCapacity;

        if (
          Object.keys(stage).length > 0 &&
          selectedOptionStage &&
          stageRemovalCapacity
        ) {
          const removalCapacity =
            totalRemovalCapacity + stageRemovalCapacity[`gas${index}`];

          // TODO:is this really needed?
          if (!isNaN(removalCapacity)) {
            totalRemovalCapacity =
              totalRemovalCapacity + stageRemovalCapacity[`gas${index}`];
          }
        }
      }, this);

      if (!isNaN(totalRemovalCapacity)) {
        if (this.unitSelectedOption !== "") {
          this.totalRemovalCapacityObj[`gas${index}`] = totalRemovalCapacity;
          this.$emit(
            "totalRemovalCapacityObjChange",
            this.totalRemovalCapacityObj
          );

          return totalRemovalCapacity;
        }
      }

      return "-";
    },

    /**
     * calculates, for a given gas, time for break through (in months) using a standard formula
     * @param {number} index - given gas index
     * @returns {number} - time for break through if its value is < 60 || 60 || "-"
     */
    calculateTimeForBreakThrough(index) {
      const totalRemovalCap = this.calculateTotalRemovalCapacity(index);
      const breakthroughSafetyFactor = parseInt(this.btsFactor);
      const inlet = this.getInlet(index);
      const fiveYrsInMonths = 60;

      let timeForBreakthrough =
        (((totalRemovalCap * breakthroughSafetyFactor) / inlet) * 12) / 100;

      if (timeForBreakthrough > fiveYrsInMonths) {
        timeForBreakthrough = fiveYrsInMonths;
      }

      if (!isNaN(timeForBreakthrough)) {
        if (this.unitSelectedOption !== "") {
          this.totalTimeBreakThrough[`gas${index}`] =
            Math.round(timeForBreakthrough);
          this.$emit("totalTimeBreakThroughChange", this.totalTimeBreakThrough);
        }

        return Math.round(timeForBreakthrough);
      }
      return "-";
    },

    /**
     * returns different classes for each value from table
     * @param {number} value - gas table value
     * @returns {string} - css class
     */
    gasesRowClass(value) {
      if (!isNaN(value)) {
        if (this.isRowNotOk(value)) {
          return "red-row";
        }
        return "green-row";
      }

      return "";
    },

    isRowNotOk(value) {
      return parseFloat(value) !== 0.0;
    },
  },
};
</script>
<style lang="scss" scoped>
.red-row {
  background-color: #f2dcdb;
  width: 100%;
  height: 100%;
  color: #ef3f30;
}

.green-row {
  background-color: #ebf1de;
  width: 100%;
  height: 100%;
  color: #3dad48;
}

::v-deep {
  .gases-table.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    padding-left: 0rem;
  }

  .gases-table.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    padding: 0 0;
  }

  .gases-table tbody tr td:first-child {
    width: 15%;
  }

  .gases-table tbody tr td:nth-child(2) {
    width: 9%;
  }
  .gases-table tbody tr td:nth-child(7),
  .gases-table tbody tr td:nth-child(8) {
    width: 15%;
  }

  .gases-table tbody tr td:nth-child(3),
  .gases-table tbody tr td:nth-child(4),
  .gases-table tbody tr td:nth-child(5),
  .gases-table tbody tr td:nth-child(6) {
    width: 7%;
  }

  .base-table.top-bordered-table tbody {
    border-top: $base-border;
  }

  .removal-table tr:first-child {
    font-family: $bold-font-family;
  }

  .removal-table.theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:first-child
    > td:not(.v-data-table__mobile-row) {
    border-bottom: 0;
  }

  .header-only.theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:first-child {
    display: none;
  }

  .header-only thead th:first-child {
    visibility: hidden;
  }

  .v-data-table.header-only.top-bordered-table {
    table thead {
      th {
        &:nth-child(3) {
          padding: 0;
        }
      }
    }
  }

  .gasBreak-container {
    div:first-child {
      color: $white;
      font-size: 1rem;
    }

    div.row {
      margin: 0;
    }

    div.col {
      border-top: $base-border;
      padding: 0.4rem;

      &:not(:first-child) {
        border-left: $base-border;
      }

      p {
        color: $white;
        font-size: 1rem;
      }
    }
  }
}
</style>
