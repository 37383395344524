import storeGasAndMedia from "../gasAndMediaSelection";
import { GasModel } from "../gasAndMediaSelection";
const getDefaultState = () => {
  return {
    gases: [],
    selectedGases: new GasModel(),
    gasUnits: ["mg/m3", "ppm", "ppb"],
    selectedGasUnit: GasModel,
    selectedGasConcentrations: {
      gas0: {
        "mg/m3": "",
        ppm: "",
        ppb: "",
      },
      gas1: {
        "mg/m3": "",
        ppm: "",
        ppb: "",
      },
      gas2: {
        "mg/m3": "",
        ppm: "",
        ppb: "",
      },
      gas3: {
        "mg/m3": "",
        ppm: "",
        ppb: "",
      },
    },
    totalGasYear: GasModel,
    opHours: 24,
    opDays: 7,
    opWeeks: 50,
    operatingTimeYear: 8400,
    concentration: GasModel,

    gClassThresholds: [],
    gasesGClass: {},
    removalCapacity: [],
    weightOfMedia: [],
  };
};

// initial state
const state = getDefaultState();

const mutations = {
  resetState() {
    Object.assign(storeGasAndMedia.state, getDefaultState());
  },
};

const actions = {
  resetGasAndMediaState({ commit }) {
    commit("resetState");
  },
};

export default {
  state,
  getters: {},
  actions,
  mutations,
};
