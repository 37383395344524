<template>
  <v-container>
    <div class="pt-15">
      <div class="d-flex mb-4">
        <custom-button
          class="ml-auto mr-6"
          type="secondary"
          title="Create new project"
          @click="handleClick"
        />
        <custom-button
          title="Refresh"
          type="secondary"
          @click="handleRefresh"
        ></custom-button>
      </div>
      <div class="mb-4 projects">Projects ({{ tableData.length }})</div>
      <div class="component-header">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          class="search-bar mb-3"
        />
        <div class="table-buttons d-flex">
          <custom-link
            v-for="(btn, index) in tableButtons"
            :text="btn.name"
            :icon="btn.icon"
            :key="index"
            :class="`ml-5 table-button ${btn.name.toLowerCase()}`"
            color="#00A6E2"
            @click="handleTableBtnClick(btn.method)"
          >
          </custom-link>
        </div>
      </div>
      <v-data-table
        dense
        :headers="headers"
        :items="tableData"
        item-key="projectId"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        class="projectsTable base-table text-center"
        show-select
        single-select
        :search="search"
        :hide-default-footer="true"
        disable-pagination
        v-model="selected"
        :mobile-breakpoint="0"
        fixed-header
        height="55vh"
      >
        <template v-slot:[`item.createdOn`]="{ item }">
          <div class="date">
            <span>{{ formatDate(item.createdOn) }}</span>
          </div>
        </template>
        <template v-slot:[`item.projectType`]="{ item }">
          <span>{{ item.projectType }}</span>
        </template>
      </v-data-table>
    </div>
    <custom-modal
      v-model="sortModalOpen"
      :buttons="dialogButtons"
      @confirmEvent="
        selectedSortOption === 1 ? sortAscending() : sortDescending()
      "
      title="Sort"
      class="custom-sort-modal"
      width="20rem"
    >
      <div class="sort-modal">
        <div class="d-flex justify-space-between align-center">
          <span class="bold">Sort order</span>
          <div class="header-button mr-4">
            <custom-link text="Reset" @click="resetSortOptions" />
          </div>
        </div>
        <v-radio-group v-model="selectedSortOption">
          <v-radio
            v-for="sortOption in sortOrderOptions"
            :key="sortOption.value"
            :label="sortOption.text"
            :value="sortOption.value"
            color="accent"
          ></v-radio>
        </v-radio-group>
        <span class="bold">Sort by</span>
        <v-radio-group v-model="selectedSortBy">
          <v-radio
            v-for="sortOption in sortByOptions"
            :key="sortOption.value"
            :label="sortOption.text"
            :value="sortOption.value"
            color="accent"
          ></v-radio>
        </v-radio-group>
      </div>
    </custom-modal>
    <custom-modal
      v-model="filterModalOpen"
      :buttons="dialogButtons"
      :title="
        selectedFilterBy ? `Filter by: ${selectedFilterBy.text}` : 'Filter'
      "
      :showBackIcon="selectedFilterBy ? true : false"
      @clickOnBackIcon="selectedFilterBy = null"
      @confirm="handleConfirmFilter"
      width="20rem"
      height="50vh"
    >
      <div class="filter-modal" v-if="!selectedFilterBy">
        <div class="d-flex justify-space-between align-center">
          <span class="bold">Filter by</span>
          <div class="header-button mr-4">
            <custom-link text="Reset" @click="resetFilters" />
          </div>
        </div>
        <ul>
          <li
            v-for="filterOption in filterOptions"
            :key="filterOption.value"
            @click="handleSelectFilter(filterOption)"
          >
            {{ filterOption.text }}
          </li>
        </ul>
      </div>
      <custom-filter-list
        v-else
        :options="selectedFilterOptions"
        :label="selectedFilterBy.value"
        @updateOption="setFilterSelectedOption"
        :filteredValues="
          filters[`${selectedFilterBy.value}`]
            ? filters[`${selectedFilterBy.value}`]
            : []
        "
      />
    </custom-modal>
  </v-container>
</template>

<script>
import CustomButton from "../components/base/CustomButton.vue";
import CustomLink from "../components/base/CustomLink.vue";
import filterIcon from "@/assets/svg/filter_icon.svg";
import router from "../router";
import CustomModal from "../components/base/CustomModal.vue";
import CustomFilterList from "../components/base/CustomFilterList.vue";
import { EventBus } from "../constants/event-bus.js";
import { mapGetters } from "vuex";

export default {
  name: "HomePage",

  components: {
    CustomButton,
    CustomLink,
    CustomModal,
    CustomFilterList,
  },

  data() {
    return {
      search: "",
      selected: [],
      expanded: [],
      sortModalOpen: false,
      filterModalOpen: false,
      selectedSortOption: 1,
      selectedSortBy: "projectId",
      sortBy: "createdOn",
      sortDesc: true,
      selectedFilterBy: null,
      selectedFilterOptions: [],
      isSortApplied: false,
      isFilterApplied: false,
      selectedFilters: {},
      sortedProjects: [],
      sortOrderOptions: [
        { text: "Ascending", value: 1 },
        { text: "Descending", value: 2 },
      ],
      sortByOptions: [
        { text: "Project ID", value: "projectId" },
        { text: "Project name", value: "projectName" },
        { text: "Customer", value: "customer" },
        { text: "Location", value: "location" },
        { text: "Segment", value: "segmentId" },
        { text: "Created on", value: "createdOn" },
        { text: "Status", value: "status" },
      ],
      filterOptions: [
        { text: "Customer", value: "customer" },
        { text: "Location", value: "location" },
        { text: "Segment", value: "segmentName" },
        { text: "Project type", value: "projectType" },
      ],
      dropdownWidth: 0,
      dialogButtons: {
        cancel: true,
        confirm: true,
      },
      tableButtons: [
        { name: "Copy", method: "handleClickCopy" },
        { name: "Edit", method: "handleClickEdit" },
        // { name: "Sort", icon: sortIcon, method: "handleClickSort" },
        { name: "Filter", icon: filterIcon, method: "handleClickFilter" },
      ],
      headers: [
        {
          text: "Project ID",
          align: "center",
          class: "align",
          value: "projectId",
        },
        {
          text: "Project name",
          align: "center",
          class: "align",
          value: "projectName",
        },
        {
          text: "Customer",
          align: "center",
          class: "align",
          value: "customer",
        },
        {
          text: "Location",
          align: "center",
          class: "align",
          value: "location",
        },
        {
          text: "Segment",
          align: "center",
          class: "align",
          value: "segmentName",
        },
        {
          text: "Created on",
          align: "center",
          class: "align",
          value: "createdOn",
        },
        {
          text: "Project Type",
          align: "center",
          class: "align status-col",
          value: "projectType",
        },
      ],
    };
  },
  mounted() {
    if (this.$store.getters.isAdmin) {
      this.headers.push({
        text: "Branch",
        align: "center",
        class: "align",
        value: "branch",
      });
      this.filterOptions.push({ text: "Branch", value: "branch" });
    }

    EventBus.$on("loaded", () => {
      this.$store.dispatch("fetchProjects").then(
        () => {
          this.$store.dispatch(`setFilters`, this.selectedFilters);
          this.resetSortOptions();

          this.sortAscending();
        },
        () => this.$toast.error("Failed to retrieve project data")
      );
      this.$store.dispatch("getStatusList");
      this.$store.dispatch("fetchSegments");

      // Asynchronously load fixed master data list at the beginning.
      // These results will be cached (generally for a day) - leaving
      this.$store.dispatch("initialDataLoad");
    });
  },

  computed: {
    ...mapGetters(["regions"]),

    projects() {
      if (this.isFilterApplied) {
        return this.$store.getters.filteredProjects;
      }
      return this.$store.getters.projects;
    },

    customers() {
      return this.$store.getters.customers;
    },

    locations() {
      return this.$store.getters.locations;
    },

    segments() {
      return this.$store.getters.segmentList;
    },

    branches() {
      return this.$store.getters.branchList;
    },

    status() {
      return this.$store.getters.status;
    },

    statuses() {
      return this.$store.getters.statuses;
    },

    filters() {
      return this.$store.getters.filters;
    },

    filteredProjects() {
      return this.$store.getters.filteredProjects;
    },

    tableData() {
      if (this.isSortApplied) {
        return this.sortedProjects;
      }

      if (this.isFilterApplied) {
        return this.filteredProjects.map((project) => ({
          ...project,
          segmentName: this.getSegmentName(project.segmentId),
          branch: this.getBranchName(project.regionId),
        }));
      }

      return this.projects.map((project) => ({
        ...project,
        segmentName: this.getSegmentName(project.segmentId),
        branch: this.getBranchName(project.regionId),
      }));
    },
  },

  methods: {
    handleRefresh() {
      window.location.reload();
    },

    handleClick() {
      router.push({ name: "Create" });
      this.clearProject();
      this.$store.dispatch("changeStepsVisibility", true);
      this.$store.dispatch("setExternalView", true);
      this.$store.dispatch("updateEditModeState", false);
    },

    clearProject() {
      this.$store.dispatch("resetTechnicalState");
      this.$store.dispatch("resetCommercialState");
      this.$store.dispatch("resetGasAndMediaState");
    },

    handleTableBtnClick(methodName) {
      this[methodName]();
    },

    handleClickCopy() {
      if (!this.selected || !this.selected[0] || !this.selected[0].projectId) {
        this.$toast.warning("Please select a project to be copied");
        return;
      }

      this.clearProject();
      this.$store.dispatch("updateEditModeState", false);

      this.$store
        .dispatch("copyProject", this.selected[0].projectId)
        .then(() => {
          router.push({ name: "Create" });
        });
    },

    handleClickEdit() {
      if (!this.selected || !this.selected[0] || !this.selected[0].projectId) {
        this.$toast.warning("Please select a project to be edited");
        return;
      }

      if (this.selected[0]) {
        const projectId = this.selected[0].projectId;

        router.push({
          name: "Edit",
          params: { id: projectId },
        });

        this.$store.dispatch("updateEditModeState", true);
      }
    },

    handleClickSort() {
      this.sortModalOpen = true;
    },

    handleClickFilter() {
      this.filterModalOpen = true;
    },

    selectStatus(status) {
      const selectedProject = this.tableData.find(
        (item) => item.projectId === this.expanded[0].projectId
      );

      selectedProject.status = status;

      this.$store.dispatch("updateProject", selectedProject);
      this.expanded = [];
    },

    sortAscending() {
      if (this.selectedSortBy === "createdOn") {
        this.sortedProjects = this.tableData.sort(
          (a, b) => a[`${this.selectedSortBy}`] - b[`${this.selectedSortBy}`]
        );
      } else {
        this.sortedProjects = this.tableData.sort(
          function (a, b) {
            let textA = a[this.selectedSortBy];
            let textB = b[this.selectedSortBy];
            textA = typeof textA === "string" ? textA.toLowerCase() : textA;
            textB = typeof textB === "string" ? textB.toLowerCase() : textB;

            return textA < textB ? -1 : textA > textB ? 1 : 0;
          }.bind(this)
        );
      }

      this.sortModalOpen = false;
    },

    sortDescending() {
      if (this.selectedSortBy === "createdOn") {
        this.sortedProjects = this.tableData.sort(
          (a, b) => b[`${this.selectedSortBy}`] - a[`${this.selectedSortBy}`]
        );
      } else {
        this.sortedProjects = this.tableData.sort((a, b) => {
          let textA = a[`${this.selectedSortBy}`].toLowerCase();
          let textB = b[`${this.selectedSortBy}`].toLowerCase();
          return textA > textB ? -1 : textA < textB ? 1 : 0;
        });
      }

      this.sortModalOpen = false;
    },

    handleSelectFilter(option) {
      this.selectedFilterBy = option;
      if (option.value === "projectName") {
        this.getProjectsName();
      }
      if (option.value === "customer") {
        this.getCustomers();
      }
      if (option.value === "location") {
        this.getLocations();
      }
      if (option.value === "segmentName") {
        this.getSegments();
      }
      if (option.value === "projectType") {
        this.getProjectTypes();
      }
      if (option.value === "branch") {
        this.getBranches();
      }
    },

    uniqueElementsByValue(arr, fn) {
      return arr.reduce((acc, v) => {
        if (!acc.some((x) => fn(v, x))) acc.push(v);
        return acc;
      }, []);
    },

    getOption() {
      this.$store.dispatch("getOption").then((response) => {
        response = this.uniqueElementsByValue(
          response,
          (a, b) => a.option == b.option
        );
        this.selectedFilterOptions = response;
      });
    },

    getProjectsName() {
      this.$store.dispatch("getProjectsName").then((response) => {
        response = this.uniqueElementsByValue(
          response,
          (a, b) => a.projectData.projectName == b.projectData.projectName
        );
        this.selectedFilterOptions = response;
      });
    },

    getCustomers() {
      this.$store.dispatch("getCustomers").then((response) => {
        response = this.uniqueElementsByValue(
          response,
          (a, b) => a.customer == b.customer
        );
        this.selectedFilterOptions = response;
      });
    },

    getLocations() {
      this.$store.dispatch("getLocations").then((response) => {
        response = this.uniqueElementsByValue(
          response,
          (a, b) => a.location == b.location
        );
        this.selectedFilterOptions = response;
      });
    },

    getSegments() {
      this.$store.dispatch("getSegments").then((response) => {
        const segmentsNames = [];
        response = this.uniqueElementsByValue(response, (a, b) => a.id == b.id);
        response.forEach((item) => {
          if (item.segmentName) {
            segmentsNames.push({
              segmentName: item.segmentName,
              id: item.id,
            });
          }
        });
        this.selectedFilterOptions = segmentsNames;
      });
    },

    getBranches() {
      this.$store.dispatch("getBranches").then((response) => {
        const branchesNames = [];
        response = this.uniqueElementsByValue(response, (a, b) => a.id == b.id);
        response.forEach((item) => {
          if (item.branch) {
            branchesNames.push({
              branch: item.branch,
              id: item.id,
            });
          }
        });
        this.selectedFilterOptions = branchesNames;
      });
    },

    getProjectTypes() {
      this.$store.dispatch("getProjectTypes").then((response) => {
        response = this.uniqueElementsByValue(
          response,
          (a, b) => a.projectType == b.projectType
        );
        this.selectedFilterOptions = response;
      });
    },

    setFilterSelectedOption(event) {
      this.selectedFilters[`${this.selectedFilterBy.value}`] = event;
    },

    handleConfirmFilter() {
      this.isFilterApplied = true;
      this.$store.dispatch(`setFilters`, this.selectedFilters);
      this.filterModalOpen = false;
      this.selectedFilterBy = null;
    },

    resetFilters() {
      this.selectedFilters = {};
      this.isFilterApplied = false;

      this.$store.dispatch(`setFilters`, this.selectedFilters);
      this.filterModalOpen = false;
    },

    resetSortOptions() {
      this.isSortApplied = false;
      this.selectedSortBy = "projectId";
      this.selectedSortOption = 1;
      this.sortAscending();
      this.sortModalOpen = false;
    },

    formatDate(item) {
      const options = {
        month: "short",
        day: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      };

      item = new Date(item);

      return item ? item.toLocaleString("default", options) : "";
    },

    getSegmentName(id) {
      return this.segments.find((segment) => segment.id === id)?.segmentName;
    },

    getBranchName(id) {
      if (id) {
        return this.regions.find((branch) => branch.id === id)?.branch;
      }
      return "";
    },
  },
};
</script>
<style lang="scss" scoped>
.component-header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.projects {
  font-size: 1.25rem;
}

::v-deep {
  .base-table {
    position: relative;

    tbody td {
      vertical-align: middle;
    }

    th:first-child,
    td:first-child {
      border-right: none;
    }

    th:nth-child(2) {
      width: 100px;
    }

    .align {
      padding: 0.2rem;
    }

    &.projectsTable {
      thead tr th {
        background-color: $accent-blue;
      }
    }
  }

  .v-table tbody td,
  table.v-table tbody th {
    height: 19px;
  }

  .v-simple-checkbox {
    .v-icon {
      font-size: 1rem;
    }
  }

  .search-bar {
    border: $base-border;
    max-width: 15rem;
    height: 2.25rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    padding: 0 0.3rem;

    .v-label {
      color: inherit;
      font-style: italic;
    }

    .v-icon {
      color: $basic-blue;
      font-size: 1.3rem;
    }

    .v-input__slot {
      &::after,
      &::before {
        display: none;
      }
    }
  }

  .table-button {
    @media (max-width: 600px) {
      margin-right: 1rem;
      margin-left: 0 !important;
    }

    &.sort {
      img {
        width: 0.7rem;
      }
    }

    &.filter {
      img {
        width: 1rem;
      }
    }
  }

  .v-select__selections {
    max-width: 110px;
  }
}

.sort-modal {
  padding-top: 1rem;

  .v-input {
    margin-top: 0;
  }

  .v-radio {
    border-bottom: $base-border;
    padding: 0.3rem 0 0.4rem 1rem;
    margin: 0 !important;

    &:first-child {
      border-top: $base-border;
    }

    &.v-item--active {
      background-color: $light-blue;
    }
  }

  span {
    font-size: 1rem;
    padding-left: 1rem;
  }
}

.filter-modal {
  padding-top: 1rem;
  font-size: 1rem;

  span {
    padding-left: 1rem;
  }

  ul {
    margin-top: 0.5rem;
  }

  li {
    border-bottom: $base-border;
    padding: 0.3rem 0 0.4rem 1rem;
    cursor: pointer;

    &:first-child {
      border-top: $base-border;
    }
  }

  .header-button {
    font-size: 1rem;
  }
}
</style>
