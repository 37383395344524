<template>
  <div>
    <helper-box text="Please enter all project details in this section" />
    <div class="project-data-form">
      <div class="project-data-section">
        <div class="mb-8">
          <text-input
            label="Project name"
            :value="projectName"
            @change="handleProjectNameChange"
          />
        </div>
        <div>
          <label class="input-label">Segment:</label>
          <v-select
            outlined
            background-color="rgba(214, 225, 236, 1)"
            item-color="rgba(214, 225, 236, 1)"
            class="fft-dropdown"
            v-model="projectSegment"
            :items="segmentOptions"
            item-text="segmentName"
            item-value="id"
          ></v-select>
        </div>
      </div>
      <div class="project-data-section">
        <div class="mb-8">
          <text-input
            :value="projectCustomer"
            label="Customer"
            @change="handleCustomerChange"
          />
        </div>
        <div>
          <label class="input-label">Project type:</label>
          <v-select
            outlined
            background-color="rgba(214, 225, 236, 1)"
            item-color="rgba(214, 225, 236, 1)"
            class="fft-dropdown"
            v-model="projectStatus"
            :items="statusOptions"
            item-text="statusName"
          ></v-select>
        </div>
      </div>
      <div class="project-data-section">
        <div class="mb-8">
          <text-input
            :value="projectLocation"
            label="Location"
            @change="handleLocationChange"
          />
        </div>
        <div>
          <label class="input-label">Unit of measure:</label>

          <v-select
            outlined
            background-color="rgba(214, 225, 236, 1)"
            item-color="rgba(214, 225, 236, 1)"
            class="fft-dropdown"
            v-model="projectUnitMeasure"
            :items="unitMeasureOptions"
            item-text="unit"
          ></v-select>
        </div>
      </div>
      <div class="project-data-section">
        <div class="mb-8">
          <label class="input-label">Branch name:</label>

          <v-select
            outlined
            background-color="rgba(214, 225, 236, 1)"
            item-color="rgba(214, 225, 236, 1)"
            class="fft-dropdown"
            v-model="branchRegion.branch"
            @change="setBranchRegion"
            :items="regionOptions"
            item-text="branch"
            item-value="id"
            :disabled="branchRegion.disabled"
          ></v-select>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import HelperBox from "../../components/base/HelperBox.vue";
import TextInput from "../../components/base/TextInput.vue";

export default {
  name: "ProjectData",

  components: { TextInput, HelperBox },

  computed: {
    ...mapGetters([
      "projectName",
      "projectCustomer",
      "projectLocation",
      "account",
      "userBranch",
      "isAdmin",
      "users",
      "airflowImperial",
      "airflow",
    ]),

    segmentOptions: {
      get() {
        return this.$store.getters.segments;
      },
      set(newValue) {
        this.$store.dispatch("setSegments", newValue);
      },
    },

    statusOptions: {
      get() {
        return this.$store.getters.statuses;
      },
      set(newValue) {
        this.$store.dispatch("setStatusList", newValue);
      },
    },

    unitMeasureOptions: {
      get() {
        return this.$store.getters.units;
      },
      set(newValue) {
        this.$store.dispatch("setUnitsList", newValue);
      },
    },

    projectUnitMeasure: {
      get() {
        return this.$store.getters.projectUnitMeasure;
      },
      set(newValue) {
        this.handleProjectUnitMeasureChange(newValue);
      },
    },

    projectStatus: {
      get() {
        return this.$store.getters.projectStatus;
      },
      set(newValue) {
        this.$store.commit("changeProjectStatus", newValue);
      },
    },

    projectSegment: {
      get() {
        return this.$store.getters.projectSegment;
      },
      set(newValue) {
        this.$store.commit("changeProjectSegment", newValue);
      },
    },

    branchRegion() {
      if (this.userBranch && !this.isAdmin) {
        const region = this.regionOptions.find(
          (region) => region.branch === this.userBranch
        );
        if (region) {
          return { branch: region, disabled: true };
        }
      }
      return { branch: this.$store.getters.branchRegion, disabled: false };
    },

    regionOptions: {
      get() {
        return this.sortBranch(this.$store.getters.regions);
      },
      set(newValue) {
        this.$store.dispatch("setRegionsList", newValue);
      },
    },

    branchOptions: {
      get() {
        return this.$store.getters.branches;
      },
      set(newValue) {
        this.$store.dispatch("setBranches", newValue);
      },
    },
  },

  created() {
    this.getDPCoeffs();
  },

  mounted() {
    this.fetchInitialData();
  },

  updated() {
    this.updateMasterlistCalcs();
    this.setAirflowImperial();
  },

  methods: {
    ...mapActions([
      "fetchSegments",
      "getStatusList",
      "getUnitsList",
      "getRegionsList",
      "fetchMediaTypes",
      "fetchFiltersData",
      "fetchDwgsCosts",
      "getUsers",
      "getDPCoeffs",
      "updateMasterlistCalcs",
    ]),

    fetchInitialData() {
      this.fetchSegments();
      this.getStatusList();
      this.getUnitsList();
      this.getRegionsList();
      this.fetchMediaTypes();
      this.fetchFiltersData();
      this.fetchDwgsCosts();
      this.getUsers();
    },

    setAirflowImperial() {
      const imperial = this.$options.filters.roundFormatter(
        this.$options.filters.unitConvertor(
          this.airflow,
          this.$imperials.AIRFLOW
        )
      );
      this.$store.commit("changeAirflowImperial", imperial);
    },

    sortBranch(branches) {
      const tmp = branches.slice(0);
      tmp.sort(function (a, b) {
        return a.branch > b.branch ? 1 : b.branch > a.branch ? -1 : 0;
      });
      return tmp;
    },

    handleProjectNameChange(newProjectName) {
      this.$store.commit("changeProjectName", newProjectName);
    },

    handleCustomerChange(newCustomer) {
      this.$store.commit("changeProjectCustomer", newCustomer);
    },

    handleLocationChange(newLocation) {
      this.$store.commit("changeProjectLocation", newLocation);
    },

    handleProjectUnitMeasureChange(newProjectUnitMeasure) {
      this.$store.commit("changeProjectUnitMeasure", newProjectUnitMeasure);
      this.$store.commit(
        "changeSelectedAirflowMeasureUnitKey",
        newProjectUnitMeasure
      );
      this.$store.commit("selectedAirflowType", "available");
      this.$store.commit("resetCalculation", 0);
    },

    setBranchRegion(val) {
      this.$store.commit("changeBranchRegion", val);
    },
  },
};
</script>
<style lang="scss" scoped>
.project-data-form {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
}

.project-data-section {
  flex: 1 1 0%;
}

.project-data-section:not(:last-child) {
  padding-right: 10%;
}
</style>
