import BeeGridTable from "beegridtable";
import "@/helpers/filters";
import Emitter from "tiny-emitter";
import Vue from "vue";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-default.css";
import App from "./App.vue";
import { imperials } from "./constants/imperialConstants.js";
import { metrics } from "./constants/metricConstants.js";
import canAccess from "./directives/canAccessImpl";
import { imperialConstants, metricConstants } from "./helpers/filters.js";
import { i18n } from "./plugins/i18n";
import vuetify from "./plugins/vuetify";
import router from "./router";
import { store } from "./store/store";

Vue.config.productionTip = false;
Vue.prototype.$imperialConstants = imperialConstants;
Vue.prototype.$metricConstants = metricConstants;
Vue.prototype.$metrics = metrics;
Vue.prototype.$imperials = imperials;
Vue.prototype.$msalInstance = {};
Vue.prototype.$emitter = new Emitter();
Vue.use(VueToast, {
  position: "top-right",
});

Vue.use(BeeGridTable, {
  capture: true,
});

Vue.directive("canAccess", canAccess);

new Vue({
  router,
  vuetify,
  i18n,
  store,

  render: (h) => {
    return h(App);
  },
}).$mount("#app");
