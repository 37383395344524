import storeTechincal from "../technicalComparison";
const getDefaultState = () => {
  return {
    mediaTypes: [],
    filtersData: [],
    inletTypes: [],
    selectedInletType: {},
    outletTypes: [],
    selectedOutletType: {},
    positionsInlet: [],
    selectedPositionInlet: {},
    positionsOutlet: [],
    selectedPositionOutlet: {},
    pdMeasurements: [],
    selectedPd: {},
    casingMaterials: {
      optionA: [],
      optionB: [],
      optionC: [],
      optionD: [],
    },
    selectedCasingMaterialA: "",
    selectedCasingMaterialB: "",
    selectedCasingMaterialC: "",
    selectedCasingMaterialD: "",
    comparisonOptions: {
      optionA: {},
      optionB: {},
      optionC: {},
      optionD: {},
    },
    stages: { stage1: {}, stage2: {}, stage3: {}, stage4: {} },
    maxAirflow: 2500,
    unitSelectedOption: "",
    selectedCassingMaterialsCosts: {
      optionA: {},
      optionB: {},
      optionC: {},
      optionD: {},
    },
    selectedUnitSize: "",
    DPCoeff: [],
    btsFactor: 85,
  };
};

// initial state
const state = getDefaultState();

const mutations = {
  resetState() {
    Object.assign(storeTechincal.state, getDefaultState());
  },
};

const actions = {
  resetTechnicalState({ commit }) {
    commit("resetState");
  },
};

export default {
  state,
  getters: {},
  actions,
  mutations,
};
