<template>
  <div class="d-flex justifiy-center align-center">
    <img v-if="icon" :src="icon" alt="arrows" class="mr-2 icon-button" />
    <a
      class="fft-custom-link"
      href="javascript:void(0);"
      @click="onCustomLinkClick"
    >
      {{ text }}
    </a>
  </div>
</template>
<script>
export default {
  name: "CustomLink",

  props: {
    text: String,
    icon: String,
  },

  methods: {
    onCustomLinkClick(event) {
      event.preventDefault();
      this.$emit("click");
    },
  },
};
</script>
<style lang="scss" scoped>
.fft-custom-link {
  color: $accent-blue;
  font-style: normal;
  text-align: right;
}
</style>
