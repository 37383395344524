<template>
  <div class="mt-10">
    <div
      class="technical-comparison-tables-container table-pre-info-container-color filter-container"
    >
      <span class="info-box">Key facts</span>
      <v-data-table
        :items="keyFactsItems"
        :headers="prefilterModelHeaders"
        class="base-table text-center no-header-table top-bordered-table key-facts-table"
        hide-default-footer
        hide-default-header
      >
        <template
          v-for="option in defaultOptions"
          v-slot:[`item.option${option.title}`]="{ item }"
        >
          <p
            :key="option.id"
            :class="checkTableRowClass(item[`option${option.title}`])"
          >
            {{ item[`option${option.title}`] }}
          </p>
        </template>
      </v-data-table>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { prefilterModelHeaders } from "../../../constants/technicalComparison/headers.js";
import { defaultOptions } from "../../../store/store.js";
export default {
  props: {
    selectedOptions: Object,
    selectedPressureUnitMeasure: String,
  },
  data() {
    return {
      prefilterModelHeaders,
      defaultOptions,
    };
  },

  computed: {
    ...mapGetters(["stages", "comparisonOptions", "projectUnitMeasure"]),

    /**
     * builds an array of object for keyfacts table items, using all options (A, B, C, D)
     * @returns {array}
     */
    keyFactsItems() {
      const rowHeaders = [
        "Total residence time per unit",
        "Estimated final PD for filters",
        "Airflow check",
        "Final PD check",
      ];
      const units = [
        "[s]",
        "[" + this.selectedPressureUnitMeasure + "]",
        "",
        "",
      ];

      const keyFacts = [];

      rowHeaders.forEach((header, i) => {
        const object = {};
        object.options = header;
        object.empty1 = units[i];

        this.defaultOptions.forEach((option) => {
          if (i === 0) {
            object[`option${option.title}`] =
              this.calculateTotalResidenceTimePerUnit(
                `option${option.title}`
              ) !== "-" &&
              this.calculateTotalResidenceTimePerUnit(`option${option.title}`)
                ? this.calculateTotalResidenceTimePerUnit(
                    `option${option.title}`
                  ).toFixed(2)
                : "-";
          } else if (i === 1) {
            object[`option${option.title}`] = this.calculateEstimatedFinalPD(
              `option${option.title}`
            )
              ? this.valueDecimalsTransformations(
                  this.calculateEstimatedFinalPD(`option${option.title}`),
                  this.$imperials.PRESSURE,
                  2
                )
              : "-";
          } else if (i === 2) {
            object[`option${option.title}`] = this.returnAirflowCheck(
              option.title
            );
          } else if (i === 3) {
            object[`option${option.title}`] = this.returnFinalPDCheck(
              option.title
            );
          }
        });

        keyFacts.push(object);
      });

      return keyFacts;
    },
  },

  methods: {
    /**
     * returns a class name for rows that have value 'NOT OK'
     * @param {string} value
     * @returns {string}
     */
    checkTableRowClass(value) {
      if (value === "NOT OK!") {
        return "error-check";
      }
    },

    /**
     * calculates the value of residence time / unit for a given option, using
     * the total number of stages that have a value and the residence time / bed of the given option
     * then sets in the store the calculated value and returns it
     * if the value is already in store for the given option just returns its value
     * @param {string} option
     * @returns {number/string}
     */
    calculateTotalResidenceTimePerUnit(option) {
      let optionNumberOfStages = 0;
      let totalResidenceTimePerUnit = "-";

      Object.values(this.stages).forEach((stage) => {
        if (stage[option] && Object.keys(stage[option]).length > 0) {
          optionNumberOfStages++;
        }
      });

      if (this.comparisonOptions[option]?.generalInfo?.residenceTimePerBed) {
        if (optionNumberOfStages) {
          totalResidenceTimePerUnit =
            optionNumberOfStages *
            this.comparisonOptions[option]?.generalInfo?.residenceTimePerBed;
        }

        this.$store.dispatch("setComparisonOptions", {
          option: option,
          comparisonItem: "totalResidenceTimePerUnit",
          value: totalResidenceTimePerUnit,
        });
        return totalResidenceTimePerUnit;
      } else {
        return this.comparisonOptions[option].totalResidenceTimePerUnit;
      }
    },

    /**
     * calculates for a given option estimated final PD for filters using the PD calculated at each stage, the prefilter final PD and
     * using the final filter final PD
     * then sets the calculated value in the store and returns it
     * @param {string} option
     * @returns {number/string}
     */
    calculateEstimatedFinalPD(option) {
      let estimatedFinalPD = 0;

      Object.keys(this.stages).forEach((stage) => {
        if (this.stages[stage][option] && this.stages[stage][option].PD) {
          estimatedFinalPD =
            parseInt(estimatedFinalPD) +
            parseInt(this.stages[stage][option].PD);
        }
      });

      if (this.comparisonOptions[option].prefilterConsideredFinalPD) {
        estimatedFinalPD =
          parseInt(estimatedFinalPD) +
          parseInt(this.comparisonOptions[option].prefilterConsideredFinalPD);
      }

      if (this.comparisonOptions[option].finalFilterConsideredFinalPD) {
        estimatedFinalPD =
          parseInt(estimatedFinalPD) +
          parseInt(this.comparisonOptions[option].finalFilterConsideredFinalPD);
      }

      this.$store.dispatch("setComparisonOptions", {
        option: option,
        comparisonItem: "estimatedFinalPD",
        value: estimatedFinalPD,
      });

      if (estimatedFinalPD === 0) {
        return "-";
      }

      return estimatedFinalPD;
    },

    /**
     * checks if the chosen airflow of the given option is below/above
     * the value of the predefined values for the airflow for min/max
     * then sets a message according to the comparison result
     * if the given option is not viledon, the comparison will not be made and the response will be 'not availible'
     * then sets the result in vuex and also returns it
     * @param {string} option
     * @returns {string}
     */
    returnAirflowCheck(option) {
      let airflowCheck = "-";
      const chosenAirflow =
        this.comparisonOptions[`option${option}`]?.generalInfo?.chosenAirflow;
      const optionObject = this.selectedOptions[`layout${option}`];

      if (optionObject?.viledon === 1) {
        let minAirflow;
        let maxAirflow;
        const tolerance = 0;

        if (optionObject) {
          minAirflow = optionObject.minFlow;
          maxAirflow = optionObject.maxFlow;
        }

        if (chosenAirflow) {
          if (chosenAirflow < minAirflow * (1 - tolerance)) {
            airflowCheck = "Too low";
          } else if (chosenAirflow > maxAirflow * (1 + tolerance)) {
            airflowCheck = "Too high";
          } else {
            airflowCheck = "Ok";
          }
        }
      } else {
        airflowCheck = "Not available";
      }

      this.$store.dispatch("setComparisonOptions", {
        option: `option${option}`,
        comparisonItem: "airflowCheck",
        value: airflowCheck,
      });

      return airflowCheck;
    },

    /**
     * checks the estimated final PD if its value is below/above the predefined maxPD
     * then sets a message according to the comparison result
     * if the given option is not viledon, the comparison will not be made and the response will be 'not availible'
     * then sets the result in vuex and also returns it
     * @param {string} option
     * @returns {string}
     */
    returnFinalPDCheck(option) {
      let finalPDCheck = "-";
      const estimatedFinalPD =
        this.comparisonOptions[`option${option}`].estimatedFinalPD;
      const optionObject = this.selectedOptions[`layout${option}`];

      if (optionObject?.viledon === 1) {
        let maxPD;
        let tolerance = 0;

        if (optionObject) {
          maxPD = optionObject.maxPdFilter;
        }

        if (estimatedFinalPD) {
          if (estimatedFinalPD < maxPD) {
            finalPDCheck = "OK";
          } else if (estimatedFinalPD < maxPD * (1 + tolerance)) {
            finalPDCheck = "Critical";
          } else {
            finalPDCheck = "NOT OK!";
          }
        }
      } else {
        finalPDCheck = "Not available";
      }

      this.$store.dispatch("setComparisonOptions", {
        option: `option${option}`,
        comparisonItem: "finalPDCheck",
        value: finalPDCheck,
      });

      return finalPDCheck;
    },

    valueDecimalsTransformations(value, constant, nrOfDecimals) {
      if (this.projectUnitMeasure === "Metric") {
        nrOfDecimals = 0;
      }

      return this.$options.filters.decimalsFormatter(
        this.$options.filters.unitConvertor(value, constant),
        nrOfDecimals
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.error-check {
  color: red;
  height: 100%;
  width: 100%;
  border: 3px solid red;
}

.info-box {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

p {
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-pre-info-container-color span {
  background-color: $page-title-container-grey;
}
</style>
