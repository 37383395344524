<template>
  <div class="segmented-button">
    <div
      v-for="option in options"
      class="segmented-option"
      :class="{ 'segmented-option-selected': selectedKey === option.key }"
      :key="option.key"
      @click="handleSegmentedButtonSelectionChange(option.key)"
    >
      <span class="segmented-option-text"> {{ option.text }} </span>
    </div>
  </div>
</template>
<script>
export default {
  name: "SegmentedButton",

  props: {
    options: Array,
    selectedKey: String,
  },

  methods: {
    handleSegmentedButtonSelectionChange(newSelectedKey) {
      this.$emit("option-change", newSelectedKey);
    },
  },
};
</script>
<style lang="scss" scoped>
.segmented-button {
  display: flex;
  border: 1px solid $basic-blue;
  border-radius: 4px;
  width: fit-content;
  cursor: pointer;
}

.segmented-option {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1em;
}

.segmented-option-selected {
  background-color: $basic-blue;
  color: white;
}

.segmented-option:not(:last-child) {
  border-right: 1px solid $basic-blue;
}

.segmented-option-text {
  user-select: none !important;
}
</style>
