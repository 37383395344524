import storeCommercial from "../commercialComparison";
import { currencyEnum } from "../../../constants/currencies";
const getDefaultState = () => {
  return {
    prices: [],
    selectedCassingMaterial: {
      optionA: "",
      optionB: "",
      optionC: "",
      optionD: "",
    },
    commercialComparison: {
      optionA: {
        isSelected: false,
        code: "-",
        unitCost: 0,
        elementsCost: 0,
        mediaCostStage1: 0,
        mediaCostStage2: 0,
        mediaCostStage3: 0,
        mediaCostStage4: 0,
        preFiltersCost: 0,
        finalFiltersCost: 0,
        totalInvestmentCost: 0,
        filtersAndPelletsCost: 0,
        changeoutYear: 0,
        annualizesCalc: 0,
      },
      optionB: {
        isSelected: false,
        code: "-",
        unitCost: 0,
        elementsCost: 0,
        mediaCostStage1: 0,
        mediaCostStage2: 0,
        mediaCostStage3: 0,
        mediaCostStage4: 0,
        preFiltersCost: 0,
        finalFiltersCost: 0,
        totalInvestmentCost: 0,
        filtersAndPelletsCost: 0,
        changeoutYear: 0,
        annualizesCalc: 0,
      },
      optionC: {
        isSelected: false,
        code: "-",
        unitCost: 0,
        elementsCost: 0,
        mediaCostStage1: 0,
        mediaCostStage2: 0,
        mediaCostStage3: 0,
        mediaCostStage4: 0,
        preFiltersCost: 0,
        finalFiltersCost: 0,
        totalInvestmentCost: 0,
        filtersAndPelletsCost: 0,
        changeoutYear: 0,
        annualizesCalc: 0,
      },
      optionD: {
        isSelected: false,
        code: "-",
        unitCost: 0,
        elementsCost: 0,
        mediaCostStage1: 0,
        mediaCostStage2: 0,
        mediaCostStage3: 0,
        mediaCostStage4: 0,
        preFiltersCost: 0,
        finalFiltersCost: 0,
        totalInvestmentCost: 0,
        filtersAndPelletsCost: 0,
        changeoutYear: 0,
        annualizesCalc: 0,
      },
    },
    currencies: [],
    selectedCurrency: currencyEnum.EUR,
    exchangeRate: null,
    completePrices: [],
  };
};

// initial state
const state = getDefaultState();

const mutations = {
  resetState() {
    Object.assign(storeCommercial.state, getDefaultState());
  },
};

const actions = {
  resetCommercialState({ commit }) {
    commit("resetState");
  },
};

export default {
  state,
  getters: {},
  actions,
  mutations,
};
