<template>
  <v-app id="app" class="full-background">
    <the-header />
    <v-main class="main-page pt-12">
      <router-view v-if="account && !hideContent" />
    </v-main>
    <the-footer />
  </v-app>
</template>
<script>
import TheHeader from "./components/common/TheHeader.vue";
import TheFooter from "./components/common/TheFooter.vue";
import { EventBus } from "./constants/event-bus.js";

export default {
  name: "App",

  components: {
    TheHeader,
    TheFooter,
  },

  data() {
    return {
      account: undefined,
      hideContent: true,
    };
  },

  async created() {
    if (process.env.NODE_ENV === "development") {
      this.account = process.env.NODE_ENV;
    }
    this.$emitter.on(
      "login",
      async function (account) {
        if (account) {
          this.account = account;
          await this.$store.dispatch("updateAccount", account);
          setTimeout(() => {
            EventBus.$emit("loaded");
          }, 1500);
        }
      }.bind(this)
    ),
      this.$emitter.on("logout", () => {
        console.log("logging out");
        this.$store.dispatch("updateAccount", undefined);
        this.account = undefined;
      });
  },

  mounted() {
    EventBus.$on("dataRestricted", (val) => {
      this.hideContent = val;
    });
  },
};
</script>
<style lang="scss">
#app {
  min-height: 100vh;
}

.main-page {
  margin-top: 10px;
  margin-bottom: $main-margins;
}
</style>
