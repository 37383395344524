<template>
  <v-dialog
    content-class="rounded-0"
    v-model="show"
    :width="width"
    :persistent="persist"
  >
    <v-card
      class="custom-modal-content d-flex flex-column"
      :style="{ height: height }"
    >
      <v-card-title class="custom-modal-header">
        <div v-if="showBackIcon" class="mr-3 back-icon">
          <img
            :src="require('@/assets/svg/back_icon_white.svg')"
            @click="handleClickBackIcon"
          />
        </div>
        {{ title }}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <slot> </slot>
      </v-card-text>
      <v-card-actions class="d-flex justify-end mt-auto">
        <custom-button
          v-if="buttons.cancel === true"
          type="secondary"
          title="Close"
          @click="
            show = false;
            handleCancel(false);
          "
        />
        <custom-button
          v-if="buttons.confirm === true"
          type="primary"
          title="Confirm"
          @click="
            show = false;
            handleConfirm($event);
          "
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import CustomButton from "./CustomButton.vue";

export default {
  name: "CustomModal",

  components: { CustomButton },

  props: {
    value: Boolean,
    title: String,
    buttons: Object,
    showBackIcon: Boolean,
    headerButton: String,
    headerBtnDisabled: Boolean,
    height: String,
    width: String,
    persist: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  methods: {
    handleConfirm() {
      this.$emit("confirm");
    },

    handleCancel() {
      this.$emit("cancel");
    },

    handleClickBackIcon() {
      this.$emit("clickOnBackIcon");
    },

    handleClickHeaderButton() {
      this.$emit("clickHeaderButton");
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-modal-header {
  background-color: $basic-blue;
  color: $white;
  height: 43px;
}

.v-dialog > .v-card > .custom-modal-header {
  padding: 2px 2px 2px 1rem;
  font-size: inherit;
  font-weight: normal;
  letter-spacing: inherit;
}

.custom-modal-content.v-sheet.v-card {
  border-radius: 0;
}

.v-dialog > .v-card > .v-card__text {
  padding: 0;
}

.back-icon {
  cursor: pointer;
}

.v-card__text {
  overflow: auto;
}
</style>
