import Vue from "vue";
import storeTechincal from "../technicalComparison";

const mutations = {
  resetOption(state, option) {
    console.log(state);
    Vue.set(storeTechincal.state.comparisonOptions, option, {});
  },

  resetStages(state, option) {
    console.log(state);

    for (let i = 1; i <= 4; i++) {
      Vue.set(storeTechincal.state.stages[`stage${i}`], option, {});
    }
  },
};

const actions = {
  resetOptionState({ commit }, title) {
    commit("resetOption", `option${title}`);
  },

  resetOptionStages({ commit }, title) {
    commit("resetStages", `option${title}`);
  },
};

export default {
  state: {},
  getters: {},
  actions,
  mutations,
};
