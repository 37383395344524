const technicalComparisonHeaders = [
  {
    text: "Options",
    class: "align bold",
    value: "options",
    sortable: false,
    width: "16%",
  },
  {
    text: "",
    align: "center",
    class: "align bold",
    value: "empty1",
    sortable: false,
    width: "5%",
  },
  {
    text: "A",
    align: "center",
    class: "align bold",
    value: "optionA",
    sortable: false,
    width: "calc(100% - 21rem)",
  },
  {
    text: "B",
    align: "center",
    class: "align bold",
    value: "optionB",
    sortable: false,
    width: "calc(100% - 21rem)",
  },
  {
    text: "C",
    align: "center",
    class: "align bold",
    value: "optionC",
    sortable: false,
    width: "calc(100% - 21rem)",
  },
  {
    text: "D",
    align: "center",
    class: "align bold",
    value: "optionD",
    sortable: false,
    width: "calc(100% - 21rem)",
  },
];

const prefilterModelHeaders = [
  {
    text: "Options",
    class: "align bold",
    value: "options",
    sortable: false,
    width: "18%",
  },
  {
    text: "",
    align: "center",
    class: "align bold",
    value: "empty1",
    sortable: false,
    width: "5%",
  },
  {
    text: "A",
    align: "center",
    class: "align bold",
    value: "optionA",
    sortable: false,
  },
  {
    text: "B",
    align: "center",
    class: "align bold",
    value: "optionB",
    sortable: false,
  },
  {
    text: "C",
    align: "center",
    class: "align bold",
    value: "optionC",
    sortable: false,
  },
  {
    text: "D",
    align: "center",
    class: "align bold",
    value: "optionD",
    sortable: false,
  },
];

const stagesHeaders = [
  {
    text: "Type",
    align: "center",
    class: "align bold",
    value: "type",
    sortable: false,
  },
  {
    text: "Unit",
    align: "center",
    class: "align bold",
    value: "unit",
    sortable: false,
    width: "5%",
  },
  {
    text: "Stage 1",
    align: "center",
    class: "align bold",
    value: "stage1",
    sortable: false,
    width: "",
  },
  {
    text: "Stage 2",
    align: "center",
    class: "align bold",
    value: "stage2",
    sortable: false,
    width: "",
  },
  {
    text: "Stage 3",
    align: "center",
    class: "align bold",
    value: "stage3",
    sortable: false,
    width: "",
  },
  {
    text: "Stage 4",
    align: "center",
    class: "align bold",
    value: "stage4",
    sortable: false,
    width: "",
  },
];

const gasesTableHeaders = [
  {
    text: "Gas",
    align: "center",
    class: "align bold",
    value: "gas",
    sortable: false,
  },
  {
    text: "Inlet kg/y",
    align: "center",
    class: "align bold",
    value: "inlet",
    sortable: false,
  },
  {
    text: "",
    align: "center",
    class: "align bold",
    value: "empty1",
    width: "7%",
    sortable: false,
  },
  {
    text: "",
    align: "center",
    class: "align bold",
    value: "empty2",
    width: "7%",
    sortable: false,
  },
  {
    text: "",
    align: "center",
    class: "align bold",
    value: "empty3",
    width: "7%",
    sortable: false,
  },
  {
    text: "",
    align: "center",
    class: "align bold",
    value: "empty4",
    width: "7%",
    sortable: false,
  },
  {
    text: "",
    align: "center",
    class: "align bold",
    value: "totalRemovalCap",
    sortable: false,
  },
  {
    text: "",
    align: "center",
    class: "align bold",
    value: "timeForBreakThrough",
    sortable: false,
  },
];

const mediaTypeHeaders = [
  {
    text: "Options",
    class: "align bold",
    value: "options",
    sortable: false,
    width: "18%",
  },
  {
    text: "",
    align: "center",
    class: "align bold",
    value: "empty1",
    sortable: false,
    width: "5%",
  },
  {
    text: "A",
    align: "center",
    class: "align bold",
    value: "optionA",
    sortable: false,
  },
  {
    text: "B",
    align: "center",
    class: "align bold",
    value: "optionB",
    sortable: false,
  },
  {
    text: "C",
    align: "center",
    class: "align bold",
    value: "optionC",
    sortable: false,
  },
  {
    text: "D",
    align: "center",
    class: "align bold",
    value: "optionD",
    sortable: false,
  },
];

const mediaCapacityHeaders = [
  {
    text: "Options",
    class: "align",
    value: "options",
    sortable: false,
    width: "18%",
  },
  {
    text: "",
    align: "center",
    class: "align",
    value: "empty1",
    sortable: false,
    width: "5%",
  },
  {
    text: "A",
    align: "center",
    class: "align",
    value: "optionA",
    sortable: false,
  },
  {
    text: "B",
    align: "center",
    class: "align",
    value: "optionB",
    sortable: false,
  },
  {
    text: "C",
    align: "center",
    class: "align",
    value: "optionC",
    sortable: false,
  },
  {
    text: "D",
    align: "center",
    class: "align",
    value: "optionD",
    sortable: false,
  },
];

export {
  technicalComparisonHeaders,
  stagesHeaders,
  gasesTableHeaders,
  prefilterModelHeaders,
  mediaTypeHeaders,
  mediaCapacityHeaders,
};
