<template>
  <v-btn
    :class="{
      'primary-button': type === 'primary',
      'secondary-button': type === 'secondary',
    }"
    @click="handleButtonClick"
    :disabled="disabled"
  >
    {{ title }}</v-btn
  >
</template>
<script>
export default {
  name: "SecondaryButton",

  props: {
    title: String,
    type: String,
    disabled: Boolean,
  },

  methods: {
    handleButtonClick() {
      this.$emit("click");
    },
  },
};
</script>
