var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-8 justify-space-between"},[_c('div',[_c('v-data-table',{staticClass:"base-table text-center top-bordered-table removal-table header-only",attrs:{"headers":_vm.gasesTableOnlyHeaders,"hide-default-footer":""},scopedSlots:_vm._u([{key:"header.gasBreak",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"d-flex flex-column gasBreak-container"},[_c('div',{staticClass:"py-2"},[_vm._v(_vm._s(header.text))]),_c('v-row',_vm._l((_vm.stages),function(stage,i,index){return _c('v-col',{key:i},[_c('p',[_vm._v("Stage "+_vm._s(index + 1))])])}),1)],1)]}}],null,true)}),_c('v-data-table',{staticClass:"base-table text-center gases-table top-bordered-table",attrs:{"items":_vm.gasesTableItems,"headers":_vm.gasesTableHeaders,"hide-default-footer":"","hide-default-header":""},scopedSlots:_vm._u([{key:"item.inlet",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"d-flex justify-center align-center",class:_vm.gasesRowClass(item.inlet)},[_vm._v(" "+_vm._s(_vm._f("decimalsFormatter")(_vm._f("unitConvertor")(item.inlet,_vm.$imperials.MASS),2))+" ")])]}},{key:"item.empty1",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"d-flex justify-center align-center",class:_vm.gasesRowClass(item.empty1)},[_vm._v(" "+_vm._s(_vm._f("decimalsFormatter")(_vm._f("unitConvertor")(item.empty1,_vm.$imperials.MASS),2))+" ")])]}},{key:"item.empty2",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"d-flex justify-center align-center",class:_vm.gasesRowClass(item.empty2)},[_vm._v(" "+_vm._s(_vm._f("decimalsFormatter")(_vm._f("unitConvertor")(item.empty2,_vm.$imperials.MASS),2))+" ")])]}},{key:"item.empty3",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"d-flex justify-center align-center",class:_vm.gasesRowClass(item.empty3)},[_vm._v(" "+_vm._s(_vm._f("decimalsFormatter")(_vm._f("unitConvertor")(item.empty3,_vm.$imperials.MASS),2))+" ")])]}},{key:"item.empty4",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"d-flex justify-center align-center",class:_vm.gasesRowClass(item.empty4)},[_vm._v(" "+_vm._s(_vm._f("decimalsFormatter")(_vm._f("unitConvertor")(item.empty4,_vm.$imperials.MASS),2))+" ")])]}},{key:"item.totalRemovalCap",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(" "+_vm._s(_vm._f("decimalsFormatter")(_vm._f("unitConvertor")(item.totalRemovalCap,_vm.$imperials.MASS),1))+" ")])]}},{key:"item.timeForBreakThrough",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(" "+_vm._s(item.timeForBreakThrough)+" ")])]}}],null,true)})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }