import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import handleError from "../../../constants/handleError.js";
import { EventBus } from "../../../constants/event-bus.js";

Vue.use(Vuex);

const state = {
  salesManager: false,
  dataManager: false,
  admin: false,
  internal: false,
  usersBranch: null,
};

const getters = {
  isSalesManager: (state) => state.salesManager,
  isDataManager: (state) => state.dataManager,
  isAdmin: (state) => state.admin,
  isInternal: (state) => state.internal,
  userBranch: (state) => state.userBranch,
};

const mutations = {
  setSalesManager(state, value) {
    state.salesManager = value;
  },
  setDataManager(state, value) {
    state.dataManager = value;
  },
  setAdmin(state, value) {
    state.admin = value;
  },
  setInternal(state, value) {
    state.internal = value;
  },

  setUserBranch(state, value) {
    state.userBranch = value;
  },
};

const actions = {
  async getUsers() {
    try {
      const response = await axios.get(`/api/user-admin`);

      return response.data;
    } catch (err) {
      console.log(err, "err");
    }
  },

  async postForUser({ commit, dispatch }, account) {
    try {
      await dispatch("getAccessToken", account);
      const response = await axios.post(
        `/api/user-admin/${encodeURI(account.username)}`,
        { userId: account.localAccountId }
      );
      if (!response) {
        throw { response: "User not found" };
      }

      if (response.data && !response.data.branch) {
        Vue.$toast.open({
          message:
            "Please contact the administrator to complete the setup of your user, before you can access the application.",
          duration: 0,
          type: "warning",
        });

        EventBus.$emit("dataRestricted", true);
      } else {
        EventBus.$emit("dataRestricted", false);
      }

      commit("setSalesManager", response?.data?.salesManager || false);
      commit("setDataManager", response?.data?.dataManager || false);
      commit("setAdmin", response?.data?.admin || false);
      commit("setInternal", response?.data?.internal || false);
      commit("setUserBranch", response?.data?.branch || null);
    } catch (err) {
      const errorMessage = handleError(err)?.data;
      return errorMessage;
    }
  },

  async updateUsers(context, payload) {
    console.log(context);
    try {
      let response = undefined;
      if (payload["id"] === undefined || payload["id"] === "") {
        response = await axios.post(`/api/user-admin`, payload);
      } else {
        response = await axios.put(`/api/user-admin`, payload);
      }
      return response.data;
    } catch (err) {
      const errorMessage = handleError(err)?.data;
      return errorMessage;
    }
  },

  async deleteUsers(context, payload) {
    console.log(context);
    try {
      let response = undefined;
      response = await axios.delete(`/api/user-admin`, { data: payload });

      return response.data;
    } catch (err) {
      const errorMessage = handleError(err)?.data;
      return errorMessage;
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
