export const gClassHeaders = [
  {
    text: "Selected gases",
    class: "align white-column",
    value: "gasName",
    sortable: false,
    width: "50%",
  },
  {
    text: "G1",
    class: "align G1",
    value: "G1",
    sortable: false,
    width: "10%",
    align: "center",
  },
  {
    text: "G2",
    class: "align G2",
    value: "G2",
    sortable: false,
    width: "10%",
    align: "center",
  },
  {
    text: "G3",
    class: "align G3",
    value: "G3",
    sortable: false,
    width: "10%",
    align: "center",
  },
  {
    text: "GX",
    class: "align GX",
    value: "GX",
    sortable: false,
    width: "10%",
    align: "center",
  },
  {
    text: "GX+",
    class: "align GX-plus",
    value: "GXPlus",
    sortable: false,
    width: "10%",
    align: "center",
  },
];
