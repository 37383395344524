import storeCommercial from "../store/modules/commercialComparison";
import storeTechincal from "../store/modules/technicalComparison";
import { currencyEnum } from "./currencies";

const COEFICIENT = 24.06104531461;

const fromMgM3ToPpm = (concentration, gasMolarMass) =>
  ((concentration * COEFICIENT) / gasMolarMass).toFixed(3);

const fromMgM3ToPpb = (concentration, gasMolarMass) =>
  (fromMgM3ToPpm(concentration, gasMolarMass) * 1000).toFixed(3);

const fromPpmToMgM3 = (concentration, gasMolarMass) =>
  ((concentration * gasMolarMass) / COEFICIENT).toFixed(3);

const fromPpbToMgM3 = (concentration, gasMolarMass) =>
  ((concentration * gasMolarMass) / (COEFICIENT * 1000)).toFixed(3);

const totalGas = (gasConcentration, airflow, operatingTimeYear) => {
  const calc = (
    (gasConcentration * airflow * operatingTimeYear) /
    1000000
  ).toFixed(3);
  if (isNaN(calc)) {
    return "";
  }
  return calc;
};

const weightOfMedia = (totalGas, media) =>
  ((totalGas * 100) / media).toFixed(1);

const mediaTypePD = (
  faceVelocityAtBed,
  bedDepthPerElement,
  PDFactor,
  media
) => {
  if (media) {
    const selectedMediaCoeffs = storeTechincal.state.DPCoeff.find(
      (item) => item.media === media
    );

    const DPA = selectedMediaCoeffs?.DPA;
    const DPB = selectedMediaCoeffs?.DPB;
    const DPC = selectedMediaCoeffs?.DPC;
    const a = DPA * (faceVelocityAtBed * faceVelocityAtBed);
    const b = DPB * faceVelocityAtBed;
    const c = DPC;

    return ((a + b + c) / 75) * bedDepthPerElement * PDFactor;
  }
  return 0;
};

const unitCost = (option) => {
  const exchangeRate = storeCommercial.state.exchangeRate;
  const selectedCurrency = storeCommercial.state.selectedCurrency;
  let optionCost = 0;

  if (exchangeRate && selectedCurrency !== currencyEnum.EUR) {
    if (option && Object.keys(option).length !== 0 && option.cost) {
      optionCost = option.cost * exchangeRate;
    }
  } else {
    if (option && Object.keys(option).length !== 0 && option.cost) {
      optionCost = option.cost;
    }
  }

  return parseFloat(optionCost);
};

const elementCosts = (layout) => {
  if (
    layout &&
    Object.keys(layout).length !== 0 &&
    layout.gpfElement &&
    layout.elementsPerStage &&
    layout.bedsUsed
  ) {
    const { gpfElement, elementsPerStage, bedsUsed } = layout;

    const exchangeRate = storeCommercial.state.exchangeRate;
    const selectedCurrency = storeCommercial.state.selectedCurrency;

    let price = 0;
    const priceItem = storeCommercial.state.prices.find(
      (price) => price.item === gpfElement
    );

    if (priceItem) {
      if (exchangeRate && selectedCurrency !== currencyEnum.EUR) {
        price = priceItem.cost
          ? (priceItem.cost / (1 - priceItem.GM / 100)) * exchangeRate
          : null;
      } else {
        price = priceItem.cost
          ? priceItem.cost / (1 - priceItem.GM / 100)
          : null;
      }
    }

    if (price !== null) {
      price = parseFloat(price * elementsPerStage * bedsUsed).toFixed(2);
    } else {
      price = 0;
    }

    return parseFloat(price);
  }

  return 0;
};

const mediaCost = (stage, option) => {
  if (stage && Object.keys(stage).length !== 0) {
    if (stage[option]) {
      let finalPrice = 0;
      let price = 0;
      const exchangeRate = storeCommercial.state.exchangeRate;
      const selectedCurrency = storeCommercial.state.selectedCurrency;

      const priceItem = storeCommercial.state.prices.find(
        (price) => price.item === stage[option].mediaTypeName
      );

      if (priceItem) {
        let priceItemCost = 0;

        if (exchangeRate && selectedCurrency !== currencyEnum.EUR) {
          priceItemCost = priceItem.cost * exchangeRate;
        } else {
          priceItemCost = priceItem.cost;
        }

        price = priceItemCost / (1 - priceItem.GM / 100);
      }

      if (priceItem && priceItem !== "" && priceItem !== "-") {
        finalPrice = parseFloat(price * stage[option].capacity).toFixed(2);
      } else {
        finalPrice = 0;
      }

      return finalPrice;
    }
  }

  return 0;
};

const filterCost = (option, allFilters, prefilter = true) => {
  const model = prefilter === true ? "prefilterModel" : "finalFilterModel";
  const qty = prefilter === true ? "prefilterQty" : "finalFilterQty";

  const exchangeRate = storeCommercial.state.exchangeRate;
  const selectedCurrency = storeCommercial.state.selectedCurrency;

  if (option) {
    if (option[model] && option[qty]) {
      let price = 0;
      const filter = allFilters.find((elem) => elem.model === option[model]);

      if (filter && filter.price) {
        if (filter.price !== "") {
          if (exchangeRate && selectedCurrency !== currencyEnum.EUR) {
            price = filter.price * option[qty] * exchangeRate;
          } else {
            price = filter.price * option[qty];
          }
        } else {
          price = 0;
        }
      }

      return parseFloat(price).toFixed(2);
    }
  }
  return 0;
};

const annualizeCost = (filtersAndPellets, changeout) => {
  const exchangeRate = storeCommercial.state.exchangeRate;
  const selectedCurrency = storeCommercial.state.selectedCurrency;

  let price = 0;

  if (
    isNaN(changeout) ||
    changeout === "" ||
    changeout === 0 ||
    changeout === "0"
  ) {
    return 0;
  }

  if (exchangeRate && selectedCurrency !== currencyEnum.EUR) {
    price = (filtersAndPellets / (changeout / 12)) * exchangeRate;
  } else {
    price = filtersAndPellets / (changeout / 12);
  }

  return parseFloat(price).toFixed(2);
};

export {
  fromMgM3ToPpm,
  fromMgM3ToPpb,
  fromPpmToMgM3,
  fromPpbToMgM3,
  totalGas,
  weightOfMedia,
  mediaTypePD,
  elementCosts,
  mediaCost,
  filterCost,
  unitCost,
  annualizeCost,
};
