import { render, staticRenderFns } from "./CustomModal.vue?vue&type=template&id=32b5bd90&scoped=true&"
import script from "./CustomModal.vue?vue&type=script&lang=js&"
export * from "./CustomModal.vue?vue&type=script&lang=js&"
import style0 from "./CustomModal.vue?vue&type=style&index=0&id=32b5bd90&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32b5bd90",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
installComponents(component, {VCard,VCardActions,VCardText,VCardTitle,VDialog,VDivider})
