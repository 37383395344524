import Vue from "vue";
import { mediaTypePD } from "../../constants/formulas";

const axios = require("axios");

const state = {
  mediaTypes: [],
  filtersData: [],
  inletTypes: [],
  selectedInletType: {},
  outletTypes: [],
  selectedOutletType: {},
  positionsInlet: [],
  selectedPositionInlet: {},
  positionsOutlet: [],
  selectedPositionOutlet: {},
  pdMeasurements: [],
  selectedPd: {},
  casingMaterials: {
    optionA: [],
    optionB: [],
    optionC: [],
    optionD: [],
  },
  selectedCasingMaterialA: "",
  selectedCasingMaterialB: "",
  selectedCasingMaterialC: "",
  selectedCasingMaterialD: "",
  comparisonOptions: {
    optionA: {},
    optionB: {},
    optionC: {},
    optionD: {},
  },
  stages: { stage1: {}, stage2: {}, stage3: {}, stage4: {} },
  maxAirflow: 2500,
  unitSelectedOption: "",
  selectedCassingMaterialsCosts: {
    optionA: {},
    optionB: {},
    optionC: {},
    optionD: {},
  },
  selectedUnitSize: "",
  DPCoeff: [],
  btsFactor: 85,
  remarks: "",
};

const getters = {
  mediaTypes: (state) => state.mediaTypes,
  filtersData: (state) => state.filtersData,
  comparisonOptions: (state) => state.comparisonOptions,
  maxAirflow: (state) => state.maxAirflow,
  stages: (state) => state.stages,
  unitSelectedOption: (state) => state.unitSelectedOption,
  allInletTypes: (state) => state.inletTypes,
  selectedInletType: (state) => state.selectedInletType,
  allOutletTypes: (state) => state.outletTypes,
  selectedOutletType: (state) => state.selectedOutletType,
  allPositionsInlet: (state) => state.positionsInlet,
  selectedPositionInlet: (state) => state.selectedPositionInlet,
  allPositionsOutlet: (state) => state.positionsOutlet,
  selectedPositionOutlet: (state) => state.selectedPositionOutlet,
  allPdMeasurements: (state) => state.pdMeasurements,
  selectedPd: (state) => state.selectedPd,
  allCassingMaterials: (state) => state.casingMaterials,
  selectedCasingMaterialA: (state) => state.selectedCasingMaterialA,
  selectedCasingMaterialB: (state) => state.selectedCasingMaterialB,
  selectedCasingMaterialC: (state) => state.selectedCasingMaterialC,
  selectedCasingMaterialD: (state) => state.selectedCasingMaterialD,
  selectedCassingMaterialsCosts: (state) => state.selectedCassingMaterialsCosts,
  selectedUnitSize: (state) => state.selectedUnitSize,
  DPCoeff: (state) => state.DPCoeff,
  btsFactor: (state) => state.btsFactor,
  remarks: (state) => state.remarks,
};

const mutations = {
  setBtsFactor(state, payload) {
    state.btsFactor = payload;
  },

  setRemarks(state, payload) {
    state.remarks = payload;
  },

  setComparisonOptions(state, payload) {
    Vue.set(
      state.comparisonOptions[payload.option],
      payload.comparisonItem,
      payload.value
    );
  },

  setStages(state, payload) {
    Vue.set(state.stages[payload.stage], payload.option, payload.value);
  },

  resetStage(state, payload) {
    const { stage, option } = payload;
    Vue.delete(state.stages[stage], option);

    // Vue.delete(state.stages[payload.stage], payload.option, payload.value);
  },

  setUnitSelectedOption(state, payload) {
    state.unitSelectedOption = payload;
  },

  setInletTypesList(state, payload) {
    state.inletTypes = payload;
  },

  changeSelectedInletType(state, payload) {
    state.selectedInletType = payload;
  },

  setOutletTypesList(state, payload) {
    state.outletTypes = payload;
  },

  changeSelectedOutletType(state, payload) {
    state.selectedOutletType = payload;
  },

  setPositionsInletList(state, payload) {
    state.positionsInlet = payload;
  },

  changeSelectedPositionInlet(state, payload) {
    state.selectedPositionInlet = payload;
  },

  setPositionsOutletList(state, payload) {
    state.positionsOutlet = payload;
  },

  changeSelectedPositionOutlet(state, payload) {
    state.selectedPositionOutlet = payload;
  },

  setPdMeasurementsList(state, payload) {
    state.pdMeasurements = payload;
  },

  changeSelectedPd(state, payload) {
    state.selectedPd = payload;
  },

  setCasingMaterialsList(state, payload) {
    state.casingMaterials = payload;
  },

  changeSelectedCasingMaterialA(state, payload) {
    state.selectedCasingMaterialA = payload;
  },

  changeSelectedCasingMaterialB(state, payload) {
    state.selectedCasingMaterialB = payload;
  },

  changeSelectedCasingMaterialC(state, payload) {
    state.selectedCasingMaterialC = payload;
  },

  changeSelectedCasingMaterialD(state, payload) {
    state.selectedCasingMaterialD = payload;
  },

  setSelectedCassingMaterials(state, payload) {
    state.selectedCassingMaterialsCosts = payload;
  },

  setFiltersData(state, payload) {
    state.filtersData = payload;
  },

  setMediaTypes(state, payload) {
    state.mediaTypes = payload;
  },

  setSelectedInletType(state, payload) {
    state.selectedInletType = payload;
  },

  setSelectedUnitSize(state, payload) {
    state.selectedUnitSize = payload;
  },

  setDPCoeff(state, payload) {
    state.DPCoeff = payload;
  },

  resetFilters(state) {
    Object.values(state.comparisonOptions).map((option) => ({
      ...option,
      prefilterModel: "",
      finalFilterModel: "",
      prefilterClass: "",
      finalFilterClass: "",
    }));
  },
};

const actions = {
  updateBtsFactor(context, payload) {
    context.commit("setBtsFactor", payload);
  },

  setRemarks(context, payload) {
    context.commit("setRemarks", payload);
  },

  setComparisonOptions(context, payload) {
    context.commit("setComparisonOptions", payload);
  },

  setStages(context, payload) {
    context.commit("setStages", payload);
  },

  resetStage(context, payload) {
    context.commit("resetStage", payload);
  },

  setUnitSelectedOption(context, payload) {
    context.commit("setUnitSelectedOption", payload);
  },

  async postPdfData(context, payload) {
    try {
      const res = await axios.post("/api/pdf-generator", payload);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  },

  setInletTypesList(context, payload) {
    context.commit("setInletTypesList", payload);
  },

  setOutletTypesList(context) {
    context.commit("setOutletTypesList");
  },

  setPositionsInletList(context) {
    context.commit("setPositionsInletList");
  },

  setPositionsOutletList(context) {
    context.commit("setPositionsOutletList");
  },

  setPdMeasurementsList(context) {
    context.commit("setPdMeasurementsList");
  },

  setCasingMaterialsList(context, payload) {
    context.commit("setCasingMaterialsList", payload);
  },

  setSelectedCassingMaterials(context, payload) {
    const { value, option } = payload;
    const { selectedCassingMaterialsCosts: newSelectedCassingMaterialsCosts } =
      context.getters;

    const optionText = `option${option}`;
    const foundCost = context.getters.dwgsCosts.filter(
      (cost) => cost.concatenateModel === value
    );

    if (foundCost && foundCost.length !== 0) {
      newSelectedCassingMaterialsCosts[optionText] = foundCost[0];
    } else {
      newSelectedCassingMaterialsCosts[optionText] = {};
    }

    context.commit(
      "setSelectedCassingMaterials",
      newSelectedCassingMaterialsCosts
    );
  },

  async fetchFiltersData({ commit, getters }) {
    try {
      const region = getters.projectRegion;
      const res = await axios.get("/api/filters", {
        params: { branch: region },
      });

      commit("setFiltersData", res.data);
    } catch (err) {
      console.log(err, "err");
    }
  },

  async fetchMediaTypes(context) {
    try {
      const res = await axios.get("/api/media-types");
      if (context.state.mediaTypes.length === 0) {
        context.commit("setMediaTypes", res.data);
      }
    } catch (err) {
      console.log(err, "err");
    }
  },

  async fetchInletTypes(context) {
    try {
      const res = await axios.get("/api/inlet-types");
      if (context.state.inletTypes.length === 0) {
        context.commit("setInletTypesList", res.data);
      }
    } catch (err) {
      console.log(err, "err");
    }
  },

  async fetchOutletTypes(context) {
    try {
      const res = await axios.get("/api/outlet-types");
      if (context.state.outletTypes.length === 0) {
        context.commit("setOutletTypesList", res.data);
      }
    } catch (err) {
      console.log(err, "err");
    }
  },

  async fetchPdMeasurements(context) {
    try {
      const res = await axios.get("/api/pd-measurements");
      if (context.state.pdMeasurements.length === 0) {
        context.commit("setPdMeasurementsList", res.data);
      }
    } catch (err) {
      console.log(err, "err");
    }
  },

  async fetchPositionsInletOutlet(context) {
    try {
      const resInlet = await axios.get("/api/inlet-positions");
      const resOutlet = await axios.get("/api/outlet-positions");
      if (
        context.state.positionsInlet.length === 0 ||
        context.state.positionsOutlet.length === 0
      ) {
        context.commit("setPositionsInletList", resInlet.data);
        context.commit("setPositionsOutletList", resOutlet.data);
      }
    } catch (err) {
      console.log(err, "err");
    }
  },

  async fetchCasingMaterials() {
    try {
      const response = await axios.get("/api/casing-materials");
      return response;
    } catch (err) {
      console.log(err, "err");
    }
  },

  setSelectedUnitSize({ commit }, payload) {
    commit("setSelectedUnitSize", payload);
  },

  async getDPCoeffs({ state, commit }) {
    try {
      const res = await axios.get("/api/dp-calcs");
      if (state.DPCoeff.length === 0) {
        commit("setDPCoeff", res.data);
      }
    } catch (err) {
      return err;
    }
  },

  calculateMediaCapacity({ state, getters, commit, dispatch }, payload) {
    const media = state.mediaTypes.find(
      (mediaType) => mediaType.id == payload.mediaTypeId
    );
    const mediaTypeValue = media?.mediaTypeValue;

    const mediaTypeName = media?.mediaTypeName;

    const item = getters.selectedLayouts.find(
      (layout) => layout.option === payload.option
    );

    const capacity = item?.bedVolPerStage * mediaTypeValue;
    Vue.nextTick(() => {
      dispatch("updateMediaPD", {
        mediaTypeId: payload.mediaTypeId,
        option: payload.option,
      }).then((res) => {
        commit("setStages", {
          stage: payload.stage,
          option: `option${payload.option}`,
          value: {
            capacity: capacity,
            mediaType: payload.mediaTypeId,
            mediaTypeName: mediaTypeName,
            PD: res ? Math.round(res) : "",
          },
        });
      });
    });
  },

  updateFiltersClass({ state, commit }, payload) {
    const filter = state.filtersData.find(
      (filter) => payload.value === filter.model
    );
    let filterClass = "";
    if (filter && filter.class) {
      filterClass = filter.class;
    }

    commit("setComparisonOptions", {
      option: payload.option,
      comparisonItem: payload.comparisonItem,
      value: filterClass,
    });
  },

  updateFilterImg({ state, commit }, payload) {
    const filter = state.filtersData.find(
      (filter) => payload.value === filter.model
    );
    let filterImgUrl = "";
    if (filter && filter.picture) {
      filterImgUrl = filter.picture;
    }

    commit("setComparisonOptions", {
      option: payload.option,
      comparisonItem: payload.comparisonItem,
      value: filterImgUrl,
    });
  },

  updateTotalResidenceTimePerUnit({ commit, getters }, payload) {
    Vue.nextTick(() => {
      const masterlist = getters.masterlist;
      let selectedItem = masterlist.find(
        (layout) => layout.option == payload.item
      );
      if (selectedItem) {
        const residenceTimePerBed = selectedItem.residenceTimePerBed;
        const stagesNr = selectedItem.bedsUsed;

        const totalResidenceTimePerUnit = residenceTimePerBed * stagesNr;

        commit("setComparisonOptions", {
          option: payload.option,
          comparisonItem: "totalResidenceTimePerUnit",
          value: totalResidenceTimePerUnit,
        });
      }
    });
  },

  updateMediaPD({ state, getters }, payload) {
    const masterlist = getters.masterlist;
    const media = state.mediaTypes.find(
      (mediaType) => mediaType.id == payload.mediaTypeId
    );

    const mediaTypeName = media?.mediaTypeName;
    let selectedItem = masterlist.find(
      (layout) => layout.option == payload.option
    );
    if (selectedItem) {
      const faceVelocity = selectedItem.faceVelocityAtBed;
      const bedDepth = selectedItem.bedDepthPerElem;
      const PDFactor = selectedItem.pdFactorPerElement;

      const PD = mediaTypePD(faceVelocity, bedDepth, PDFactor, mediaTypeName);

      return PD;
    }
  },

  updateSelectedUnitSize({ getters, commit }, payload) {
    if (payload) {
      const option = getters.masterlist.find(
        (item) => item.option === payload.selectedUnit
      );

      if (option) {
        const model = option.unitModel.split("-")[0];

        commit("setSelectedUnitSize", model);
      }
    }
  },

  emptyUnusedStages({ state }, payload) {
    Object.values(state.stages).forEach((stage, index) => {
      if (index + 1 > payload.stagesNr) {
        stage[`option${payload.option}`] = null;
      }
    });
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
