<template>
  <v-app-bar
    class="header logo-section white"
    flat
    light
    elevation="0"
    fixed
    app
  >
    <img class="logo-style" :src="chemGuideLogo" />

    <v-spacer></v-spacer>
    <img class="logo-style" id="fft" :src="freudLogo" />

    <v-btn
      icon
      color="red lighten-2"
      dark
      v-on:click="signIn"
      v-if="!account && !isDevelopmentMode"
    >
      <img :src="userIcon" class="circle" />
    </v-btn>
    <v-dialog width="350" class="account" v-if="account || isDevelopmentMode">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon color="red lighten-2" dark v-bind="attrs" v-on="on">
          <img :src="userIcon" class="circle" />
        </v-btn>
      </template>

      <v-card class="dialog-options w-64">
        <v-card-title class="d-block text-center card-title"
          ><div><img :src="userIcon" class="circle" id="account-image" /></div>
          <div class="name bold">{{ accountName }}</div>
        </v-card-title>
        <v-divider class="divider"></v-divider>
        <v-card-text class="text-center pt-3">
          <div class="external-view pt-3 text-center" v-can-access:internal>
            <span class="pr-5">External view</span
            ><v-switch
              class="mt-0 pt-0 view-switch"
              v-model="externalViewSwitch"
              color="#004388"
              @change="handleExternalViewChange"
            ></v-switch>
          </div>
          <div class="pt-0">
            <a class="logout" @click="signOut()">Logout</a>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <template>
      <custom-modal
        v-model="showDialog"
        title=" Warning"
        :buttons="dialogButtons"
        :persist="true"
        width="40rem"
        @cancel="handleCancelSwitchExternalView"
        @confirm="handleConfirmSwitchExternalView"
      >
        <div class="mx-4 my-4">
          You are about to turn off the external view. <br />You will display
          internal pricing information.
        </div>
      </custom-modal>
    </template>
  </v-app-bar>
</template>
<script>
import chemGuideLogo from "../../assets/images/ChemGuide_Logo_cmyk.png";
import freudLogo from "../../assets/images/u5.png";
import userIcon from "../../assets/images/u117.png";
import CustomModal from "../../components/base/CustomModal.vue";
import { PublicClientApplication } from "@azure/msal-browser";

export default {
  name: "TheHeader",

  components: {
    CustomModal,
  },

  created() {
    this.$msalInstance = new PublicClientApplication(
      this.$store.state.msalConfig
    );
  },

  async mounted() {
    await this.$msalInstance.handleRedirectPromise();
    const accounts = this.$msalInstance.getAllAccounts();
    if (accounts.length == 0) {
      return;
    }
    this.account = accounts[0];
    this.$emitter.emit("login", this.account);
  },

  data() {
    return {
      chemGuideLogo: chemGuideLogo,
      freudLogo: freudLogo,
      userIcon: userIcon,
      account: undefined,
      loginName: "",

      selectedUnit: "metric",
      showDialog: false,
      unitOptions: [
        {
          key: "metric",
          text: "Metric",
        },
        {
          key: "imperial",
          text: "Imperial",
        },
      ],
      dialogButtons: {
        cancel: true,
        confirm: true,
      },
    };
  },

  methods: {
    handleOptionChange(selectedKey) {
      this.selectedUnit = selectedKey;
    },

    handleExternalViewChange(isExternalView) {
      if (isExternalView === false) {
        this.openDialog();
      } else {
        this.$store.dispatch("changeStepsVisibility", isExternalView);
      }
    },

    handleCancelSwitchExternalView() {
      this.$store.dispatch("changeStepsVisibility", true);
      this.externalViewSwitch = true;
    },

    handleConfirmSwitchExternalView() {
      this.$store.dispatch("changeStepsVisibility", false);
    },

    openDialog() {
      this.showDialog = true;
    },

    signIn() {
      this.$msalInstance
        .loginPopup({})
        .then(() => {
          const myAccounts = this.$msalInstance.getAllAccounts();
          this.account = myAccounts[0];

          this.loginName = this.account.name;
          this.$emitter.emit("login", this.account);
        })
        .catch((error) => {
          console.error(`error during authentication: ${error}`);
        });
    },

    signOut() {
      this.$msalInstance
        .logout({})
        .then(() => {
          this.$emitter.emit("logout", "logging out");
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },

  computed: {
    externalViewSwitch: {
      get() {
        return this.$store.getters.externalViewSwitch;
      },
      set(newValue) {
        this.$store.dispatch("setExternalView", newValue);
      },
    },
    isDevelopmentMode: {
      get() {
        return false; // process.env.NODE_ENV === "development";
      },
    },
    accountName() {
      return this.$store.getters.account?.name || "-";
    },
  },
};
</script>
<style lang="scss" scoped>
.v-app-bar.header {
  height: auto !important;
  z-index: 9;
}

::v-deep .v-toolbar__content {
  height: 45px !important;
}

.logo-section {
  padding-right: 25px;
  padding-top: 10px;
  padding-left: 25px;
}

.logo-style {
  height: 38px;
}
.circle {
  border-radius: 100%;
  width: 35px;
  height: 35px;
}

.account {
  position: relative;
}

.v-dialog > .v-card {
  .card-title {
    font-size: 1rem;
    line-height: 1.5;
  }
  .divider {
    background-color: $basic-blue;
    margin: auto;
    width: 70%;
    border-width: thin 1;
  }
}

.v-dialog > .dialog-options {
  position: absolute;
  right: 17px;
  top: 80px;
  width: 320px;
  border-radius: 0;
  border: 1px solid;
  box-shadow: 3px 3px 3px #424242;
}

.dialog-options:before {
  content: url("../../assets/svg/accountArrow.svg");
  position: absolute;
  right: 35px;
  margin-top: -17px;
  z-index: -1;
}

.v-card > .v-card__text {
  .measuring-unit,
  .external-view {
    .view-switch {
      height: 30px;
    }
    display: inline-flex;
    color: $dark-blue;
  }
}

.logout {
  color: $dark-blue;
}

#account-image {
  width: 70px;
  height: 70px;
  margin: auto;
}

#fft {
  padding-right: 40px;
}
</style>
