<template>
  <div>
    <helper-box
      text="Select up to 4 options to compare (A-D). Filter and sort the columns depending on the project's priorities."
    />
    <v-row class="mt-15 mb-8">
      <v-col cols="auto" class="selection font-weight-bold">
        <span class="selection-tag">Set selection:</span>
        <drop @drop="onDropSelectionOnOriginalList">
          <transition-group name="list" tag="div" class="options">
            <drag
              v-for="item in options"
              :key="item.id"
              class="drag-elem"
              :data="item"
              >{{ item.title }}
            </drag>
          </transition-group>
        </drop>
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="airflow text-end">
        <span> Airflow: </span>
        <span class="ml-4">
          {{ airflow | unitConvertor($imperials.AIRFLOW) | roundFormatter }}
          <span> {{ selectedAirflowMeasureUnitKey }}</span>
        </span>
        <br />
        <div class="d-flex justify-end mt-2">
          <custom-link
            class="bold"
            text="Show recommended residence times per G-class"
            @click="handleShowGClassPress"
          />
        </div>
      </v-col>
    </v-row>
    <div class="component-header">
      <div class="table-buttons d-flex mb-1">
        <custom-link
          v-for="(btn, index) in tableButtons"
          :text="btn.name"
          :icon="btn.icon"
          :key="index"
          :class="`ml-5 table-button ${btn.name.toLowerCase()}`"
          color="#00A6E2"
          @click="handleTableBtnClick(btn.method)"
        >
        </custom-link>
      </div>
    </div>
    <v-data-table
      dense
      class="systemOptions text-center"
      :headers="headers"
      :items="layouts"
      :hide-default-footer="true"
      disable-pagination
      fixed-header
      height="400"
    >
      <template v-slot:[`item.option`]="{ item }">
        <drag>
          <drop
            @drop="onDrop(item, $event)"
            class="dropZone"
            :accepts-data="() => item.option === ''"
          >
            <div v-if="item.option !== ''">
              <div v-for="(option, i) in [item.option]" :key="i" class="option">
                {{ option }}
              </div>
            </div>
          </drop>
        </drag>
      </template>
      <template v-slot:[`item.residenceTime`]="{ item }">
        <div>
          {{ item.residenceTime ? item.residenceTime.toFixed(2) : null }}
        </div>
      </template>
    </v-data-table>

    <custom-modal
      v-model="filterModalOpen"
      :buttons="dialogButtons"
      :title="
        selectedFilterBy ? `Filter by: ${selectedFilterBy.text}` : 'Filter'
      "
      :showBackIcon="selectedFilterBy ? true : false"
      @clickOnBackIcon="selectedFilterBy = null"
      @confirm="handleConfirmFilter"
      width="20rem"
      height="50vh"
    >
      <div class="filter-modal" v-if="!selectedFilterBy">
        <div class="d-flex justify-space-between align-center">
          <span class="bold">Filter by</span>
          <div class="header-button mr-4">
            <custom-link text="Reset" @click="resetFilters" />
          </div>
        </div>
        <ul>
          <li
            v-for="filterOption in filterOptions"
            :key="filterOption.value"
            @click="handleSelectFilter(filterOption)"
          >
            {{ filterOption.text }}
          </li>
        </ul>
      </div>
      <custom-filter-list
        v-else
        :options="selectedFilterOptions"
        :label="selectedFilterBy.value.toString()"
        @updateOption="setFilterSelectedOption"
        :filteredValues="
          filters[`${selectedFilterBy.value}`]
            ? filters[`${selectedFilterBy.value}`]
            : []
        "
      />
    </custom-modal>

    <template>
      <custom-modal
        v-model="showGClassDialog"
        width="30rem"
        title="Recommended residence times per G-class (in sec.)"
        :buttons="dialogButtonsGClassDialog"
      >
        <div class="mx-4 my-4">
          <table class="text-center">
            <thead>
              <tr>
                <th scope="row"></th>
                <td class="empty"></td>
                <td colspan="2">Per stage</td>
              </tr>
              <tr>
                <th scope="row"></th>
                <td></td>
                <td>Min</td>
                <td>Ideal</td>
              </tr>
            </thead>
            <tr>
              <th scope="row"></th>
              <td class="G1">G1</td>
              <td>0,03</td>
              <td>0,07</td>
            </tr>
            <tr>
              <th scope="row"></th>
              <td class="G2">G2</td>
              <td>0,06</td>
              <td>0,12</td>
            </tr>
            <tr>
              <th scope="row"></th>
              <td class="G3">G3</td>
              <td>0,12</td>
              <td>0,2</td>
            </tr>
            <tr>
              <th scope="row"></th>
              <td class="GX">GX</td>
              <td>0,28</td>
              <td>0,5</td>
            </tr>
            <tr>
              <th scope="row"></th>
              <td class="GX-plus">GX+</td>
              <td>0,45</td>
              <td>0,6</td>
            </tr>
          </table>
        </div>
      </custom-modal>
    </template>
  </div>
</template>
<script>
import HelperBox from "../../components/base/HelperBox.vue";
import CustomLink from "../../components/base/CustomLink.vue";
import CustomModal from "../../components/base/CustomModal.vue";
import CustomFilterList from "../../components/base/CustomFilterList.vue";
import { Drag, Drop } from "vue-easy-dnd";
import filterIcon from "@/assets/svg/filter_icon.svg";
import { units } from "../../constants/measuringUnits.js";
import Vue from "vue";
import { mapGetters } from "vuex";

export default {
  name: "SystemOptions",

  components: {
    HelperBox,
    CustomLink,
    CustomModal,
    CustomFilterList,
    Drag,
    Drop,
  },

  data() {
    return {
      airflowUnits: {
        [units.Metric]: {
          text: "m³/h",
        },
        [units.Imperial]: {
          text: "ft³/min",
        },
      },
      velocityUnits: {
        [units.Metric]: {
          text: "m/s",
        },
        [units.Imperial]: {
          text: "ft/min",
        },
      },
      volumeUnits: {
        [units.Metric]: {
          text: "m³",
        },
        [units.Imperial]: {
          text: "ft³",
        },
      },
      lengthUnits: {
        [units.Metric]: {
          text: "mm",
        },
        [units.Imperial]: {
          text: "in",
        },
      },
      showGClassDialog: false,
      dialogButtons: {
        cancel: true,
        confirm: true,
      },
      dialogButtonsGClassDialog: {
        cancel: true,
        confirm: false,
      },
      tableButtons: [
        { name: "Filter", icon: filterIcon, method: "handleClickFilter" },
      ],

      filterModalOpen: false,
      selectedFilterBy: null,
      selectedFilterOptions: [],
      isFilterApplied: false,
      selectedFilters: {},
      filterOptions: [
        { text: "Option", value: "option" },
        { text: "Construction model", value: "constructionModel" },
        { text: "System type", value: "systemType" },
        { text: "Nominal flow", value: "nominalFlow" },
        { text: "Layout", value: "layout" },
        { text: "Filter matrix", value: "filterMatrix" },
        { text: "Viledon", value: "viledon" },
        { text: "GPF element", value: "gpfElement" },
        { text: "Elements", value: "modules" },
        { text: "Bed depth", value: "bedDepth" },
        { text: "Residence time", value: "residenceTime" },
        { text: "Face velocity", value: "faceVelocity" },
      ],
    };
  },

  computed: {
    ...mapGetters([
      "airflow",
      "projectUnitMeasure",
      "options",
      "selectedLayouts",
      "masterlist",
      "selectedCassingMaterialsCosts",
    ]),

    ...mapGetters({
      filters: "filtersMasterlist",
      filteredLayouts: "filteredConstructionModels",
    }),

    selectedAirflowMeasureUnitKey: {
      get() {
        if (this.projectUnitMeasure == "Imperial") {
          return this.airflowUnits[units.Imperial].text;
        }
        return this.airflowUnits[units.Metric].text;
      },
    },

    selectedVelocityMeasureUnitKey: {
      get() {
        if (this.projectUnitMeasure == "Imperial") {
          return this.velocityUnits[units.Imperial].text;
        }
        return this.velocityUnits[units.Metric].text;
      },
    },

    selectedLengthUnitMeasure: {
      get() {
        if (this.projectUnitMeasure == "Imperial") {
          return this.lengthUnits[units.Imperial].text;
        }
        return this.lengthUnits[units.Metric].text;
      },
    },

    selectedVolumeUnitMeasure: {
      get() {
        if (this.projectUnitMeasure == "Imperial") {
          return this.volumeUnits[units.Imperial].text;
        }
        return this.volumeUnits[units.Metric].text;
      },
    },

    headers() {
      return [
        {
          text: "Option        ",
          value: "option",
          align: "center",
          class: "table-header",
          width: "5rem",
        },
        {
          text: "Construction model",
          value: "constructionModel",
          align: "center",
          sortable: true,
          class: "table-header",
        },
        {
          text: "System type",
          value: "systemType",
          align: "center",
          sortable: true,
          class: "table-header",
        },
        {
          text: "Nominal flow [" + this.selectedAirflowMeasureUnitKey + "]",
          value: "nominalFlow",
          align: "center",
          sortable: "true",
          class: "table-header",
        },
        {
          text: "Layout        ",
          value: "layout",
          align: "center",
          sortable: true,
          class: "table-header",
        },
        {
          text: "Filter matrix",
          value: "filterMatrix",
          align: "center",
          sortable: true,
          class: "table-header",
        },
        {
          text: "Viledon available",
          value: "viledon",
          align: "center",
          sortable: true,
          class: "table-header",
        },
        {
          text: "GPF element",
          value: "gpfElement",
          align: "center",
          sortable: true,
          class: "table-header",
        },
        {
          text: "Elements p/stage",
          value: "modules",
          align: "center",
          sortable: true,
          class: "table-header",
        },
        {
          text: "Bed depth per elem [" + this.selectedLengthUnitMeasure + "]",
          value: "bedDepth",
          align: "center",
          sortable: true,
          class: "table-header",
        },
        {
          text: "Residence time per bed [s]",
          value: "residenceTime",
          align: "center",
          sortable: "true",
          class: "table-header",
        },
        {
          text:
            "Face velocity at bed [" +
            this.selectedVelocityMeasureUnitKey +
            "]",
          value: "faceVelocity",
          align: "center",
          sortable: "true",
          class: "table-header",
        },
      ];
    },

    layouts() {
      if (this.isFilterApplied) {
        return this.mapList(this.filteredLayouts);
      }
      return this.mapList(this.masterlist);
    },
  },

  mounted() {
    this.$store.dispatch("fetchPrices");
    this.$store.dispatch("updateMasterlistCalcs");
  },

  methods: {
    mapList(list) {
      return list.map((item) => ({
        id: item.id,
        option: this.getSelectedOption(item.id) || "",
        layout: item.layout,
        constructionModel: item.unitModel,
        systemType: item.systemType,
        filterMatrix: item.filterMatrix,
        viledon: item.viledon === 1 ? "yes" : "no",
        gpfElement: item.gpfElement,
        modules: item.elementsPerStage,
        bedDepth: this.$options.filters.roundFormatter(
          this.$options.filters.unitConvertor(
            item.bedDepthPerElem,
            this.$imperials.LENGTH
          )
        ),
        residenceTime: item.residenceTimePerBed,
        faceVelocity: this.$options.filters.decimalsFormatter(
          this.$options.filters.unitConvertor(
            item.faceVelocityAtBed,
            this.$imperials.VELOCITY
          ),
          2
        ),
        nominalFlow: this.$options.filters.roundFormatter(
          this.$options.filters.unitConvertor(
            item.nominalFlow,
            this.$imperials.AIRFLOW
          )
        ),
        stagesNumber: item.bedsUsed,
      }));
    },

    handleShowGClassPress() {
      this.showGClassDialog = true;
    },

    getSelectedOption(id) {
      return this.selectedLayouts.find(
        (selectedLayout) => selectedLayout.id === id
      )?.option;
    },

    handleTableBtnClick(methodName) {
      this[methodName]();
    },

    handleClickFilter() {
      this.filterModalOpen = true;
    },

    setFilterSelectedOption(event) {
      this.selectedFilters[`${this.selectedFilterBy.value}`] = event;
    },

    handleSelectFilter(option) {
      this.selectedFilterBy = option;
      const methodName =
        option.value.charAt(0).toUpperCase() + option.value.slice(1);

      this[`get${methodName}`]();
    },

    uniqueElementsByValue(arr, fn) {
      return arr.reduce((acc, v) => {
        if (!acc.some((x) => fn(v, x))) acc.push(v);
        return acc;
      }, []);
    },

    getOption() {
      this.$store.dispatch("getOption").then((response) => {
        response = this.uniqueElementsByValue(
          response,
          (a, b) => a.option == b.option
        );
        this.selectedFilterOptions = response;
      });
    },

    getConstructionModel() {
      this.$store.dispatch("getConstructionModel").then((response) => {
        response = this.uniqueElementsByValue(
          response,
          (a, b) => a.constructionModel == b.constructionModel
        );
        this.selectedFilterOptions = response;
      });
    },

    getSystemType() {
      this.$store.dispatch("getSystemType").then((response) => {
        response = this.uniqueElementsByValue(
          response,
          (a, b) => a.systemType == b.systemType
        );
        this.selectedFilterOptions = response;
      });
    },

    getNominalFlow() {
      this.$store.dispatch("getNominalFlow").then((response) => {
        if (this.projectUnitMeasure === "Imperial") {
          response = response.map((item) => ({
            ...item,
            nominalFlow: this.$options.filters.roundFormatter(
              this.$options.filters.unitConvertor(
                parseInt(item.nominalFlow),
                this.$imperials.AIRFLOW
              )
            ),
          }));
        }
        response = this.uniqueElementsByValue(
          response,
          (a, b) => a.nominalFlow == b.nominalFlow
        );
        this.selectedFilterOptions = response;
      });
    },

    getLayout() {
      this.$store.dispatch("getLayout").then((response) => {
        response = this.uniqueElementsByValue(
          response,
          (a, b) => a.layout == b.layout
        );
        this.selectedFilterOptions = response;
      });
    },

    getFilterMatrix() {
      this.$store.dispatch("getFilterMatrix").then((response) => {
        response = this.uniqueElementsByValue(
          response,
          (a, b) => a.filterMatrix == b.filterMatrix
        );
        this.selectedFilterOptions = response;
      });
    },

    getViledon() {
      this.$store.dispatch("getViledon").then((response) => {
        response = this.uniqueElementsByValue(
          response,
          (a, b) => a.viledon == b.viledon
        );
        this.selectedFilterOptions = response;
      });
    },

    getGpfElement() {
      this.$store.dispatch("getGpfElement").then((response) => {
        response = this.uniqueElementsByValue(
          response,
          (a, b) => a.gpfElement == b.gpfElement
        );
        this.selectedFilterOptions = response;
      });
    },

    getModules() {
      this.$store.dispatch("getModule").then((response) => {
        response = this.uniqueElementsByValue(
          response,
          (a, b) => a.modules == b.modules
        );

        this.selectedFilterOptions = response;
      });
    },

    getBedDepth() {
      this.$store.dispatch("getBedDepth").then((response) => {
        if (this.$store.getters.projectUnitMeasure === "Imperial") {
          response = response.map((item) => ({
            ...item,
            bedDepth: Math.round(
              parseInt(item.bedDepth) *
                this.$imperialConstants[this.$imperials.LENGTH]
            ),
          }));
        }
        response = this.uniqueElementsByValue(
          response,
          (a, b) => a.bedDepth == b.bedDepth
        );
        this.selectedFilterOptions = response;
      });
    },

    getResidenceTime() {
      this.$store.dispatch("getResidenceTime").then((response) => {
        response = this.uniqueElementsByValue(
          response,
          (a, b) => a.residenceTime == b.residenceTime
        );
        this.selectedFilterOptions = response.map((item) => ({
          ...item,
          residenceTime: parseFloat(item.residenceTime).toFixed(2),
        }));
      });
    },

    getFaceVelocity() {
      this.$store.dispatch("getFaceVelocity").then((response) => {
        if (this.projectUnitMeasure === "Imperial") {
          response = response.map((item) => ({
            ...item,
            faceVelocity: this.$options.filters.decimalsFormatter(
              this.$options.filters.unitConvertor(
                parseFloat(item.faceVelocity),
                this.$imperials.VELOCITY
              ),
              2
            ),
          }));
        }
        response = this.uniqueElementsByValue(
          response,
          (a, b) => a.faceVelocity == b.faceVelocity
        );
        this.selectedFilterOptions = response.map((item) => ({
          ...item,
          faceVelocity: parseFloat(item.faceVelocity).toFixed(2),
        }));
      });
    },

    handleConfirmFilter() {
      this.$store.dispatch("setFiltersOptions", this.selectedFilters);
      this.filterModalOpen = false;
      this.selectedFilterBy = null;
      this.isFilterApplied = true;
    },

    resetFilters() {
      this.selectedFilters = {};
      this.$store.dispatch(`setFiltersOptions`, this.selectedFilters);
      this.filterModalOpen = false;
      this.isFilterApplied = false;
    },

    onDrop(item, event) {
      const index = this.layouts.findIndex((layout) => layout.id === item.id);
      const optionDragged = event.data
        ? event.data.title
        : event.source.$el.innerText;

      this.layouts.forEach((layout) => {
        if (layout.option === optionDragged) {
          this.$store.dispatch("removeSelectedLayout", {
            itemId: layout.id,
            option: optionDragged,
          });
        }
      });

      Vue.set(this.layouts[index], "option", optionDragged);
      this.$store.dispatch("resetOptionState", optionDragged);

      this.$store.dispatch("filterOptions", {
        action: "remove",
        value: optionDragged,
      });

      this.$store.dispatch("addSelectedLayouts", {
        itemId: item.id,
        option: optionDragged,
      });

      this.$store.dispatch("setElementsCost", {
        option: `option${optionDragged}`,
        type: "insert",
      });

      this.$store.dispatch("setIsOptionSelected", {
        option: optionDragged,
        isSelected: true,
      });

      this.$store.dispatch("setCode", {
        value: [
          item.constructionModel,
          this.selectedCassingMaterialsCosts[`option${optionDragged}`]
            .casingMaterial,
        ],
        option: optionDragged,
      });

      this.$store.dispatch("emptyUnusedStages", {
        option: optionDragged,
        stagesNr: this.layouts[index].stagesNumber,
      });

      this.$store.dispatch("updateProjectChangesState", true);
      this.$store.dispatch("setHasChanges", true);
    },

    onDropSelectionOnOriginalList(event) {
      const draggedElem = event.source.$el.innerText;

      const draggedData = this.layouts.find(
        (elem) => elem.option === draggedElem
      );

      const indexesMapping = {
        A: 0,
        B: 1,
        C: 2,
        D: 3,
      };

      this.$store.dispatch("resetOptionStages", draggedData.option);
      this.$store.dispatch("resetOptionState", draggedData.option);

      this.$store.dispatch("removeSelectedLayout", {
        itemId: draggedData.id,
        option: draggedData.option,
      });

      this.$store.dispatch("filterOptions", {
        action: "add",
        value: draggedElem,
        id: indexesMapping[draggedElem],
      });

      this.$store.dispatch("setElementsCost", {
        option: `option${draggedData.option}`,
        type: "remove",
      });

      this.$store.dispatch("setCode", {
        value: [
          "",
          this.$store.getters.selectedCassingMaterialsCosts[
            `option${draggedData.option}`
          ].casingMaterial,
        ],
        option: draggedData.option,
      });

      this.$store.dispatch("setIsOptionSelected", {
        option: draggedData.option,
        isSelected: false,
      });

      draggedData.option = "";

      this.$store.dispatch("setHasChanges", true);
    },
  },
};
</script>
<style lang="scss" scoped>
.selection-tag {
  vertical-align: -webkit-baseline-middle;
}

.dropZone {
  width: 5rem;
  height: 30px;
}

.option {
  background-color: $basic-blue;
  height: 30px;
  justify-content: space-evenly;
  padding: 0.3rem 0;
  color: $white;
}

.dnd-drop {
  flex-wrap: wrap;
}

.options {
  display: flex;
  flex-wrap: wrap;
  border: $base-border;
  padding: 12px;
  justify-content: space-between;
  gap: 0.3rem;
  min-height: 30px;
  min-width: 5rem;
}

.fft-dropdown.v-text-field--outlined,
.fft-dropdown.v-text-field--solo {
  width: 7rem;
}

::v-deep {
  .table-header {
    background-color: $accent-blue !important;
    border: $base-border !important;
    color: $white !important;
    font-weight: bold;
    font-size: 15px !important;
    padding: 0.4rem !important;

    span {
      font-weight: 300;
      font-size: 1rem;
    }
  }

  .systemOptions {
    border-radius: 0 !important;
  }

  td {
    vertical-align: middle !important;
    height: 30px !important;
    border: $base-border;
  }

  td:first-child {
    padding: 0 !important;
  }
}

.drag-elem {
  width: 5rem;
  height: 30px;
  color: $white;
  background-color: $basic-blue;
  display: flex;
  justify-content: center;
  align-items: center;
}

.selection {
  display: -webkit-inline-box;
  align-items: center;
  gap: 1rem;
}

.empty {
  visibility: hidden;
  border-left: 0;
}

div.v-card__text > div > table {
  margin: auto;
  > tr:nth-child(2) > td {
    width: 120px;
  }
}

.component-header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.table-buttons {
  margin-left: auto;
  margin-right: 0;
}

.table-button {
  @media (max-width: 600px) {
    margin-right: 1rem;
    margin-left: 0 !important;
  }

  &.filter {
    img {
      width: 1rem;
    }
  }
}

.v-select__selections {
  max-width: 110px;
}

.filter-modal {
  padding-top: 1rem;
  font-size: 1rem;

  span {
    padding-left: 1rem;
  }

  ul {
    margin-top: 0.5rem;
  }

  li {
    border-bottom: $base-border;
    padding: 0.3rem 0 0.4rem 1rem;
    cursor: pointer;

    &:first-child {
      border-top: $base-border;
    }
  }

  .header-button {
    font-size: 1rem;
  }
}
</style>
