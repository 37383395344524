import axios from "axios";

const state = {
  project: {
    projectName: "",
    projectSegment: "",
    projectCustomer: "",
    projectStatus: "",
    projectLocation: "",
    projectUnitMeasure: "",
    projectId: "",
    branchRegion: "",
    version: 0,
  },
  segments: [],
  statuses: [],
  units: [],
  regions: [],
  selectedRegion: "",
};

const getters = {
  projectName: (state) => state.project.projectName,
  projectSegment: (state) => state.project.projectSegment,
  projectCustomer: (state) => state.project.projectCustomer,
  projectId: (state) => state.project.projectId,
  segments: (state) => state.segments,
  statuses: (state) => state.statuses,
  units: (state) => state.units,
  regions: (state) => state.regions,
  projectStatus: (state) => state.project.projectStatus,
  projectLocation: (state) => state.project.projectLocation,
  projectUnitMeasure: (state) => state.project.projectUnitMeasure,
  project: (state) => state.project,
  branchRegion: (state) => state.project.branchRegion,
  selectedRegion: (state) => state.project.selectedRegion,
  projectRegion: (state) => {
    if (!state.regions || !state.project?.branchRegion) {
      return undefined;
    }

    const projectRegion = state.regions.find(
      (region) => region.id == state.project.branchRegion
    );
    return projectRegion?.branch;
  },
  constants: (state) => state.constants,
};

const mutations = {
  changeProject(state, project) {
    state.project.projectName = project.projectName;
    state.project.projectSegment = project.segmentId;
    state.project.projectCustomer = project.customer;
    state.project.projectStatus = project.projectType;
    state.project.projectLocation = project.location;
    state.project.projectUnitMeasure = project.unitOfMeasure;
    state.project.projectId = project.id;
    state.project.branchRegion = project.regionId;
    state.project.version = project.version;
  },

  changeProjectName(state, projectName) {
    state.project.projectName = projectName;
  },

  changeProjectId(state, res) {
    if (res && res.data) {
      state.project.projectId = res.data.id;
      state.project.version = res.data.version;
    }
  },

  changeProjectSegment(state, projectSegment) {
    state.project.projectSegment = projectSegment;
  },

  changeProjectCustomer(state, projectCustomer) {
    state.project.projectCustomer = projectCustomer;
  },

  setSegments(state, payload) {
    state.segments = payload;
  },

  setStatusList(state, payload) {
    state.statuses = payload;
  },

  setUnitsList(state, payload) {
    state.units = payload;
  },

  setRegionsList(state, payload) {
    state.regions = payload;
  },

  setSelectedRegion(state, payload) {
    state.selectedRegion = payload;
  },

  changeProjectStatus(state, projectStatus) {
    state.project.projectStatus = projectStatus;
  },

  changeProjectLocation(state, projectLocation) {
    state.project.projectLocation = projectLocation;
  },

  changeProjectUnitMeasure(state, projectUnitMeasure) {
    state.project.projectUnitMeasure = projectUnitMeasure;
  },

  changeBranchRegion(state, branchRegion) {
    state.project.branchRegion = branchRegion;
  },
};

const actions = {
  setProjectName(context, payload) {
    context.commit("setProjectName", payload);
  },

  setCustomer(context, payload) {
    context.commit("setCustomer", payload);
  },

  setLocation(context, payload) {
    context.commit("setLocation", payload);
  },

  async getStatusList(context) {
    try {
      const res = await axios.get("/api/statuses");
      if (context.state.statuses.length === 0) {
        context.commit("setStatusList", res.data);
      }
    } catch (err) {
      console.log(err, "err");
    }
  },

  async getUnitsList(context) {
    try {
      const res = await axios.get("/api/measure-units");
      if (context.state.units.length === 0) {
        context.commit("setUnitsList", res.data);
      }
    } catch (err) {
      console.log(err, "err");
    }
  },

  async getRegionsList(context) {
    try {
      const res = await axios.get("/api/branches");
      var regionOptions = [];
      if (context.state.regions.length === 0) {
        res.data.forEach((element) => {
          // var regionBranch = element.region + "(" + element.branch + ")";
          var regionBranch = {
            branch: element.branch,
            id: element.id,
          };
          regionOptions.push(regionBranch);
          context.commit("setRegionsList", regionOptions);
        });
      }
    } catch (err) {
      console.log(err, "err");
    }
  },

  async getRegionsListByAccount({ getters }) {
    try {
      const account = getters.account.localAccountId;
      if (!account || account === "") {
        return [];
      }

      const res = await axios.get("/api/branches/" + account);
      const regionOptions = res.data.map((element) => element.branch);

      console.log("poi", regionOptions);
      return regionOptions;
    } catch (err) {
      console.log(err, "err");
    }
  },

  async fetchProjectsRegions({ getters, commit }) {
    try {
      const res = await axios.get("/api/project-regions");

      const selectedRegion = res.data.find(
        (region) => getters.projectId == region.projectId
      );

      const formattedRegion =
        selectedRegion.region + "(" + selectedRegion.branch + ")";
      commit("setSelectedRegion", formattedRegion);
      commit("changeBranchRegion", formattedRegion);
      return selectedRegion;
    } catch (err) {
      console.log(err, "err");
    }
  },

  async fetchSegments(context) {
    try {
      const res = await axios.get("/api/segments");
      if (context.state.segments.length === 0) {
        context.commit("setSegments", res.data);
      }
    } catch (err) {
      console.log(err, "err");
    }
  },

  async fetchProjectEditor(context, param) {
    try {
      return await axios.get(`/api/project/${param}/edited`);
    } catch (err) {
      console.log(err, "err");
    }
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
