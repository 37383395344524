import { imperials } from "../../constants/imperialConstants";
import { imperialConstants } from "../../helpers/filters";
const state = {
  option: "",
  constructionModel: "",
  systemType: "",
  nominalFlow: "",
  layout: "",
  filterMatrix: "",
  viledon: "",
  gpfElement: "",
  modules: "",
  bedDepth: "",
  residenceTime: "",
  faceVelocity: "",
  filtersMasterlist: {},
  filteredConstructionModels: [],
  changes: false,
};

const getters = {
  option: (state) => state.option,
  constructionModel: (state) => state.constructionModel,
  systemType: (state) => state.systemType,
  nominalFlow: (state) => state.nominalFlow,
  layout: (state) => state.layout,
  filterMatrix: (state) => state.filterMatrix,
  viledon: (state) => state.viledon,
  gpfElement: (state) => state.gpfElement,
  modules: (state) => state.modules,
  bedDepth: (state) => state.bedDepth,
  residenceTime: (state) => state.residenceTime,
  faceVelocity: (state) => state.faceVelocity,
  filteredConstructionModels: (state) => state.filteredConstructionModels,
  filtersMasterlist: (state) => state.filtersMasterlist,
  changes: (state) => state.changes,
};

const mutations = {
  setHasChanges(state, payload) {
    state.changes = payload;
  },

  setOption(state, payload) {
    state.option = payload;
  },

  setConstructionModel(state, payload) {
    state.constructionModel = payload;
  },

  setSystemType(state, payload) {
    state.systemType = payload;
  },

  setNominalFlow(state, payload) {
    state.nominalFlow = payload;
  },

  setLayout(state, payload) {
    state.layout = payload;
  },

  setFilterMatrix(state, payload) {
    state.filterMatrix = payload;
  },

  setViledon(state, payload) {
    state.viledon = payload;
  },

  setGpfElement(state, payload) {
    state.gpfElement = payload;
  },

  setModule(state, payload) {
    state.modules = payload;
  },

  setBedDepth(state, payload) {
    state.bedDepth = payload;
  },

  setResidenceTime(state, payload) {
    state.residenceTime = payload;
  },

  setFaceVelocity(state, payload) {
    state.faceVelocity = payload;
  },

  setFiltersOptions(state, list) {
    if (list.payload) {
      state.filtersMasterlist = list.payload;
    }

    const optionFilter =
      state.filtersMasterlist.option &&
      state.filtersMasterlist.option.length > 0
        ? state.filtersMasterlist.option
        : false;
    const constructionModelFilter =
      state.filtersMasterlist.constructionModel &&
      state.filtersMasterlist.constructionModel.length > 0
        ? state.filtersMasterlist.constructionModel
        : false;
    const systemTypeFilter =
      state.filtersMasterlist.systemType &&
      state.filtersMasterlist.systemType.length > 0
        ? state.filtersMasterlist.systemType
        : false;
    const nominalFlowFilter =
      state.filtersMasterlist.nominalFlow &&
      state.filtersMasterlist.nominalFlow.length > 0
        ? state.filtersMasterlist.nominalFlow
        : false;
    const layoutFilter =
      state.filtersMasterlist.layout &&
      state.filtersMasterlist.layout.length > 0
        ? state.filtersMasterlist.layout
        : false;
    const filterMatrixFilter =
      state.filtersMasterlist.filterMatrix &&
      state.filtersMasterlist.filterMatrix.length > 0
        ? state.filtersMasterlist.filterMatrix
        : false;
    const viledonFilter =
      state.filtersMasterlist.viledon &&
      state.filtersMasterlist.viledon.length > 0
        ? state.filtersMasterlist.viledon
        : false;
    const gpfElementFilter =
      state.filtersMasterlist.gpfElement &&
      state.filtersMasterlist.gpfElement.length > 0
        ? state.filtersMasterlist.gpfElement
        : false;
    const modulesFilter =
      state.filtersMasterlist.modules &&
      state.filtersMasterlist.modules.length > 0
        ? state.filtersMasterlist.modules
        : false;
    const bedDepthFilter =
      state.filtersMasterlist.bedDepth &&
      state.filtersMasterlist.bedDepth.length > 0
        ? state.filtersMasterlist.bedDepth
        : false;
    const residenceTimeFilter =
      state.filtersMasterlist.residenceTime &&
      state.filtersMasterlist.residenceTime.length > 0
        ? state.filtersMasterlist.residenceTime
        : false;
    const faceVelocityFilter =
      state.filtersMasterlist.faceVelocity &&
      state.filtersMasterlist.faceVelocity.length > 0
        ? state.filtersMasterlist.faceVelocity
        : false;

    const constructionModels = list.masterlist.filter(
      ({
        option,
        unitModel,
        systemType,
        nominalFlow,
        layout,
        filterMatrix,
        viledon,
        gpfElement,
        elementsPerStage,
        bedDepthPerElem,
        residenceTimePerBed,
        faceVelocityAtBed,
      }) => {
        if (viledon === 0) {
          viledon = "no";
        } else {
          viledon = "yes";
        }

        if (list.getters.projectUnitMeasure === "Imperial") {
          bedDepthPerElem = Math.round(
            parseInt(bedDepthPerElem) * imperialConstants[imperials.LENGTH]
          );

          nominalFlow = Math.round(
            parseInt(nominalFlow) * imperialConstants[imperials.AIRFLOW]
          );

          faceVelocityAtBed = (
            parseFloat(faceVelocityAtBed) *
            imperialConstants[imperials.VELOCITY]
          ).toFixed(2);
        }

        return (
          (optionFilter
            ? optionFilter.find((item) => item.option === option) && option
            : true) &&
          (constructionModelFilter
            ? constructionModelFilter.find(
                (item) => item.constructionModel === unitModel
              )
            : true) &&
          (systemTypeFilter
            ? systemTypeFilter.find((item) => item.systemType === systemType)
            : true) &&
          (nominalFlowFilter
            ? nominalFlowFilter.find(
                (item) => parseInt(item.nominalFlow) === parseInt(nominalFlow)
              )
            : true) &&
          (layoutFilter
            ? layoutFilter.find((item) => item.layout === layout)
            : true) &&
          (filterMatrixFilter
            ? filterMatrixFilter.find(
                (item) => item.filterMatrix === filterMatrix
              )
            : true) &&
          (viledonFilter
            ? viledonFilter.find((item) => item.viledon === viledon)
            : true) &&
          (gpfElementFilter
            ? gpfElementFilter.find((item) => item.gpfElement === gpfElement)
            : true) &&
          (modulesFilter
            ? modulesFilter.find(
                (item) => parseInt(item.modules) === parseInt(elementsPerStage)
              )
            : true) &&
          (bedDepthFilter
            ? bedDepthFilter.find(
                (item) => parseInt(item.bedDepth) === parseInt(bedDepthPerElem)
              )
            : true) &&
          (residenceTimeFilter
            ? residenceTimeFilter.find(
                (item) =>
                  item.residenceTime ===
                  parseFloat(residenceTimePerBed).toFixed(2)
              )
            : true) &&
          (faceVelocityFilter
            ? faceVelocityFilter.find(
                (item) =>
                  item.faceVelocity === parseFloat(faceVelocityAtBed).toFixed(2)
              )
            : true)
        );
      }
    );
    state.filteredConstructionModels = constructionModels;
  },
};

const actions = {
  getOption(context) {
    const { selectedLayouts } = context.getters;
    const options = [];
    selectedLayouts.forEach((item) => {
      if (item.option !== "") {
        options.push({ id: item.id, option: item.option });
      }
    });
    context.commit("setOption", options);
    return options;
  },

  getConstructionModel(context) {
    const { masterlist } = context.getters;

    const constructionModels = masterlist.map((item) => ({
      id: item.id,
      constructionModel: item.unitModel,
    }));
    context.commit("setConstructionModel", constructionModels);
    return constructionModels;
  },

  getSystemType(context) {
    const { masterlist } = context.getters;

    const systemTypes = masterlist.map((item) => ({
      id: item.id,
      systemType: item.systemType,
    }));
    context.commit("setSystemType", systemTypes);
    return systemTypes;
  },

  getNominalFlow(context) {
    const { masterlist } = context.getters;

    const nominalFlow = masterlist.map((item) => ({
      id: item.id,
      nominalFlow: String(item.nominalFlow),
    }));
    context.commit("setNominalFlow", nominalFlow);
    return nominalFlow;
  },

  getLayout(context) {
    const { masterlist } = context.getters;

    const layout = masterlist.map((item) => ({
      id: item.id,
      layout: item.layout,
    }));
    context.commit("setLayout", layout);
    return layout;
  },

  getFilterMatrix(context) {
    const { masterlist } = context.getters;

    const filterMatrix = masterlist.map((item) => ({
      id: item.id,
      filterMatrix: item.filterMatrix,
    }));
    context.commit("setFilterMatrix", filterMatrix);
    return filterMatrix;
  },

  getViledon(context) {
    const { masterlist } = context.getters;

    const viledon = masterlist.map((item) => ({
      id: item.id,
      viledon: item.viledon ? "yes" : "no",
    }));
    context.commit("setViledon", viledon);
    return viledon;
  },

  getGpfElement(context) {
    const { masterlist } = context.getters;

    const gpfElement = masterlist.map((item) => ({
      id: item.id,
      gpfElement: item.gpfElement,
    }));
    context.commit("setGpfElement", gpfElement);
    return gpfElement;
  },

  getModule(context) {
    const { masterlist } = context.getters;

    const module = masterlist.map((item) => ({
      id: item.id,
      modules: String(item.elementsPerStage),
    }));
    context.commit("setModule", module);
    return module;
  },

  getBedDepth(context) {
    const { masterlist } = context.getters;

    const bedDepth = masterlist.map((item) => ({
      id: item.id,
      bedDepth: String(item.bedDepthPerElem),
    }));
    context.commit("setBedDepth", bedDepth);
    return bedDepth;
  },

  getResidenceTime(context) {
    const { masterlist } = context.getters;

    const residenceTime = masterlist.map((item) => ({
      id: item.id,
      residenceTime: String(item.residenceTimePerBed),
    }));
    context.commit("setResidenceTime", residenceTime);
    return residenceTime;
  },

  getFaceVelocity(context) {
    const { masterlist } = context.getters;

    const faceVelocity = masterlist.map((item) => ({
      id: item.id,
      faceVelocity: String(item.faceVelocityAtBed),
    }));
    context.commit("setFaceVelocity", faceVelocity);
    return faceVelocity;
  },

  setFiltersOptions(context, payload) {
    const { masterlist } = context.getters;

    const list = {
      payload: payload,
      masterlist: masterlist,
      getters: context.getters,
    };

    context.commit("setFiltersOptions", list);
  },

  setHasChanges({ commit }, payload) {
    commit("setHasChanges", payload);
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
