<template>
  <div class="d-flex warning-container">
    <div class="ml-2">{{ text }}</div>
  </div>
</template>
<script>
export default {
  name: "WarningBox",

  props: {
    text: String,
  },
};
</script>
<style lang="scss" scoped>
.warning-container {
  background-color: #de4553;
  padding: 0.4rem;
  color: white;
}

.warning-container .v-icon {
  color: $error-red;
}
</style>
