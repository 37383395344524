import Vue from "vue";
import { store } from "../store/store";

function canAccess(el, binding) {
  const { arg } = binding;

  let hasPermission = false;
  switch (arg) {
    case "admin": {
      hasPermission = true; // store.getters.isAdmin;
      break;
    }
    case "sales": {
      hasPermission = store.getters.isAdmin || store.getters.isSalesManager;
      break;
    }
    case "data": {
      hasPermission = store.getters.isAdmin || store.getters.isDataManager;
      break;
    }
    case "internal": {
      hasPermission = store.getters.isAdmin || store.getters.isInternal;
      break;
    }
  }

  if (!hasPermission) {
    const parent = el.parentNode;
    // const warning = document.createElement("h");
    // warning.innerHTML = "Forbidden";
    // parent.appendChild(warning);

    parent && parent.removeChild(el);
    return false;
  }

  return true;
}

export default {
  update(el, binding) {
    canAccess(el, binding);
  },
  componentUpdated(el, binding) {
    canAccess(el, binding);
  },
  inserted(el, binding) {
    const access = canAccess(el, binding);
    if (!access) {
      Vue.$toast.error("You don't have permission to view this page");
    }
  },
};
