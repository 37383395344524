<template>
  <div class="mt-14">
    <helper-box
      text="Select the equipment's features to be shown in the final report"
    />
    <div class="form-container">
      <div class="min-w-15rem" v-for="(item, i) in features" :key="i">
        <label class="input-label">{{ item.name }}:</label>
        <v-select
          outlined
          item-color="rgba(214, 225, 236, 1)"
          class="fft-dropdown"
          :value="item.value"
          :items="item.options"
          :item-text="item.text"
          @change="handleChangeOption($event, item.mutation)"
        />
      </div>
      <div class="remarks-container">
        <label>Additional remarks</label>
        <v-textarea
          light
          clearable
          no-resize
          outlined
          counter="200"
          rows="5"
          maxlength="200"
          class="remarks"
          :value="remarks"
          @change="handleRemarksChange"
        ></v-textarea>
      </div>
    </div>
  </div>
</template>
<script>
import HelperBox from "../../base/HelperBox.vue";
import { mapGetters, mapMutations } from "vuex";
import { EventBus } from "../../../constants/event-bus";

export default {
  components: { HelperBox },

  computed: {
    ...mapGetters([
      "unitSelectedOption",
      "allInletTypes",
      "allOutletTypes",
      "allPositionsInlet",
      "allPositionsOutlet",
      "allPdMeasurements",
      "selectedInletType",
      "selectedOutletType",
      "selectedPositionInlet",
      "selectedPositionOutlet",
      "selectedPd",
      "remarks",
      "selectedUnitSize",
      "comparisonOptions",
    ]),

    features() {
      return [
        {
          name: "Inlet type",
          value: this.selectedInletType,
          options: this.inletTypeOptions,
          text: "inletType",
          mutation: "changeSelectedInletType",
        },
        {
          name: "Outlet type",
          value: this.selectedOutletType,
          options: this.outletTypeOptions,
          text: "outletType",
          mutation: "changeSelectedOutletType",
        },
        {
          name: "PD measurement for Part. Filters",
          value: this.selectedPd,
          options: this.pdMeasurementOptions,
          text: "pdMeasurement",
          mutation: "changeSelectedPd",
        },
        {
          name: "Inlet position",
          value: this.selectedPositionInlet,
          options: this.inletPositionOptions,
          text: "position",
          mutation: "changeSelectedPositionInlet",
        },
        {
          name: "Outlet position",
          value: this.selectedPositionOutlet,
          options: this.outletPositionOptions,
          text: "position",
          mutation: "changeSelectedPositionOutlet",
        },
      ];
    },

    inletTypeOptions: {
      get() {
        const filteredInletTypes = [];
        this.allInletTypes.forEach((inletType) => {
          if (inletType[this.selectedUnitSize]) {
            filteredInletTypes.push(inletType);
          }
        });

        return filteredInletTypes;
      },
      set(newValue) {
        this.$store.dispatch("setInletTypesList", newValue);
      },
    },

    outletTypeOptions: {
      get() {
        const filteredOutletTypes = [];
        this.allOutletTypes.forEach((outletType) => {
          if (outletType[this.selectedUnitSize]) {
            filteredOutletTypes.push(outletType);
          }
        });

        return filteredOutletTypes;
      },
      set(newValue) {
        this.$store.dispatch("setOutletTypesList", newValue);
      },
    },

    inletPositionOptions: {
      get() {
        const filteredinletPositions = [];
        this.allPositionsInlet.forEach((inletPosition) => {
          if (inletPosition[this.selectedUnitSize]) {
            filteredinletPositions.push(inletPosition);
          }
        });

        return filteredinletPositions;
      },
      set(newValue) {
        this.$store.dispatch("setPositionsInletList", newValue);
      },
    },

    outletPositionOptions: {
      get() {
        const filteredOutletPositions = [];
        this.allPositionsOutlet.forEach((outletPosition) => {
          if (outletPosition[this.selectedUnitSize]) {
            filteredOutletPositions.push(outletPosition);
          }
        });

        return filteredOutletPositions;
      },
      set(newValue) {
        this.$store.dispatch("setPositionsOutletList", newValue);
      },
    },

    pdMeasurementOptions: {
      get() {
        const filteredPdMeasurements = [];
        this.allPdMeasurements.forEach((pdMeasurement) => {
          if (pdMeasurement[this.selectedUnitSize]) {
            filteredPdMeasurements.push(pdMeasurement);
          }
        });

        return filteredPdMeasurements;
      },
      set(newValue) {
        this.$store.dispatch("setPdMeasurementsList", newValue);
      },
    },
  },

  mounted() {
    this.updateSelectedUnitSize();

    EventBus.$on("unitSizeUpdated", (payload) => {
      this.getSelectedSizes(payload);
    });
  },

  methods: {
    ...mapMutations([
      "changeSelectedInletType",
      "changeSelectedOutletType",
      "changeSelectedPd",
      "changeSelectedPositionInlet",
      "changeSelectedPositionOutlet",
    ]),

    handleRemarksChange(newValue) {
      this.$store.dispatch("setRemarks", newValue);
    },

    handleChangeOption(value, mutation) {
      this[mutation](value);
    },

    /**
     * updates selectedUnitSize in store, for updating the options for each select from this component
     */
    updateSelectedUnitSize() {
      this.$nextTick(() => {
        const option =
          this.comparisonOptions[`option${this.unitSelectedOption}`];
        const constructionModel = option?.generalInfo?.constructionModel;
        const selectedSize = constructionModel?.split("-")[0];

        if (selectedSize) {
          this.$store.dispatch("setSelectedUnitSize", selectedSize);
        }
      });
    },

    /**
     * invokes a function for getting a list of features that are only stndard, and another function for setting the default value that will be selected in dropdown
     * @param {string} selectedSize - the selected model of the filter (ex: DPS)
     */
    getSelectedSizes(selectedSize) {
      this.features.forEach((item) => {
        const standardFeature = this.getStandardTypeItem(
          item.options,
          selectedSize
        );

        this.setEquipmentFeatureDefault(
          item.mutation,
          standardFeature,
          item.text
        );
      });
    },

    /**
     * saves in store a value from the first object from an array, if the array is empty sets the null value
     * @param {string} mutation - the name of the mutation we want to call
     * @param {array} array - the array of objects
     * @param {string} value - the name of attribute we want to save from the object
     */
    setEquipmentFeatureDefault(mutation, array, value) {
      if (array.length > 0) {
        this.$store.commit(mutation, array[0][value]);
      } else {
        this.$store.commit(mutation, null);
      }
    },

    getStandardTypeItem(array, selectedSize) {
      return array.filter((item) => item[selectedSize] === "standard");
    },
  },
};
</script>
<style lang="scss" scoped>
.form-container {
  display: grid;
  grid-template-columns: 0.2fr 0.2fr 0.22fr;
  column-gap: 3rem;
  row-gap: 1rem;
  margin-top: 1rem;
  padding: 0 1rem;
}

.remarks-container {
  grid-column: 4;
  grid-row: 1 / 3;
  min-width: 10rem;
}

::v-deep {
  .remarks {
    border-color: #ccc;
    border-radius: 0;

    .v-input__slot {
      background-color: $light-blue;
    }

    .v-text-field__details {
      display: inherit;
      margin-top: 0.5rem;
    }
  }
}
</style>
