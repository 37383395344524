<template>
  <div
    class="mt-10 technical-comparison-tables-container table-pre-info-container-color"
  >
    <div class="table-pre-info-container-color filter-container">
      <span class="info-box">Prefilter</span>
    </div>
    <div>
      <v-data-table
        :items="prefilterModelItems"
        :headers="prefilterModelHeaders"
        class="base-table text-center no-header-table top-bordered-table dropdown-table bold"
        hide-default-footer
        hide-default-header
      >
        <template
          v-for="option in defaultOptions"
          v-slot:[`item.option${option.title}`]="{ item }"
        >
          <v-select
            :key="option.id"
            class="no-outline"
            :items="getFilterModelsOptions(option, 'prefilter')"
            item-text="model"
            v-model="item[`option${option.title}`]"
            @change="
              handleChangePrefilterModel(
                `option${option.title}`,
                item[`option${option.title}`]
              )
            "
            :disabled="!selectedOptions[`layout${option.title}`]"
            clearable
          />
        </template>
      </v-data-table>
      <v-data-table
        :items="prefilterItems"
        :headers="prefilterModelHeaders"
        class="base-table text-center no-header-table"
        hide-default-footer
        hide-default-header
      >
      </v-data-table>
    </div>
    <div
      class="table-pre-info-container-color filter-container filter-container-last-item"
    >
      <span class="info-box">Final filter</span>
    </div>
    <div>
      <v-data-table
        :items="finalFilterModelItems"
        :headers="prefilterModelHeaders"
        class="base-table text-center no-header-table final-filter-table bold"
        hide-default-footer
        hide-default-header
      >
        <template
          v-for="option in defaultOptions"
          v-slot:[`item.option${option.title}`]="{ item }"
        >
          <v-select
            :key="option.id"
            class="no-outline"
            :items="getFilterModelsOptions(option, 'final')"
            item-text="model"
            v-model="item[`option${option.title}`]"
            @change="
              handleChangeFinalFilterModel(
                `option${option.title}`,
                item[`option${option.title}`]
              )
            "
            :disabled="!selectedOptions[`layout${option.title}`]"
            clearable
          />
        </template>
      </v-data-table>
      <v-data-table
        :items="finalfilterItems"
        :headers="prefilterModelHeaders"
        class="base-table text-center no-header-table"
        hide-default-footer
        hide-default-header
      >
      </v-data-table>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { prefilterModelHeaders } from "../../../constants/technicalComparison/headers.js";
import { defaultOptions } from "../../../store/store.js";
export default {
  name: "FilterItems",

  props: {
    selectedOptions: Object,
    selectedPressureUnitMeasure: String,
  },
  data() {
    return {
      prefilterModelHeaders,
      defaultOptions,
      maxFilterAirflow: 2500,
    };
  },

  computed: {
    ...mapGetters(["comparisonOptions", "projectUnitMeasure", "filtersData"]),

    preFilters() {
      return this.filtersData.filter(
        (filter) => filter.filterType === "prefilter"
      );
    },

    finalFilters() {
      return this.filtersData.filter((filter) => filter.filterType === "final");
    },

    prefilterModelItems() {
      return this.constructObjectForFilterModels("prefilter");
    },

    finalFilterModelItems() {
      return this.constructObjectForFilterModels("filter");
    },

    prefilterItems() {
      return this.constructObjectsForFilters("prefilter");
    },

    finalfilterItems() {
      return this.constructObjectsForFilters("filter");
    },
  },

  methods: {
    getFilterModelsOptions(option, type) {
      let optionSystemType;
      if (this.comparisonOptions[`option${option.title}`].generalInfo) {
        optionSystemType =
          this.comparisonOptions[`option${option.title}`].generalInfo
            .systemType;

        const prefilters = this.filtersData.filter(
          (filter) => filter.filterType === type && filter[optionSystemType]
        );

        return prefilters;
      }
    },
    /**
     * builds following objects for table items: qty, filter class and finalPD, for each option (A, B, C, D)
     * @param {string} type - dynamic type: filter/prefilter
     * @returns {array}
     */
    constructObjectsForFilters(type) {
      const qtyFunctionName =
        type === "filter" ? "finalFilterQty" : "prefilterQty";
      const filterClassFunctionName =
        type === "filter" ? "findFinalFilterClass" : "findPrefilterClass";

      const finalPDfunctionName =
        type === "filter"
          ? "calculateFinalFilterFinalPD"
          : "calculatePrefilterFinalPD";

      const qty = {};
      const filterClass = {};
      const finalPD = {};

      qty.options = type === "prefilter" ? "Prefilter Qty" : "Final filter qty";
      filterClass.options = "Filter Class";
      finalPD.options = "Considered Final PD";

      qty.empty1 = "[pc]";
      filterClass.empty1 = "";
      finalPD.empty1 = "[" + this.selectedPressureUnitMeasure + "]";

      this.defaultOptions.forEach((option) => {
        qty[`option${option.title}`] = this[qtyFunctionName](option.title);
        filterClass[`option${option.title}`] = this[filterClassFunctionName](
          `option${option.title}`
        );
        finalPD[`option${option.title}`] = this[finalPDfunctionName](
          `option${option.title}`
        )
          ? this.valueDecimalsTransformations(
              this[finalPDfunctionName](`option${option.title}`),
              this.$imperials.PRESSURE,
              2
            )
          : "-";
      });

      return [qty, filterClass, finalPD];
    },

    /**
     * builds an object for table items using given type to find in store the model for prefilter/filter for each option (A, B, C, D)
     * @param {string} type - dynamic type: filter/prefilter
     * @returns {array}
     */
    constructObjectForFilterModels(type) {
      const headerName =
        type === "filter" ? "Final filter model" : "Prefilter model";
      const model = type === "filter" ? "finalFilterModel" : "prefilterModel";
      const object = {};
      object.options = headerName;
      object.empty1 = "";

      this.defaultOptions.forEach((option) => {
        object[`option${option.title}`] =
          this.comparisonOptions[`option${option.title}`][model];
      });

      return [object];
    },

    /**
     * sets the following values in store: prefilter model, prefilter class, prefilter image url, prefilter cost
     * @param {string} option
     * @param {string} value
     */
    handleChangePrefilterModel(option, value) {
      this.$store.dispatch("setComparisonOptions", {
        option: option,
        comparisonItem: "prefilterModel",
        value: value,
      });

      const filter = this.preFilters.find((filter) => value === filter.model);
      let filterClass = "";

      if (filter && filter.class) {
        filterClass = filter.class;
      }

      this.$store.dispatch("setComparisonOptions", {
        option: option,
        comparisonItem: "prefilterClass",
        value: filterClass,
      });

      this.$store.dispatch("setComparisonOptions", {
        option: option,
        comparisonItem: "prefilterImgUrl",
        value: filter.picture,
      });

      this.$store.dispatch("setFiltersCost", { option, prefilter: true });
    },

    /**
     * sets the following values in store: final filter model, final filter class, final filter image url, final filter cost
     * @param {string} option
     * @param {string} value
     */
    handleChangeFinalFilterModel(option, value) {
      this.$store.dispatch("setComparisonOptions", {
        option: option,
        comparisonItem: "finalFilterModel",
        value: value,
      });

      let filterClass = "";

      const filter = this.finalFilters.find((filter) => value === filter.model);

      if (filter && filter.class) {
        filterClass = filter.class;
      }

      this.$store.dispatch("setComparisonOptions", {
        option: option,
        comparisonItem: "finalFilterClass",
        value: filterClass,
      });

      this.$store.dispatch("setComparisonOptions", {
        option: option,
        comparisonItem: "finalImgUrl",
        value: filter.picture,
      });

      this.$store.dispatch("setFiltersCost", { option, prefilter: false });
    },

    /**
     * find the value of the final filter qty for a given option and sets its value in store
     * @param {string} option
     * @returns {number/string} - the value of the final filter qty
     */
    finalFilterQty(option) {
      if (this.comparisonOptions[`option${option}`].finalFilterModel) {
        if (this.selectedOptions[`layout${option}`]) {
          this.$store.dispatch("setComparisonOptions", {
            option: `option${option}`,
            comparisonItem: "finalFilterQty",
            value:
              this.selectedOptions[`layout${option}`]?.particleFiltersPerStage,
          });

          return this.selectedOptions[`layout${option}`]
            ?.particleFiltersPerStage;
        }
      }

      return "-";
    },

    prefilterQty(option) {
      if (this.comparisonOptions[`option${option}`].prefilterModel) {
        if (this.selectedOptions[`layout${option}`]) {
          this.$store.dispatch("setComparisonOptions", {
            option: `option${option}`,
            comparisonItem: "prefilterQty",
            value:
              this.selectedOptions[`layout${option}`]?.particleFiltersPerStage,
          });

          return this.selectedOptions[`layout${option}`]
            ?.particleFiltersPerStage;
        }
      }

      return "-";
    },

    findFinalFilterClass(option) {
      if (this.comparisonOptions[option].finalFilterClass) {
        return this.comparisonOptions[option].finalFilterClass;
      }
      return "-";
    },

    findPrefilterClass(option) {
      if (this.comparisonOptions[option].prefilterClass) {
        return this.comparisonOptions[option].prefilterClass;
      }
      return "-";
    },

    calculateFinalFilterFinalPD(option) {
      if (Object.keys(this.comparisonOptions[option]).length !== 0) {
        const chosenAirflow =
          this.comparisonOptions[option]?.generalInfo?.chosenAirflow;
        const finalFilterQty = this.comparisonOptions[option]?.finalFilterQty;

        const airflowPerFilter = chosenAirflow / finalFilterQty;

        this.$store.dispatch("setComparisonOptions", {
          option: option,
          comparisonItem: "finalFilterAirflowPerFilter",
          value: airflowPerFilter,
        });

        const selectedFilterModel =
          this.comparisonOptions[option].finalFilterModel;
        const selectedFilterObject = this.finalFilters.find(
          (finalFilter) => finalFilter.model === selectedFilterModel
        );

        let consideredFinalPD;
        let initialPD;
        if (airflowPerFilter < this.maxFilterAirflow) {
          consideredFinalPD = selectedFilterObject?.finalPdLowFlow;
          initialPD = selectedFilterObject?.initialPdLowFlow;
        } else {
          consideredFinalPD = selectedFilterObject?.finalPdHighFlow;
          initialPD = selectedFilterObject?.initialPdHighFlow;
        }

        this.$store.dispatch("setComparisonOptions", {
          option: option,
          comparisonItem: "finalFilterConsideredFinalPD",
          value: consideredFinalPD,
        });

        this.$store.dispatch("setComparisonOptions", {
          option: option,
          comparisonItem: "finalFilterInitialPD",
          value: initialPD,
        });

        return consideredFinalPD;
      }
      return "-";
    },

    calculatePrefilterFinalPD(option) {
      if (Object.keys(this.comparisonOptions[option]).length !== 0) {
        const chosenAirflow =
          this.comparisonOptions[option]?.generalInfo?.chosenAirflow;
        const prefilterQty = this.comparisonOptions[option]?.prefilterQty;

        const airflowPerFilter = chosenAirflow / prefilterQty;

        this.$store.dispatch("setComparisonOptions", {
          option: option,
          comparisonItem: "prefilterAirflowPerFilter",
          value: airflowPerFilter,
        });

        const selectedFilterModel =
          this.comparisonOptions[option].prefilterModel;
        const selectedFilterObject = this.preFilters.find(
          (prefilter) => prefilter.model === selectedFilterModel
        );
        let consideredFinalPD;
        let initialPD;
        if (airflowPerFilter < this.maxFilterAirflow) {
          consideredFinalPD = selectedFilterObject?.finalPdLowFlow;
          initialPD = selectedFilterObject?.initialPdLowFlow;
        } else {
          consideredFinalPD = selectedFilterObject?.finalPdHighFlow;
          initialPD = selectedFilterObject?.initialPdHighFlow;
        }

        this.$store.dispatch("setComparisonOptions", {
          option: option,
          comparisonItem: "prefilterConsideredFinalPD",
          value: consideredFinalPD,
        });

        this.$store.dispatch("setComparisonOptions", {
          option: option,
          comparisonItem: "prefilterInitialPD",
          value: initialPD,
        });

        return consideredFinalPD;
      }
      return "-";
    },

    valueDecimalsTransformations(value, constant, nrOfDecimals) {
      if (this.projectUnitMeasure === "Metric") {
        nrOfDecimals = 0;
      }

      return this.$options.filters.decimalsFormatter(
        this.$options.filters.unitConvertor(value, constant),
        nrOfDecimals
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.error-check {
  color: red;
  height: 100%;
  width: 100%;
  border: 3px solid red;
}

.info-box {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.table-pre-info-container-color span {
  background-color: $page-title-container-grey;
}

.filter-container {
  display: grid;
}

.filter-container-last-item {
  border-top: $base-border;
}
</style>
