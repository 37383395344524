<template>
  <v-data-table
    :items="stagesItems"
    :headers="stagesHeaders"
    class="base-table text-center stages-table"
    hide-default-footer
  />
</template>
<script>
import { mapGetters } from "vuex";
import { stagesHeaders } from "../../../constants/technicalComparison/headers.js";
export default {
  data() {
    return {
      stagesHeaders,
    };
  },

  computed: {
    ...mapGetters([
      "mediaTypes",
      "stages",
      "projectUnitMeasure",
      "unitSelectedOption",
    ]),

    metric() {
      if (this.projectUnitMeasure === "Metric") {
        return true;
      }

      return false;
    },

    weightUnit() {
      if (this.metric) {
        return "kg";
      }
      return "lbs";
    },

    stagesItems() {
      return [
        {
          type: "Media",
          unit: "",
          stage1: this.getStageMedia("stage1"),
          stage2: this.getStageMedia("stage2"),
          stage3: this.getStageMedia("stage3"),
          stage4: this.getStageMedia("stage4"),
        },
        {
          type: "Quantity",
          unit: "[" + this.weightUnit + "]",
          stage1:
            this.getCapacityMedia("stage1") !== "-"
              ? this.calculateRoundWeightValue(this.getCapacityMedia("stage1"))
              : "-",
          stage2:
            this.getCapacityMedia("stage2") !== "-"
              ? this.calculateRoundWeightValue(this.getCapacityMedia("stage2"))
              : "-",
          stage3:
            this.getCapacityMedia("stage3") !== "-"
              ? this.calculateRoundWeightValue(this.getCapacityMedia("stage3"))
              : "-",
          stage4:
            this.getCapacityMedia("stage4") !== "-"
              ? this.calculateRoundWeightValue(this.getCapacityMedia("stage4"))
              : "-",
        },
      ];
    },
  },

  methods: {
    getStageMedia(stage) {
      const mediaTypeId =
        this.stages[stage][`option${this.unitSelectedOption}`]?.mediaType;

      return (
        this.mediaTypes.find((mediaType) => mediaType.id === mediaTypeId)
          ?.mediaTypeName || "-"
      );
    },

    getCapacityMedia(stage) {
      return (
        Math.round(
          this.stages[stage][`option${this.unitSelectedOption}`]?.capacity
        ) || "-"
      );
    },

    calculateRoundWeightValue(item) {
      return this.$options.filters.roundFormatter(
        this.$options.filters.unitConvertor(item, this.$imperials.MASS)
      );
    },
  },
};
</script>
<style lang="scss" scoped></style>
