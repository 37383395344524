<template>
  <div class="technical-comparison-tables-container">
    <div class="table-pre-info-container table-pre-info-container-color">
      <div></div>
      <span class="info-box">Unit Info</span>
      <span class="info-box">Element Info</span>
      <span class="info-box">Bed Info</span>
    </div>
    <v-data-table
      :headers="technicalComparisonHeaders"
      :items="technicalComparisonItems"
      class="base-table text-center"
      hide-default-footer
      hide-default-header
    >
    </v-data-table>
  </div>
</template>
<script>
import { technicalComparisonHeaders } from "../../../constants/technicalComparison/headers.js";
import { defaultOptions } from "../../../store/store.js";
import { units } from "../../../constants/measuringUnits.js";
import { mapGetters } from "vuex";
export default {
  props: {
    selectedOptions: Object,
  },
  data() {
    return {
      technicalComparisonHeaders,
      defaultOptions,
      units,
      airflowUnits: {
        [units.Metric]: {
          text: "m³/h",
        },
        [units.Imperial]: {
          text: "ft³/min",
        },
      },
      velocityUnits: {
        [units.Metric]: {
          text: "m/s",
        },
        [units.Imperial]: {
          text: "ft/min",
        },
      },
    };
  },

  computed: {
    ...mapGetters(["airflow"]),

    selectedAirflowMeasureUnitKey: {
      get() {
        if (this.$store.getters.projectUnitMeasure == "Imperial") {
          return this.airflowUnits[units.Imperial].text;
        }
        return this.airflowUnits[units.Metric].text;
      },
    },

    selectedVelocityMeasureUnitKey: {
      get() {
        if (this.$store.getters.projectUnitMeasure == "Imperial") {
          return this.velocityUnits[units.Imperial].text;
        }
        return this.velocityUnits[units.Metric].text;
      },
    },

    /**
     * builds an array of object for general informations table, using all options (A, B, C, D)
     * @returns {array}
     */
    technicalComparisonItems() {
      const rowHeaders = [
        "Filter matrix ",
        "Chosen airflow",
        "GPF element",
        "Elements p/stage",
        "Chemical stages",
        "Residence time per bed",
        "Face velocity at bed",
      ];
      const infoName = [
        "filterMatrix",
        "airflow",
        "gpfElement",
        "elementsPerStage",
        "bedsUsed",
        "residenceTimePerBed",
        "faceVelocityAtBed",
      ];
      const units = [
        "",
        "[" + this.selectedAirflowMeasureUnitKey + "]",
        "",
        "[pcs]",
        "[No.]",
        "[s]",
        "[" + this.selectedVelocityMeasureUnitKey + "]",
      ];
      const generalInfo = [];

      for (let i = 0; i < rowHeaders.length; i++) {
        const object = {};
        object.options = rowHeaders[i];
        object.empty1 = units[i];

        generalInfo.push(object);
      }

      this.defaultOptions.forEach((option) => {
        generalInfo.forEach((info, index) => {
          info[`option${option.title}`] = "-";

          if (this.selectedOptions[`layout${option.title}`]) {
            if (infoName[index] === "airflow") {
              info[`option${option.title}`] = this.valueRoundedTransformations(
                this.airflow,
                this.$imperials.AIRFLOW
              );
            } else if (infoName[index] === "residenceTimePerBed") {
              info[`option${option.title}`] =
                this.$options.filters.decimalsFormatter(
                  this.selectedOptions[`layout${option.title}`][
                    infoName[index]
                  ],
                  2
                );
            } else if (infoName[index] === "faceVelocityAtBed") {
              info[`option${option.title}`] = this.valueFloatTransformations(
                this.selectedOptions[`layout${option.title}`][infoName[index]],
                this.$imperials.VELOCITY,
                2
              );
            } else {
              info[`option${option.title}`] =
                this.selectedOptions[`layout${option.title}`][infoName[index]];
            }
          }
        });
      });

      return generalInfo;
    },
  },

  methods: {
    valueRoundedTransformations(value, constant) {
      return this.$options.filters.roundFormatter(
        this.$options.filters.unitConvertor(value, constant)
      );
    },
    valueFloatTransformations(value, constant, decimals) {
      return this.$options.filters.decimalsFormatter(
        this.$options.filters.unitConvertor(value, constant),
        decimals
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.info-box {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.table-pre-info-container-color span {
  background-color: $page-title-container-grey;
}

.table-pre-info-container {
  display: grid;
  grid-template-rows: 0fr 1fr 4fr 2fr;

  span:not(:last-child):not(:first-child) {
    border-bottom: $base-border;
  }
}
</style>
