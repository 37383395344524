import { render, staticRenderFns } from "./CommercialComparison.vue?vue&type=template&id=2da8f8da&scoped=true&"
import script from "./CommercialComparison.vue?vue&type=script&lang=js&"
export * from "./CommercialComparison.vue?vue&type=script&lang=js&"
import style0 from "./CommercialComparison.vue?vue&type=style&index=0&id=2da8f8da&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2da8f8da",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VDataTable,VSelect})
