import Vue from "vue";
import VueRouter from "vue-router";
import HomePage from "../views/HomePage.vue";

const ProjectsOverview = () => import("../views/ProjectOverview.vue");
const PriceDashboard = () => import("../views/PriceDashboard.vue");
const CatalogData = () => import("../views/CatalogData.vue");
const UserAdmin = () => import("../views/UserAdminDashboard.vue");
const ProjectsStatistics = () => import("../views/Projects.vue");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomePage,
  },
  {
    path: "/overview/create",
    name: "Create",
    component: ProjectsOverview,
  },

  {
    path: "/:id/overview/edit",
    name: "Edit",
    component: ProjectsOverview,
  },
  {
    path: "/dashboard/price",
    name: "Price Edit",
    component: PriceDashboard,
  },
  {
    path: "/dashboard/catalog-data",
    name: "Catalog Data",
    component: CatalogData,
  },
  {
    path: "/dashboard/user-admin",
    name: "User Admin",
    component: UserAdmin,
  },
  {
    path: "/statistics/projects",
    name: "Projects Statistics",
    component: ProjectsStatistics,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
