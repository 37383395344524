export const commercialComparisonHeaders = [
  {
    text: "Options",
    class: "align bold",
    value: "options",
    sortable: false,
    width: "19%",
  },
  {
    text: "",
    align: "center",
    class: "align bold",
    value: "currency",
    sortable: false,
    width: "5%",
  },
  {
    text: "A",
    align: "center",
    class: "align bold",
    value: "optionA",
    sortable: false,
    width: "19%",
  },
  {
    text: "B",
    align: "center",
    class: "align bold",
    value: "optionB",
    sortable: false,
    width: "19%",
  },
  {
    text: "C",
    align: "center",
    class: "align bold",
    value: "optionC",
    sortable: false,
    width: "19%",
  },
  {
    text: "D",
    align: "center",
    class: "align bold",
    value: "optionD",
    sortable: false,
    width: "19%",
  },
];
