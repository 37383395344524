<template>
  <div class="grid-system-gases mb-2">
    <span class="gas-name"> {{ name }} </span>
    <div>
      <v-select
        outlined
        item-color="rgba(214, 225, 236, 1)"
        class="fft-dropdown"
        v-model="selectedGases[gasIndex]"
        :items="gasesOptions"
        item-text="gasName"
        item-value="id"
        @change="handleGasValueChange($event, gasIndex)"
        clearable
      ></v-select>
    </div>
    <div class="ml-4">
      <text-input
        :value="concentration[gasIndex]"
        @change="handleInletConcChange"
      />
    </div>
    <div class="mr-4">
      <v-select
        outlined
        item-color="rgba(214, 225, 236, 1)"
        class="fft-dropdown"
        v-model="selectedUnitOption[gasIndex]"
        item-text="unit"
        :items="unitOptions"
        @change="handleGasUnitMeasureChange"
      ></v-select>
    </div>
    <div class="mr-4 container-border d-flex justify-center">
      <div class="d-flex align-center">
        {{
          totalGasYear[`gas${gasIndex}`]
            | unitConvertor($imperials.MASS)
            | decimalsFormatter(3)
        }}
      </div>
    </div>
    <div class="container-border d-flex justify-center">
      <div class="d-flex align-center">
        {{ selectedGasConcentrations[`gas${gasIndex}`]["mg/m3"] }}
      </div>
    </div>
    <div class="container-border d-flex justify-center">
      <div class="d-flex align-center">
        {{ selectedGasConcentrations[`gas${gasIndex}`]["ppm"] }}
      </div>
    </div>
    <div class="container-border d-flex justify-center">
      <div class="d-flex align-center">
        {{ selectedGasConcentrations[`gas${gasIndex}`]["ppb"] }}
      </div>
    </div>
    <div
      class="container-border d-flex justify-center"
      :class="{
        G1: gasesGClass[`gas${gasIndex}`] === 'G1',
        G2: gasesGClass[`gas${gasIndex}`] === 'G2',
        G3: gasesGClass[`gas${gasIndex}`] === 'G3',
        GX: gasesGClass[`gas${gasIndex}`] === 'GX',
        'GX-plus': gasesGClass[`gas${gasIndex}`] === 'GX+',
      }"
    >
      <div class="d-flex align-center">
        {{ gasesGClass[`gas${gasIndex}`] }}
      </div>
    </div>
  </div>
</template>
<script>
import { EventBus } from "../../constants/event-bus.js";
import TextInput from "./TextInput.vue";

export default {
  name: "GasRow",

  components: { TextInput },

  props: {
    name: String,
    gasIndex: Number,
  },

  computed: {
    isDisabled() {
      return true;
    },
    gasesOptions: {
      get() {
        return this.$store.getters.gases;
      },
    },

    selectedGases: {
      get() {
        return Object.keys(this.$store.getters.selectedGases).map(
          (gas) => this.$store.getters.selectedGases[gas].gasData.id
        );
      },
    },

    unitOptions: {
      get() {
        return this.$store.getters.gasUnits;
      },
    },

    selectedUnitOption: {
      get() {
        return Object.keys(this.$store.getters.selectedGases).map(
          (gas) => this.$store.getters.selectedGases[gas].unit
        );
      },
    },

    selectedGasConcentrations: {
      get() {
        return this.$store.getters.selectedGasConcentrations;
      },
    },

    totalGasYear: {
      get() {
        return this.$store.getters.totalGasYear;
      },
    },

    concentration: {
      get() {
        return Object.keys(this.$store.getters.selectedGases).map(
          (gas) => this.$store.getters.selectedGases[gas].inletConcentration
        );
      },

      set(newValue) {
        this.$store.dispatch("setConcentration", {
          gasIndex: this.gasIndex,
          value: newValue,
        });
      },
    },

    gasesGClass: {
      get() {
        return this.$store.getters.gasesGClass;
      },
    },
  },

  mounted: function () {
    this.$nextTick(function () {
      this.updateConcentrationDetails();
    });

    EventBus.$on("refreshCalcs", () => {
      this.updateData();
      this.updateConcentrationDetails();
    });
  },

  methods: {
    handleInletConcChange(newInletConcValue) {
      this.$store.dispatch("setConcentration", {
        gasIndex: this.gasIndex,
        value: newInletConcValue,
      });

      this.updateConcentrationDetails();
    },

    handleGasValueChange(selectedKey, gasIndex) {
      if (selectedKey) {
        this.$store.dispatch("setSelectedGas", {
          gasIndex: this.gasIndex,
          value: selectedKey,
        });

        this.$store.dispatch("addGClassThreshold", {
          gasId: selectedKey,
          gasIndex: this.gasIndex,
        });

        this.$store.dispatch("addRemovalCapacity", {
          gasId: selectedKey,
          gasIndex: this.gasIndex,
        });

        this.updateConcentrationDetails();
      } else {
        this.$store.dispatch("resetGas", gasIndex);
        this.$store.dispatch("resetRemovalCapacity", gasIndex);
        this.$store.dispatch("resetWeightOfMedia", gasIndex);
        this.$store.dispatch("resetGClassThresholds", gasIndex);
        this.updateConcentrationDetails();
      }
    },

    handleGasUnitMeasureChange(selectedUnitMeasure) {
      if (selectedUnitMeasure) {
        this.$store.dispatch("setSelectedGasUnit", {
          gasIndex: this.gasIndex,
          value: selectedUnitMeasure,
        });

        this.updateConcentrationDetails();
      }
    },

    updateConcentrationDetails() {
      const selectedGas =
        this.$store.getters.selectedGases[`gas${this.gasIndex}`];
      const selectedConcentration = this.concentration[this.gasIndex];
      const unitMeasure = this.selectedUnitOption[this.gasIndex];

      if (
        Object.keys(selectedGas.gasData).length > 0 &&
        selectedConcentration !== "" &&
        unitMeasure !== ""
      ) {
        this.$store.dispatch("setConcentrations", {
          gasIndex: this.gasIndex,
          gas: selectedGas,
          inlet: selectedGas.inletConcentration,
          unitMeasure: selectedGas.unit,
        });

        this.$store.dispatch("setTotalGasYear", {
          gasIndex: this.gasIndex,
        });

        this.$store.dispatch("setGClass", {
          gasIndex: this.gasIndex,
          gas: selectedGas,
        });

        this.$store.dispatch("addWeightOfMedia", {
          gas: selectedGas,
          gasIndex: this.gasIndex,
        });
      } else {
        this.$store.dispatch("resetGasValues", { gasIndex: this.gasIndex });
      }
    },

    updateData() {
      Object.values(this.$store.getters.selectedGases).forEach((gas) => {
        if (gas.index) {
          this.$store.dispatch("setSelectedGas", {
            gasIndex: gas.index,
            value: gas.gasData.id,
          });

          this.$store.dispatch("addGClassThreshold", {
            gasId: gas.gasData.id,
            gasIndex: gas.index,
          });

          this.$store.dispatch("addRemovalCapacity", {
            gasId: gas.gasData.id,
            gasIndex: gas.index,
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.gas-name:after {
  content: ":";
}

.container-border {
  border: 1px solid $border-grey;
  padding: 0 10px;
  height: 36px;
}
</style>
