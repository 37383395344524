<template>
  <v-footer class="footer" width="auto" tile padless>
    <v-row class="flex" id="footer-content">
      <v-col class="footer-navigation pl-10 pr-10">
        <p class="title ma-0">Internal links</p>
        <ul class="pl-0 mt-0">
          <li class="links pr-8">
            <a
              href="https://sharepoint.freudenberg-filter.com/sites/FILTEREU/gpf/SitePages/Home.aspx"
              target="_blank"
              >GPF sharepoint site</a
            >
          </li>
          <li class="links pr-8">
            <a
              href="https://portal.freudenberg-filter.com/en/tools/datasheets/Pages/default.aspx"
              target="_blank"
              >Data sheets</a
            >
          </li>
          <li class="links pr-8">
            <a
              href="https://portal.freudenberg-filter.com/en/tools/pub/Pages/default.aspx"
              target="_blank"
              >Publications</a
            >
          </li>
        </ul>
      </v-col>
      <v-col></v-col>
      <v-col class="footer-navigation pl-10">
        <p class="title ma-0">External links</p>
        <ul class="pl-0">
          <li class="links pr-8">
            <a
              href="https://products.freudenberg-filter.com/en/?_ga=2.127337614.2028259027.1619445883-2130961962.1566898140"
              target="_blank"
              >Viledon e-catalog</a
            >
          </li>
          <li class="links">
            <a
              href="https://www.freudenberg-filter.com/en/world-of-industrial/gas-phase-filtration/"
              target="_blank"
              >FFT website</a
            >
          </li>
        </ul>
      </v-col>

      <v-spacer></v-spacer>

      <v-col sm="4" class="footer-copyright text-right pl-10 pr-6">
        &copy; Freudenberg Filtration Technologies GmbH & Co. KG
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: "TheFooter",
};
</script>

<style lang="scss" scoped>
#app .footer {
  background-color: $basic-blue;
  position: sticky;
  bottom: auto !important;
}

#footer-content {
  color: $white;
  margin: 0;
}

.footer-navigation {
  padding-bottom: 20px;
  .links a {
    color: $white;
    align-self: flex-end;
  }
}

.pl-0 {
  display: -webkit-inline-box;
}

.footer-copyright {
  padding-bottom: 20px;
  align-self: flex-end;
}
</style>
<script>
export default {
  name: "Footer",
};
</script>
