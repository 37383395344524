<template>
  <div>
    <helper-box :text="helpersText.operatingTime" class="mb-7" />
    <div class="d-flex flex-wrap pb-14 operating-container">
      <div class="column-flex right-separator">
        <label class="input-label">Airflow:</label>
        <div class="d-flex align-center" style="height: 35px">
          <span class="mr-2">
            {{ airflowValue }}
          </span>
          <div>
            <span>
              {{ selectedAirflowMeasureUnitKey.text }}
            </span>
          </div>
        </div>
      </div>
      <div class="column-flex right-separator">
        <span> Operating time: </span>
        <div class="d-flex align-center">
          <text-input
            width="5rem"
            :maxlength="2"
            :value="hours"
            @change="handleTimeInputChange($event, 'hours', 24)"
          />
          <span class="ml-2 mr-6"> hrs/day </span>
          <text-input
            width="5rem"
            :maxlength="1"
            :value="days"
            @change="handleTimeInputChange($event, 'days', 7)"
          />
          <span class="ml-2 mr-6"> days/week </span>
          <text-input
            width="5rem"
            :maxlength="2"
            :value="weeks"
            @change="handleTimeInputChange($event, 'weeks', 52)"
          />
          <span class="ml-2 mr-6"> weeks/year </span>
        </div>
      </div>
      <div class="column-flex">
        <span> Operating time/year: </span>
        <div class="d-flex align-center" style="height: 35px">
          <span> {{ operatingTimeYear }} hrs </span>
        </div>
      </div>
      <custom-button
        title="Refresh"
        type="secondary"
        class="mb-0 ml-auto mr-0 mt-auto"
        @click="handleRefreshCalcs()"
      ></custom-button>
    </div>
    <div class="pt-14 operating-container">
      <div class="mt-2">
        <helper-box :text="helpersText.gases" />
        <div class="d-flex justify-end mt-2">
          <custom-link
            class="bold"
            text="Show G-class thresholds levels"
            @click="handleShowGClassPress"
          />
        </div>
        <div class="mt-7 pb-14">
          <div class="grid-system-gases">
            <span> </span>
            <span> Select gas </span>
            <span class="ml-7"> Inlet conc. </span>
            <span> Unit </span>
            <span> Total gas/year ({{ selectedWeightUnitMeasure }}) </span>
            <span> mg/m³ </span>
            <span> ppm </span>
            <span> ppb </span>
            <span> G-Class </span>
          </div>
          <div>
            <gas-row name="Gas 1" :gasIndex="0" />
            <gas-row name="Gas 2" :gasIndex="1" />
            <gas-row name="Gas 3" :gasIndex="2" />
            <gas-row name="Gas 4" :gasIndex="3" />
          </div>
        </div>
      </div>
    </div>
    <div
      class="pt-14"
      :class="{
        'calculations-container': selectedTypeOfFit === 'bestFits',
      }"
    >
      <div class="d-flex flex-column">
        <span class="custom-title bold">
          Media options and quantity calculation
        </span>
        <helper-box :text="helpersText.mediaTables" />

        <div class="py-4 d-flex justify-end">
          <custom-link
            v-if="selectedTypeOfFit === 'bestFits'"
            text="Show all fits"
            @click="handleTableTypeClick('allFits')"
            class="bold"
          />
          <!-- <custom-link
            v-else
            text="Show best fits"
            @click="handleTableTypeClick('bestFits')"
            class="bold"
          /> -->
        </div>
      </div>
      <component :is="fits[selectedTypeOfFit]"></component>
    </div>
    <template>
      <custom-modal
        v-model="showGClassDialog"
        title="G-class thresholds levels per ISA 71.04-2013 (in ppb)"
        :buttons="dialogButtons"
        width="45rem"
      >
        <div class="mx-6 mt-12 mb-10">
          <v-data-table
            :headers="gClassHeaders"
            :items="gClassThresholds"
            class="base-table text-center"
            hide-default-footer
          />
        </div>
      </custom-modal>
    </template>
  </div>
</template>
<script>
import BasicTable from "../base/BasicTable.vue";
import CustomLink from "../base/CustomLink.vue";
import CustomModal from "../base/CustomModal.vue";
import GasRow from "../base/GasRow.vue";
import HelperBox from "../base/HelperBox.vue";
import TextInput from "../base/TextInput.vue";
import CustomButton from "../base/CustomButton.vue";

import AllFitsVue from "./AllFits.vue";
import BestFitsVue from "./BestFits.vue";

import { units } from "../../constants/measuringUnits.js";
import { EventBus } from "../../constants/event-bus.js";
import { gClassHeaders } from "../../constants/gasAndMedia/gClassHeaders.js";

import { mapGetters } from "vuex";

export default {
  name: "GasAndMediaSelection",

  components: {
    TextInput,
    HelperBox,
    CustomLink,
    CustomModal,
    GasRow,
    BasicTable,
    CustomButton,
  },

  data() {
    return {
      airflowUnits: {
        [units.Metric]: {
          text: "m³/h",
        },
        [units.Imperial]: {
          text: "ft³/min",
        },
      },
      weightUnits: {
        [units.Metric]: {
          text: "kg",
        },
        [units.Imperial]: {
          text: "lbs",
        },
      },
      showGClassDialog: false,
      dialogButtons: {
        cancel: true,
        confirm: false,
      },
      selectedTypeOfFit: "allFits",
      fits: {
        bestFits: BestFitsVue,
        allFits: AllFitsVue,
      },
      gClassHeaders,
      helpersText: {
        operatingTime:
          "Enter the working hours, days and weeks expected for the system to be running per year",
        gases:
          "Select the gases present in the environment and enter their corresponding concentrations",
        mediaTables:
          "Check which medias are suitable for the selected gases according their removal capacity. Media selection per stage will be required in further steps",
      },
    };
  },

  computed: {
    ...mapGetters([
      "operatingTimeYear",
      "gClassThresholds",
      "airflow",
      "airflowImperial",
      "projectUnitMeasure",
    ]),

    airflowValue() {
      return this.projectUnitMeasure === "Metric"
        ? this.airflow
        : this.airflowImperial;
    },

    hours: {
      get() {
        return this.$store.getters.opHours;
      },

      set(newValue) {
        this.$store.dispatch("setOpHours", newValue);
      },
    },

    days: {
      get() {
        return this.$store.getters.opDays;
      },
      set(newValue) {
        this.$store.dispatch("setOpDays", newValue);
      },
    },

    weeks: {
      get() {
        return this.$store.getters.opWeeks;
      },

      set(newValue) {
        this.$store.dispatch("setOpWeeks", newValue);
      },
    },

    selectedAirflowMeasureUnitKey: {
      get() {
        if (this.projectUnitMeasure == "Imperial") {
          return this.airflowUnits[units.Imperial];
        }
        return this.airflowUnits[units.Metric];
      },
    },
    selectedWeightUnitMeasure: {
      get() {
        if (this.projectUnitMeasure == "Imperial") {
          return this.weightUnits[units.Imperial].text;
        }
        return this.weightUnits[units.Metric].text;
      },
    },
  },

  mounted() {
    this.fetchInitialData();
  },

  methods: {
    fetchInitialData() {
      this.$store.dispatch("updateOperatingTime");
      this.$store.dispatch("fetchCapacityData");
    },

    handleShowGClassPress() {
      this.showGClassDialog = true;
    },

    handleTableTypeClick(type) {
      this.selectedTypeOfFit = type;
    },

    handleTimeInputChange(newValue, field, limit) {
      if (newValue > limit || newValue < 0 || isNaN(newValue)) {
        this[field] = limit;
      } else {
        this[field] = newValue;
      }

      this.handleRefreshCalcs();
    },

    handleRefreshCalcs() {
      this.$store.dispatch("updateOperatingTime");
      this.$store.dispatch("recalculateTotalGasYear");

      EventBus.$emit("refreshCalcs");
    },
  },
};
</script>
<style lang="scss" scoped>
.operating-container {
  border-bottom: 1px solid $basic-blue;
}

.calculations-container {
  width: fit-content;
}

.right-separator {
  margin-right: 6rem;
}

.custom-title {
  font-size: 1.2rem;
}

::v-deep .fft-dropdown .v-input__control {
  width: 7rem !important;
}
</style>
