import Vue from "vue";
import { imperials } from "../constants/imperialConstants";
import { metrics } from "../constants/metricConstants";
import storeProjectData from "../store/modules/projectData.js";

export const imperialConstants = {
  [imperials.AIRFLOW]: 0.588578,
  [imperials.PRESSURE]: 0.004,
  [imperials.MASS]: 2.204,
  [imperials.LENGTH]: 0.039,
  [imperials.VELOCITY]: 196.85,
};

export const metricConstants = {
  [metrics.AIRFLOW]: 1.699,
};

Vue.filter("unitConvertor", (value, constant) => {
  if (!isNaN(value)) {
    if (storeProjectData.state.project.projectUnitMeasure === "Imperial") {
      return value * imperialConstants[constant];
    }
    return value;
  }
  return "-";
});

Vue.filter("decimalsFormatter", (value, number) => {
  if (!isNaN(value)) {
    return parseFloat(value).toFixed(number);
  }

  return "-";
});

Vue.filter("roundFormatter", (value) => {
  if (!isNaN(value)) {
    return Math.round(value);
  }

  return "-";
});
