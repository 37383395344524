export const allFitsHeaders = [
  {
    text: "Gases",
    class: "align white-column font-weight-bold",
    value: "gasName",
    sortable: false,
  },
  {
    text: "CCP104",
    class: "align white-column font-weight-bold",
    value: "CCP104",
    sortable: false,
    align: "center",
  },
  {
    text: "CCP108",
    class: "align white-column font-weight-bold",
    value: "CCP108",
    sortable: false,
    align: "center",
  },
  {
    text: "CCP210",
    class: "align white-column font-weight-bold",
    value: "CCP210",
    sortable: false,
    align: "center",
  },
  {
    text: "CCP310",
    class: "align white-column font-weight-bold",
    value: "CCP310",
    sortable: false,
    align: "center",
  },
  {
    text: "CCP320",
    class: "align white-column font-weight-bold",
    value: "CCP320",
    sortable: false,
    align: "center",
  },
  {
    text: "CCP510",
    class: "align white-column font-weight-bold",
    value: "CCP510",
    sortable: false,
    align: "center",
  },
  {
    text: "CCP610",
    class: "align white-column font-weight-bold",
    value: "CCP610",
    sortable: false,
    align: "center",
  },
  {
    text: "CCP630",
    class: "align white-column font-weight-bold",
    value: "CCP630",
    sortable: false,
    align: "center",
  },
  {
    text: "CCP810",
    class: "align white-column font-weight-bold",
    value: "CCP810",
    sortable: false,
    align: "center",
  },
  {
    text: "CCP830",
    class: "align white-column font-weight-bold",
    value: "CCP830",
    sortable: false,
    align: "center",
  },
  {
    text: "CCP840",
    class: "align white-column font-weight-bold",
    value: "CCP840",
    sortable: false,
    align: "center",
  },
  {
    text: "CCP840plus",
    class: "align white-column font-weight-bold",
    value: "CCP840plus",
    sortable: false,
    align: "center",
  },
  {
    text: "CCP860",
    class: "align white-column font-weight-bold",
    value: "CCP860",
    sortable: false,
    align: "center",
  },
  {
    text: "CCP903",
    class: "align white-column font-weight-bold",
    value: "CCP903",
    sortable: false,
    align: "center",
  },
  {
    text: "WVIS",
    class: "align white-column font-weight-bold",
    value: "WVIS",
    sortable: false,
    align: "center",
  },
  {
    text: "HM",
    class: "align white-column font-weight-bold",
    value: "HM",
    sortable: false,
    align: "center",
  },
  {
    text: "CCP_Dummy",
    class: "align white-column font-weight-bold",
    value: "CCP_Dummy",
    sortable: false,
    align: "center",
  },
];
