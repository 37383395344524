<template>
  <div>
    <div class="d-flex align-center justify-space-between mb-8">
      <h6 class="semibold large-font-size mr-3">Commercial Comparison</h6>
      <custom-button
        title="Refresh"
        type="secondary"
        @click="handleRefresh()"
      ></custom-button>
    </div>
    <div class="mb-15">
      <v-data-table
        :headers="commercialComparisonHeaders"
        :items="commercialComparisonItems"
        class="base-table text-center"
        hide-default-footer
      />
    </div>
    <warning-box
      :text="'Important: These are only Reference Sales Prices and should only be used as indication to help finding the best solution. These should not be used for any kind of cost calculation or quotation.'"
      class="mb-7"
    />
    <helper-box
      text="Enter the expected lifetime in months for the desired options, according
      to the Breakthrough calculations from Technical Comparison (previous step)"
      class="mb-7"
    />
    <v-data-table
      :headers="commercialComparisonHeaders"
      :items="filtersAndPelletsItems"
      class="base-table text-center second-table"
      hide-default-footer
      hide-default-header
    />
    <v-data-table
      :headers="commercialComparisonHeaders"
      :items="changeoutEveryYearItems"
      class="base-table text-center annualized-calc-table"
      hide-default-footer
      hide-default-header
    >
      <template v-slot:[`item.options`]="{ item }">{{ item.options }}</template>
      <template
        v-for="(optItem, i) in options"
        v-slot:[`item.option${optItem}`]="{ item }"
      >
        <text-input
          :key="i"
          class="number-input"
          :value="item[`option${optItem}`]"
          :disabled="!commercialComparisonCalcs[`option${optItem}`]"
          @change="handleAnnualizedCalcChange($event, optItem)"
        />
      </template>
    </v-data-table>
    <v-data-table
      :headers="commercialComparisonHeaders"
      :items="annualizesCalcItems"
      class="base-table text-center changeout-table mb-7"
      hide-default-footer
      hide-default-header
    />
    <div class="d-flex">
      <div class="d-flex flex-column mr-1">
        <label class="input-label">Currency:</label>
        <v-select
          outlined
          background-color="rgba(214, 225, 236, 1)"
          item-color="rgba(214, 225, 236, 1)"
          class="fft-dropdown"
          :value="selectedCurrencyText"
          @change="updateCurrency"
          :items="currencies"
          item-text="currency"
        ></v-select>
      </div>
      <text-input
        v-if="selectedCurrency !== currencyEnum.EUR"
        :value="exchangeRate"
        label="Exchange rate"
        @change="updateExchangeRate"
      />
    </div>
  </div>
</template>
<script>
import TextInput from "../base/TextInput.vue";
import HelperBox from "../base/HelperBox.vue";
import WarningBox from "../base/WarningBox.vue";
import { currencyEnum } from "../../constants/currencies";
import CustomButton from "../base/CustomButton.vue";
import { commercialComparisonHeaders } from "../../constants/commercialComparison/headers.js";
import { mapGetters } from "vuex";

export default {
  name: "CommercialComparison",
  components: { TextInput, HelperBox, WarningBox, CustomButton },
  data() {
    return {
      currencyEnum,
      commercialComparisonHeaders,
      currencySymbols: {
        [currencyEnum.EUR]: { symbol: "€", text: "EUR" },
        [currencyEnum.USD]: { symbol: "$", text: "USD" },
      },
      options: ["A", "B", "C", "D"],
    };
  },

  computed: {
    ...mapGetters([
      "exchangeRate",
      "currencies",
      "selectedCurrency",
      "stages",
      "comparisonOptions",
    ]),

    ...mapGetters({
      commercialComparisonCalcs: "commercialComparison",
    }),

    selectedCurrencySign() {
      return this.currencySymbols[this.selectedCurrency].symbol;
    },

    selectedCurrencyText() {
      return this.currencySymbols[this.selectedCurrency].text;
    },

    selectedOptions: {
      get() {
        const storeOptions = this.$store.getters.selectedLayouts;

        const layoutA =
          storeOptions.find((layout) => layout.option === "A") || null;

        const layoutB =
          storeOptions.find((layout) => layout.option === "B") || null;
        const layoutC =
          storeOptions.find((layout) => layout.option === "C") || null;

        const layoutD =
          storeOptions.find((layout) => layout.option === "D") || null;

        return {
          layoutA,
          layoutB,
          layoutC,
          layoutD,
        };
      },
    },

    commercialComparisonItems() {
      const comparisonA = this.commercialComparisonCalcs.optionA;
      const comparisonB = this.commercialComparisonCalcs.optionB;
      const comparisonC = this.commercialComparisonCalcs.optionC;
      const comparisonD = this.commercialComparisonCalcs.optionD;

      const arr = [
        {
          options: "Manufacture Model",
          currency: "",
          optionA: comparisonA.code,
          optionB: comparisonB.code,
          optionC: comparisonC.code,
          optionD: comparisonD.code,
        },
        {
          options: "Unit cost",
          currency: this.selectedCurrencySign,
          optionA:
            comparisonA.isSelected === true
              ? this.$i18n.n(comparisonA.unitCost)
              : "-",
          optionB:
            comparisonB.isSelected === true
              ? this.$i18n.n(comparisonB.unitCost)
              : "-",
          optionC:
            comparisonC.isSelected === true
              ? this.$i18n.n(comparisonC.unitCost)
              : "-",
          optionD:
            comparisonD.isSelected === true
              ? this.$i18n.n(comparisonD.unitCost)
              : "-",
        },
        {
          options: "Elements cost",
          currency: this.selectedCurrencySign,
          optionA:
            comparisonA.isSelected === true
              ? this.$i18n.n(Math.round(comparisonA.elementsCost))
              : "-",
          optionB:
            comparisonB.isSelected === true
              ? this.$i18n.n(Math.round(comparisonB.elementsCost))
              : "-",
          optionC:
            comparisonC.isSelected === true
              ? this.$i18n.n(Math.round(comparisonC.elementsCost))
              : "-",
          optionD:
            comparisonD.isSelected === true
              ? this.$i18n.n(Math.round(comparisonD.elementsCost))
              : "-",
        },
        {
          options: "Media cost - Stage 1",
          currency: this.selectedCurrencySign,
          optionA:
            comparisonA.isSelected === true
              ? this.$i18n.n(Math.round(comparisonA.mediaCostStage1))
              : "-",
          optionB:
            comparisonB.isSelected === true
              ? this.$i18n.n(Math.round(comparisonB.mediaCostStage1))
              : "-",
          optionC:
            comparisonC.isSelected === true
              ? this.$i18n.n(Math.round(comparisonC.mediaCostStage1))
              : "-",
          optionD:
            comparisonD.isSelected === true
              ? this.$i18n.n(Math.round(comparisonD.mediaCostStage1))
              : "-",
        },
        {
          options: "Media cost - Stage 2",
          currency: this.selectedCurrencySign,
          optionA:
            comparisonA.isSelected === true
              ? this.$i18n.n(Math.round(comparisonA.mediaCostStage2))
              : "-",
          optionB:
            comparisonB.isSelected === true
              ? this.$i18n.n(Math.round(comparisonB.mediaCostStage2))
              : "-",
          optionC:
            comparisonC.isSelected === true
              ? this.$i18n.n(Math.round(comparisonC.mediaCostStage2))
              : "-",
          optionD:
            comparisonD.isSelected === true
              ? this.$i18n.n(Math.round(comparisonD.mediaCostStage2))
              : "-",
        },
        {
          options: "Media cost - Stage 3",
          currency: this.selectedCurrencySign,
          optionA:
            comparisonA.isSelected === true
              ? this.$i18n.n(Math.round(comparisonA.mediaCostStage3))
              : "-",
          optionB:
            comparisonB.isSelected === true
              ? this.$i18n.n(Math.round(comparisonB.mediaCostStage3))
              : "-",
          optionC:
            comparisonC.isSelected === true
              ? this.$i18n.n(Math.round(comparisonC.mediaCostStage3))
              : "-",
          optionD:
            comparisonD.isSelected === true
              ? this.$i18n.n(Math.round(comparisonD.mediaCostStage3))
              : "-",
        },
        {
          options: "Media cost - Stage 4",
          currency: this.selectedCurrencySign,
          optionA:
            comparisonA.isSelected === true
              ? this.$i18n.n(Math.round(comparisonA.mediaCostStage4))
              : "-",
          optionB:
            comparisonB.isSelected === true
              ? this.$i18n.n(Math.round(comparisonB.mediaCostStage4))
              : "-",
          optionC:
            comparisonC.isSelected === true
              ? this.$i18n.n(Math.round(comparisonC.mediaCostStage4))
              : "-",
          optionD:
            comparisonD.isSelected === true
              ? this.$i18n.n(Math.round(comparisonD.mediaCostStage4))
              : "-",
        },
        {
          options: "Pre filters cost",
          currency: this.selectedCurrencySign,
          optionA:
            comparisonA.isSelected === true
              ? this.$i18n.n(comparisonA.preFiltersCost)
              : "-",
          optionB:
            comparisonB.isSelected === true
              ? this.$i18n.n(comparisonB.preFiltersCost)
              : "-",
          optionC:
            comparisonC.isSelected === true
              ? this.$i18n.n(comparisonC.preFiltersCost)
              : "-",
          optionD:
            comparisonD.isSelected === true
              ? this.$i18n.n(comparisonD.preFiltersCost)
              : "-",
        },
        {
          options: "Final filters cost",
          currency: this.selectedCurrencySign,
          optionA:
            comparisonA.isSelected === true
              ? this.$i18n.n(comparisonA.finalFiltersCost)
              : "-",
          optionB:
            comparisonB.isSelected === true
              ? this.$i18n.n(comparisonB.finalFiltersCost)
              : "-",
          optionC:
            comparisonC.isSelected === true
              ? this.$i18n.n(comparisonC.finalFiltersCost)
              : "-",
          optionD:
            comparisonD.isSelected === true
              ? this.$i18n.n(comparisonD.finalFiltersCost)
              : "-",
        },
        {
          options: "TOTAL INVESTMENT COST",
          currency: this.selectedCurrencySign,
          optionA:
            comparisonA.isSelected === true
              ? !isNaN(comparisonA.totalInvestmentCost)
                ? this.$i18n.n(Math.round(comparisonA.totalInvestmentCost))
                : "-"
              : "-",
          optionB:
            comparisonB.isSelected === true
              ? !isNaN(comparisonB.totalInvestmentCost)
                ? this.$i18n.n(Math.round(comparisonB.totalInvestmentCost))
                : "-"
              : "-",
          optionC:
            comparisonC.isSelected === true
              ? !isNaN(comparisonC.totalInvestmentCost)
                ? this.$i18n.n(Math.round(comparisonC.totalInvestmentCost))
                : "-"
              : "-",
          optionD:
            comparisonD.isSelected === true
              ? !isNaN(comparisonD.totalInvestmentCost)
                ? this.$i18n.n(Math.round(comparisonD.totalInvestmentCost))
                : "-"
              : "-",
        },
      ];
      return arr;
    },

    filtersAndPelletsItems() {
      const comparisonA = this.commercialComparisonCalcs.optionA;
      const comparisonB = this.commercialComparisonCalcs.optionB;
      const comparisonC = this.commercialComparisonCalcs.optionC;
      const comparisonD = this.commercialComparisonCalcs.optionD;

      const arr = [
        {
          options: "Filter & Pellets cost",
          currency: this.selectedCurrencySign,
          optionA:
            comparisonA.isSelected === true
              ? !isNaN(comparisonA.filtersAndPelletsCost)
                ? this.$i18n.n(Math.round(comparisonA.filtersAndPelletsCost))
                : "-"
              : "-",
          optionB:
            comparisonB.isSelected === true
              ? !isNaN(comparisonB.filtersAndPelletsCost)
                ? this.$i18n.n(Math.round(comparisonB.filtersAndPelletsCost))
                : "-"
              : "-",
          optionC:
            comparisonC.isSelected === true
              ? !isNaN(comparisonC.filtersAndPelletsCost)
                ? this.$i18n.n(Math.round(comparisonC.filtersAndPelletsCost))
                : "-"
              : "-",
          optionD:
            comparisonD.isSelected === true
              ? !isNaN(comparisonD.filtersAndPelletsCost)
                ? this.$i18n.n(Math.round(comparisonD.filtersAndPelletsCost))
                : "-"
              : "-",
        },
      ];
      return arr;
    },

    changeoutEveryYearItems: {
      get() {
        const comparisonA = this.commercialComparisonCalcs.optionA;
        const comparisonB = this.commercialComparisonCalcs.optionB;
        const comparisonC = this.commercialComparisonCalcs.optionC;
        const comparisonD = this.commercialComparisonCalcs.optionD;
        const arr = [
          {
            options: "Est. lifetime Media+Filters",
            currency: "(m)",
            optionA: !isNaN(comparisonA.changeoutYear)
              ? comparisonA.changeoutYear
              : "-",
            optionB: !isNaN(comparisonB.changeoutYear)
              ? comparisonB.changeoutYear
              : "-",
            optionC: !isNaN(comparisonC.changeoutYear)
              ? comparisonC.changeoutYear
              : "-",
            optionD: !isNaN(comparisonD.changeoutYear)
              ? comparisonD.changeoutYear
              : "-",
          },
        ];
        return arr;
      },
    },

    annualizesCalcItems: {
      get() {
        const comparisonA = this.commercialComparisonCalcs.optionA;
        const comparisonB = this.commercialComparisonCalcs.optionB;
        const comparisonC = this.commercialComparisonCalcs.optionC;
        const comparisonD = this.commercialComparisonCalcs.optionD;
        const arr = [
          {
            options: "Annualized Cost calc.",
            currency: this.selectedCurrencySign,
            optionA:
              comparisonA.isSelected === true
                ? !isNaN(comparisonA.annualizesCalc)
                  ? this.$i18n.n(Math.round(comparisonA.annualizesCalc))
                  : "-"
                : "-",
            optionB:
              comparisonB.isSelected === true
                ? !isNaN(comparisonB.annualizesCalc)
                  ? this.$i18n.n(Math.round(comparisonB.annualizesCalc))
                  : "-"
                : "-",
            optionC:
              comparisonC.isSelected === true
                ? !isNaN(comparisonC.annualizesCalc)
                  ? this.$i18n.n(Math.round(comparisonC.annualizesCalc))
                  : "-"
                : "-",
            optionD:
              comparisonD.isSelected === true
                ? !isNaN(comparisonD.annualizesCalc)
                  ? this.$i18n.n(Math.round(comparisonD.annualizesCalc))
                  : "-"
                : "-",
          },
        ];
        return arr;
      },
    },
  },

  mounted() {
    this.fetchInitialData();
  },

  methods: {
    fetchInitialData() {
      this.$store.dispatch("fetchCurrencies");
      this.$store.dispatch("fetchPrices");
      this.$store.dispatch("fetchDwgsCosts");
      this.$store.dispatch("fetchFiltersData");
    },

    handleRefresh() {
      this.updateCommercialComparison();
    },

    handleCassingMaterialChange(value, option) {
      this.$store.dispatch("setSelectedCassingMaterial", { option, value });
    },

    handleAnnualizedCalcChange(value, option) {
      this.$store.dispatch("setChangeoutYear", { value, option });
      this.$store.dispatch("setAnnualCost", { value, option });
    },

    updateCurrency(name) {
      if (name === this.currencySymbols[currencyEnum[name]].text) {
        this.updateExchangeRate(null);
      }
      this.$store.dispatch("updateSelectedCurrency", currencyEnum[name]);
    },

    updateExchangeRate(value) {
      this.$store.dispatch("updateExchangeRate", value);
      this.updateCommercialComparison();
    },

    updateStageCost(option, index) {
      this.$store.dispatch("setStageCost", {
        option,
        stageNo: index,
      });
    },

    updateUnitCost(option) {
      this.$store.dispatch("setUnitCost", { option });
    },

    updateElementsCost(option) {
      this.$store.dispatch("setElementsCost", {
        option: option,
        type: "insert",
      });
    },

    updatePrefilterCost(option) {
      this.$store.dispatch("setFiltersCost", { option, prefilter: true });
    },

    updateFinalFiltersCost(option) {
      this.$store.dispatch("setFiltersCost", {
        option,
        prefilter: false,
      });
    },

    updateAnnualizedCalc(option, value) {
      const optionItem = option.replace("option", "");
      this.$store.dispatch("setAnnualCost", { value, option: optionItem });
    },

    updateCasingMaterials(value, option) {
      const optionItem = option.replace("option", "");
      this.$store.dispatch("setSelectedCassingMaterials", {
        value,
        option: optionItem,
      });
    },

    updateCommercialComparison() {
      Object.keys(this.stages).forEach((stage, index) => {
        if (Object.keys(this.stages[stage]).length !== 0) {
          Object.keys(this.stages[stage]).forEach((option) => {
            this.updateStageCost(option, index);
          });
        }
      });

      Object.keys(this.commercialComparisonCalcs).forEach((option) => {
        if (this.commercialComparisonCalcs[option].isSelected === true) {
          this.updateCasingMaterials(
            this.commercialComparisonCalcs[option].code,
            option
          );
          this.updateUnitCost(option);
          this.updatePrefilterCost(option);
          this.updateFinalFiltersCost(option);
          this.updateElementsCost(option);
          this.updateAnnualizedCalc(
            option,
            this.commercialComparisonCalcs[option].changeoutYear
          );
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.form-container {
  display: flex;
  gap: 6rem;
  margin-top: 1rem;
}

.select {
  min-width: 13rem;
}

::v-deep {
  .base-table tbody tr:last-child {
    font-family: $bold-font-family;
  }
  .second-table,
  .changeout-table,
  .annualized-calc-table {
    tbody tr td:nth-of-type(2) {
      width: 5%;
    }

    tbody tr td:not(:nth-of-type(2)) {
      width: 19%;
    }
  }

  .annualized-calc-table.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr
    > td:not(:first-child),
  .annualized-calc-table.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr
    > th:not(:first-child),
  .annualized-calc-table.v-data-table
    > .v-data-table__wrapper
    > table
    > thead
    > tr
    > td:not(:first-child),
  .annualized-calc-table.v-data-table
    > .v-data-table__wrapper
    > table
    > thead
    > tr
    > th:not(:first-child),
  .annualized-calc-table.v-data-table
    > .v-data-table__wrapper
    > table
    > tfoot
    > tr
    > td:not(:first-child),
  .annualized-calc-table.v-data-table
    > .v-data-table__wrapper
    > table
    > tfoot
    > tr
    > th:not(:first-child) {
    padding: 0 0;
    transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
  }

  .second-table tbody tr {
    font-family: $bold-font-family;
  }

  .second-table tbody tr:first-child {
    border-top: $base-border;
  }

  .second-table tbody tr:first-child td:first-child {
    font-family: $bold-font-family;
  }

  .second-table tbody tr:nth-child(2) td:not(:first-child) {
    font-family: $bold-font-family;
    background-color: $light-blue;
  }

  .commercial-comparison-table tbody tr:last-child {
    border: $bold-border;
    font-family: $bold-font-family;
  }

  .filters-pellets-cost {
    span:first-child {
      font-family: $bold-font-family;
    }

    @for $i from 7 through 10 {
      span:nth-child(#{$i}) {
        font-family: $bold-font-family;
        background-color: $light-blue;
      }
    }
  }

  .changeout-table tbody tr {
    font-family: $bold-font-family;
  }

  .fft-dropdown {
    width: 19.5rem;
  }
}
</style>
