var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-10 technical-comparison-tables-container table-pre-info-container-color"},[_vm._m(0),_c('div',[_c('v-data-table',{staticClass:"base-table text-center no-header-table top-bordered-table dropdown-table bold",attrs:{"items":_vm.prefilterModelItems,"headers":_vm.prefilterModelHeaders,"hide-default-footer":"","hide-default-header":""},scopedSlots:_vm._u([_vm._l((_vm.defaultOptions),function(option){return {key:("item.option" + (option.title)),fn:function(ref){
var item = ref.item;
return [_c('v-select',{key:option.id,staticClass:"no-outline",attrs:{"items":_vm.getFilterModelsOptions(option, 'prefilter'),"item-text":"model","disabled":!_vm.selectedOptions[("layout" + (option.title))],"clearable":""},on:{"change":function($event){return _vm.handleChangePrefilterModel(
              ("option" + (option.title)),
              item[("option" + (option.title))]
            )}},model:{value:(item[("option" + (option.title))]),callback:function ($$v) {_vm.$set(item, ("option" + (option.title)), $$v)},expression:"item[`option${option.title}`]"}})]}}})],null,true)}),_c('v-data-table',{staticClass:"base-table text-center no-header-table",attrs:{"items":_vm.prefilterItems,"headers":_vm.prefilterModelHeaders,"hide-default-footer":"","hide-default-header":""}})],1),_vm._m(1),_c('div',[_c('v-data-table',{staticClass:"base-table text-center no-header-table final-filter-table bold",attrs:{"items":_vm.finalFilterModelItems,"headers":_vm.prefilterModelHeaders,"hide-default-footer":"","hide-default-header":""},scopedSlots:_vm._u([_vm._l((_vm.defaultOptions),function(option){return {key:("item.option" + (option.title)),fn:function(ref){
            var item = ref.item;
return [_c('v-select',{key:option.id,staticClass:"no-outline",attrs:{"items":_vm.getFilterModelsOptions(option, 'final'),"item-text":"model","disabled":!_vm.selectedOptions[("layout" + (option.title))],"clearable":""},on:{"change":function($event){return _vm.handleChangeFinalFilterModel(
              ("option" + (option.title)),
              item[("option" + (option.title))]
            )}},model:{value:(item[("option" + (option.title))]),callback:function ($$v) {_vm.$set(item, ("option" + (option.title)), $$v)},expression:"item[`option${option.title}`]"}})]}}})],null,true)}),_c('v-data-table',{staticClass:"base-table text-center no-header-table",attrs:{"items":_vm.finalfilterItems,"headers":_vm.prefilterModelHeaders,"hide-default-footer":"","hide-default-header":""}})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-pre-info-container-color filter-container"},[_c('span',{staticClass:"info-box"},[_vm._v("Prefilter")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-pre-info-container-color filter-container filter-container-last-item"},[_c('span',{staticClass:"info-box"},[_vm._v("Final filter")])])}]

export { render, staticRenderFns }