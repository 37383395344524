<template>
  <div class="d-flex flex-column">
    <span class="custom-subtitle bold">
      Removal capacity by media type [% w/w]
    </span>
    <basic-table
      :columns="removalCapcityBestFitsColumns"
      :rows="removalCapacityBestFitsRows"
      :columnsNumber="removalCapcityBestFitsColumns.length"
    />

    <div class="mt-8">
      <span class="custom-subtitle bold">
        Weight of media required to remove the gases [kg/year]
      </span>

      <basic-table
        :columns="removalCapcityBestFitsColumns"
        :rows="removalCapacityBestFitsRows"
        :columnsNumber="removalCapcityBestFitsColumns.length"
      />
    </div>
  </div>
</template>
<script>
import BasicTable from "../base/BasicTable.vue";
export default {
  name: "BestFits",

  components: { BasicTable },

  data() {
    return {
      removalCapcityBestFitsColumns: [
        {
          name: "Gases",
          key: "Gases",
          width: "50%",
        },
        {
          name: "CCP210",
          key: "CCP210",
        },
        {
          name: "CCP310",
          key: "CCP310",
        },
        {
          name: "CCP850",
          key: "CCP850",
        },
      ],
      removalCapacityBestFitsRows: [
        [
          {
            value: "Hydrogen sulfide (H2S)",
            id: "201",
          },
          { value: "25%", id: "202" },
          { value: "25%", id: "203" },
          { value: "25%", id: "204" },
        ],
        [
          {
            value: "Sulfur dioxide (SO2)",
            id: "215",
          },
          { value: "25%", id: "216" },
          { value: "25%", id: "217" },
          { value: "25%", id: "218" },
        ],
      ],
    };
  },
};
</script>
