<template>
  <div class="technical-content">
    <div class="fixed-container">
      <div class="d-flex align-center justify-space-between mb-8">
        <h6 class="semibold large-font-size mr-3">Technical Comparison</h6>
        <custom-button
          title="Refresh"
          type="secondary"
          @click="handleRefreshCalcs()"
        ></custom-button>
      </div>
      <v-data-table
        :headers="technicalComparisonHeaders"
        :items="casingMaterialItems"
        class="base-table text-center dropdown-table dropdown-table-margins"
        hide-default-footer
      >
        <template
          v-for="option in defaultOptions"
          v-slot:[`item.option${option.title}`]="{ item }"
        >
          <v-select
            :key="option.id"
            class="no-outline"
            :items="transformInMaterialType(item[`option${option.title}`])"
            :value="
              transformInMaterialType([
                $store.getters[`selectedCasingMaterial${option.title}`],
              ])[0]
            "
            @change="handleChangeSelectedCasingMaterial($event, option.title)"
            :disabled="!selectedOptions[`layout${option.title}`]"
            clearable
          />
        </template>
      </v-data-table>
      <v-data-table
        :headers="technicalComparisonHeaders"
        :items="constructionModelItems"
        class="base-table text-center no-header-table dropdown-table-margins"
        hide-default-header
        hide-default-footer
      >
      </v-data-table>
    </div>
    <div class="scrollable-content">
      <div class="pb-14 technical-comparison-container">
        <options-general-info :selectedOptions="selectedOptions" />
        <stages-tables :selectedOptions="selectedOptions" />
        <filter-items
          :selectedOptions="selectedOptions"
          :selectedPressureUnitMeasure="selectedPressureUnitMeasure"
        />
        <key-facts
          :selectedOptions="selectedOptions"
          :selectedPressureUnitMeasure="selectedPressureUnitMeasure"
        />
      </div>
      <div class="mt-14 pb-14 gas-removal-container">
        <div class="mb-8">
          <h6 class="semibold large-font-size mb-3">Gas removal check</h6>
        </div>
        <div>
          <div class="d-flex">
            <div class="d-flex gas-removal-check-subitem">
              <div class="mr-8">
                <span> Unit selected </span>
                <v-select
                  outlined
                  class="fft-dropdown"
                  :items="unitSelectedOptions"
                  item-text="name"
                  v-model="unitSelectedOption"
                />
              </div>
              <text-input
                label="Breakthrough safety factor [%]"
                :hideSemicolon="true"
                :value="safetyFactor"
                :maxlength="3"
                width="6rem"
                @change="handleSafetyFactorChange"
              />
            </div>
            <div class="half-container">
              <selected-option-stages-table></selected-option-stages-table>
            </div>
          </div>
          <gases-table
            @totalRemovalCapacityObjChange="setTotalRemovalCapacityObj"
            @totalTimeBreakThroughChange="setTotalTimeBreakThrough"
          ></gases-table>
        </div>
      </div>
      <equipment-features></equipment-features>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

import TextInput from "../../base/TextInput.vue";
import CustomButton from "../../base/CustomButton.vue";

import { mediaTypePD } from "../../../constants/formulas";
import { units } from "../../../constants/measuringUnits.js";
import { EventBus } from "../../../constants/event-bus";
import {
  technicalComparisonHeaders,
  prefilterModelHeaders,
} from "../../../constants/technicalComparison/headers.js";

import { defaultOptions } from "../../../store/store.js";

import KeyFacts from "../technicalComparison/KeyFacts.vue";
import FilterItems from "../technicalComparison/FilterItems.vue";
import OptionsGeneralInfo from "../technicalComparison/OptionsGeneralInfo.vue";
import StagesTables from "../technicalComparison/StagesTables.vue";
import GasesTable from "../technicalComparison/GasesTable.vue";
import EquipmentFeatures from "../technicalComparison/EquipmentFeatures.vue";
import SelectedOptionStagesTable from "./SelectedOptionStagesTable.vue";

export default {
  name: "TechnicalComparison",

  components: {
    TextInput,
    CustomButton,
    EquipmentFeatures,
    FilterItems,
    GasesTable,
    SelectedOptionStagesTable,
    KeyFacts,
    OptionsGeneralInfo,
    StagesTables,
  },

  data() {
    return {
      defaultOptions,
      technicalComparisonHeaders,
      prefilterModelHeaders,
      pressureUnits: {
        [units.Metric]: {
          text: "Pa",
        },
        [units.Imperial]: {
          text: "in w.g.",
        },
      },
      totalRemovalCapacityObj: {},
      totalTimeBreakThrough: {},
    };
  },

  computed: {
    ...mapGetters({
      safetyFactor: "btsFactor",
    }),

    ...mapGetters([
      "selectedUnitSize",
      "mediaTypes",
      "masterlist",
      "comparisonOptions",
      "stages",
      "gases",
      "selectedGases",
      "selectedGasUnit",
      "selectedGasConcentrations",
      "operatingTimeYear",
      "airflow",
      "totalGasYear",
      "projectUnitMeasure",
      "changes",
      "dwgsCosts",
    ]),

    metric() {
      if (this.projectUnitMeasure === "Metric") {
        return true;
      }

      return false;
    },

    unitSelectedOption: {
      get() {
        return this.$store.getters.unitSelectedOption;
      },

      set(newValue) {
        this.handleChangeUnitSelectedOption(newValue);
        this.getSelectedSize(newValue);
      },
    },

    selectedPressureUnitMeasure: {
      get() {
        if (this.$store.getters.projectUnitMeasure == "Imperial") {
          return this.pressureUnits[units.Imperial].text;
        }
        return this.pressureUnits[units.Metric].text;
      },
    },

    casingMaterialItems() {
      const casingMaterial = {};
      casingMaterial.options = "Casing Material";
      casingMaterial.empty1 = [];

      this.defaultOptions.forEach((option) => {
        casingMaterial[`option${option.title}`] = this.getCasingMaterials(
          this.selectedOptions[`layout${option.title}`]?.unitModel,
          option.title
        );
      });

      return [casingMaterial];
    },

    selectedLayouts() {
      return this.$store.getters.selectedLayouts.map((layout) => {
        const masterlistItem = this.masterlist.find(
          (item) => layout.id === item.id
        );
        return {
          ...layout,
          faceVelocityAtBed: masterlistItem?.faceVelocityAtBed,
          residenceTimePerBed: masterlistItem?.residenceTimePerBed,
          airFlowPerElement: masterlistItem?.airFlowPerElement,
        };
      });
    },

    selectedOptions() {
      const storeOptions = this.selectedLayouts;
      const layouts = {};

      this.defaultOptions.forEach((option) => {
        layouts[`layout${option.title}`] =
          storeOptions.find((layout) => layout.option === option.title) || null;
      });

      return layouts;
    },

    layoutsStages() {
      const layoutStages = {};

      this.defaultOptions.forEach((option) => {
        if (this.selectedOptions[`layout${option.title}`]) {
          layoutStages[option.title] =
            this.selectedOptions[`layout${option.title}`].bedsUsed;
        }
      });

      return layoutStages;
    },

    constructionModelItems() {
      const constructionModel = {};
      constructionModel.options = "Construction model";
      constructionModel.empty1 = "";

      this.defaultOptions.forEach((option) => {
        constructionModel[`option${option.title}`] = "-";

        if (this.selectedOptions[`layout${option.title}`]) {
          constructionModel[`option${option.title}`] =
            this.selectedOptions[`layout${option.title}`].unitModel;
        }
      });

      return [constructionModel];
    },

    unitSelectedOptions() {
      return this.selectedLayouts
        .map((selectedOption) => ({
          name: selectedOption.option,
          id: selectedOption.id,
        }))
        .sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
    },
  },

  mounted() {
    this.fetchInitialData();

    Object.keys(this.selectedOptions).forEach((selectedOption) => {
      this.uploadSelectedOptionsToComparisonArray(
        this.selectedOptions[selectedOption],
        selectedOption
      );
    });

    Object.keys(this.selectedOptions).forEach((option) => {
      let masterListItem = this.masterlist.find(
        (item) => item.id === this.selectedOptions[option]?.id
      );
      if (masterListItem && this.selectedOptions[option]) {
        this.selectedOptions[option].residenceTimePerBed =
          masterListItem?.residenceTimePerBed;
        this.selectedOptions[option].faceVelocityAtBed =
          masterListItem?.faceVelocityAtBed;
      }
    });

    this.$store.dispatch("updateProjectChangesState", false);
  },

  methods: {
    mediaTypePD,

    ...mapActions([
      "updateProjectChangesState",
      "setHasChanges",
      "fetchDwgsCosts",
      "fetchFiltersData",
      "fetchInletTypes",
      "fetchOutletTypes",
      "fetchOutletTypes",
      "fetchPositionsInletOutlet",
      "fetchPdMeasurements",
      "setCasingMaterialsList",
      "setSelectedUnitSize",
      "setUnitSelectedOption",
    ]),

    /**
     * gets all the important data we need for the calculations
     */
    fetchInitialData() {
      this.fetchDwgsCosts();
      this.fetchFiltersData();
      this.fetchInletTypes();
      this.fetchOutletTypes();
      this.fetchPositionsInletOutlet();
      this.fetchPdMeasurements();
      this.setCasingMaterialsList();
    },

    /**
     * trigger functions for media types, projectHasChanges (store.js) state, refreshes unit selectd option,
     * calls function for refreshing equipment's features, sets hasChanges to false (systemOptins)
     */
    handleRefreshCalcs() {
      this.emitRefreshCalcsForMediaTypes();

      this.updateProjectChangesState(false);

      this.handleChangeUnitSelectedOption(this.unitSelectedOption);

      if (this.changes) {
        this.getSelectedSize(this.unitSelectedOption);
        this.setHasChanges(false);
      }
    },

    /**
     * iterates over each stage and each option from a stage that has media type saved and emits an event for recalculating media capacity
     */
    emitRefreshCalcsForMediaTypes() {
      Object.values(this.stages).forEach((stageValue, index) => {
        this.selectedLayouts.forEach((layout) => {
          const currentOptionName = layout.option;
          const mediaId = stageValue[`option${currentOptionName}`]?.mediaType;

          if (mediaId) {
            EventBus.$emit(
              "calculateMediaCapacity",
              currentOptionName,
              mediaId,
              index
            );
          }
        });
      });
    },

    /**
     * finds the selected size (ex MPS, DPS) using the unit selected option and saves it in store and emits an event for updating equipment's features
     * @param {string} unitSelectedOption (A, B, C, D)
     */
    getSelectedSize(value) {
      const option = this.comparisonOptions[`option${value}`];
      const constructionModel = option.generalInfo.constructionModel;
      const selectedSize = constructionModel.split("-")[0];

      this.setSelectedUnitSize(selectedSize);

      EventBus.$emit("unitSizeUpdated", selectedSize);
    },

    /**
     * builds a new list with full name for a list of abbreviated names (only DW, SS, CS)
     * @param {array} list
     * @returns {array} material types list
     */
    transformInMaterialType(list) {
      const materialTypes = [];
      list.forEach((item) => {
        if (item === "DW") materialTypes.push("Double Wall");
        if (item === "SS") materialTypes.push("Stainless Steel");
        if (item === "CS") materialTypes.push("Carbon Steel painted");
      });
      return materialTypes;
    },

    transformInMaterialCode(materialCode) {
      if (materialCode === "Double Wall") return "DW";
      if (materialCode === "Stainless Steel") return "SS";
      if (materialCode === "Carbon Steel painted") return "CS";
    },

    /**
     * get list of casing materials for an received option, using the list of all casing materials and the unit model of the option received
     * @param {string} unitModel
     * @param {string} option
     * @returns {array}
     */
    getCasingMaterials(unitModel, option) {
      let dropdownCassingMaterials = [];

      if (unitModel !== "") {
        dropdownCassingMaterials = this.dwgsCosts
          .filter((cost) => cost.unitModel === unitModel)
          .map((filter) => filter.casingMaterial);
      }

      if (dropdownCassingMaterials.length === 0) {
        this.$store.commit(`changeSelectedCasingMaterial${option}`, "");
      }
      return dropdownCassingMaterials;
    },

    handleChangeUnitSelectedOption(event) {
      this.setUnitSelectedOption(event);
    },

    handleChangeSelectedCasingMaterial(selection, option) {
      if (selection !== null) {
        const materialCodeSelection = this.transformInMaterialCode(selection);

        this.$store.commit(
          `changeSelectedCasingMaterial${option}`,
          materialCodeSelection
        );

        this.handleCostsSave(materialCodeSelection, option);
      } else {
        this.$store.commit(`changeSelectedCasingMaterial${option}`, "");
        this.handleCostsSave("", option);
      }
    },

    handleCostsSave(selected, option) {
      const concatenate = [
        this.selectedOptions[`layout${option}`].unitModel,
        selected,
      ].join("-");

      this.$store.dispatch("setSelectedCassingMaterials", {
        value: concatenate,
        option,
      });

      this.$store.dispatch("setCode", {
        value: [this.selectedOptions[`layout${option}`].unitModel, selected],
        option,
      });

      this.$store.dispatch("setUnitCost", { option: `option${option}` });
    },

    handleSafetyFactorChange(newValue) {
      if (newValue > 100 || newValue < 0 || isNaN(newValue)) {
        this.$store.dispatch("updateBtsFactor", 100);
      } else {
        this.$store.dispatch("updateBtsFactor", newValue);
      }
    },

    /**
     * saves in store a copy of general info for a given option
     * @param {object} option
     * @param {string} optionName
     */
    uploadSelectedOptionsToComparisonArray(option, optionName) {
      if (option) {
        const generalInfo = {
          constructionModel: option.unitModel,
          filterMatrix: option.filterMatrix,
          chosenAirflow: this.airflow,
          gpfElement: option.gpfElement,
          gpfElementImgUrl: option.picture,
          elementsPerStage: option.elementsPerStage,
          chemicalStages: option.bedsUsed,
          residenceTimePerBed: option.residenceTimePerBed,
          faceVelocityAtBed: option.faceVelocityAtBed,
          fanType: option.fanType,
          systemType: option.systemType,
        };

        const comparisonOption = optionName.replace("layout", "");

        this.$store.dispatch("setComparisonOptions", {
          option: `option${comparisonOption}`,
          comparisonItem: "generalInfo",
          value: generalInfo,
        });
      }
    },

    setTotalRemovalCapacityObj(event) {
      this.totalRemovalCapacityObj = event;
    },

    setTotalTimeBreakThrough(event) {
      this.totalTimeBreakThrough = event;
    },
  },

  /**
   * when we leave the component, saves in store, for the current selected option, the totalRemovalCapacity and totalTimeBreakThrough from the component state
   */
  destroyed() {
    if (this.unitSelectedOption) {
      this.$store.dispatch("setComparisonOptions", {
        option: `option${this.unitSelectedOption}`,
        comparisonItem: "totalRemovalCapacity",
        value: this.totalRemovalCapacityObj,
      });
      this.$store.dispatch("setComparisonOptions", {
        option: `option${this.unitSelectedOption}`,
        comparisonItem: "totalTimeBreakThrough",
        value: this.totalTimeBreakThrough,
      });
    }
  },
};
</script>
<style lang="scss" scoped>
::v-deep .v-select__selection--comma {
  white-space: normal !important;
}

::v-deep {
  .technical-comparison-tables-container {
    display: grid;
    grid-template-columns: 5rem 1fr;
  }

  .technical-comparison-tables-container {
    table {
      td:nth-of-type(2) {
        width: 5% !important;
        min-width: 5rem;
        max-width: 5rem;
      }

      td:nth-of-type(1) {
        width: 16%;
        min-width: 16rem;
      }

      td:not(:nth-of-type(2)):not(:nth-of-type(1)) {
        width: 18%;
      }
    }
  }

  .dropdown-table,
  .no-header-table {
    table {
      td:nth-of-type(2) {
        width: 5%;
        min-width: 5rem;
      }

      td:nth-of-type(1) {
        width: 16%;
        min-width: 16rem;
      }

      td:not(:nth-of-type(2)):not(:nth-of-type(1)) {
        width: 18%;
      }
    }
  }
}

.technical-comparison-container,
.gas-removal-container {
  border-bottom: 1px solid $basic-blue;
}

.gas-removal-check-subitem {
  width: 50%;
}

::v-deep {
  .key-facts-table tbody tr {
    td:nth-child(3),
    td:nth-child(4),
    td:nth-child(5),
    td:nth-child(6) {
      padding: 0;
    }
  }

  .bold-table tr:first-child {
    font-family: $bold-font-family;
    border-top: $base-border;
  }

  @for $i from 3 through 6 {
    .no-header-table tbody tr td {
      width: 18%;
    }
  }

  .dropdown-table {
    height: fit-content;
  }

  .dropdown-table-margins {
    margin-left: 5.01rem;
  }

  .dropdown-table.v-data-table,
  .no-header-table.bold-table.v-data-table,
  .no-header-table.dropdown-table.v-data-table,
  .no-header-table.final-filter-table.v-data-table {
    & > .v-data-table__wrapper > table > tbody > tr > td {
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6) {
        background-color: $light-blue;
      }
    }
  }

  .base-table.top-bordered-table tbody {
    border-top: $base-border;
  }

  .key-facts-table td {
    font-family: $bold-font-family;
  }

  .stages-table thead tr th:nth-child(1),
  .stages-table thead tr th:nth-child(2) {
    visibility: hidden;
  }

  .stages-table {
    thead {
      tr th:first-child {
        border-right: 0;
      }
    }
  }

  .stages-precontainer thead {
    visibility: hidden;
  }
}

p {
  display: flex;
  justify-content: center;
  align-items: center;
}

.half-container {
  width: 50%;
}

.gas-breakthrough-subitem {
  width: 60%;
}

.technical-content {
  .fixed-container {
    margin-right: 1px;
  }

  .scrollable-content {
    height: 45vh;
    overflow: auto;
    margin-right: -16px;
  }
}
</style>
