<template>
  <div>
    <div class="search">
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        class="search-bar mb-3"
      />
    </div>
    <div
      v-for="(option, index) in filteredItems"
      :key="index"
      class="checkbox-list"
    >
      <v-checkbox
        :label="
          label ? (option[label] ? option[label].toString() : '') : option
        "
        :value="option.isChecked ? option : false"
        color="accent"
        @change="handleChangeOption($event, option)"
      ></v-checkbox>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    options: Array,
    filteredValues: Array,
    label: [Number, String, Array],
  },

  data() {
    return {
      selectedOption: this.value !== "" ? this.value : "",
      search: "",
      selectedOptions:
        this.filteredValues && this.filteredValues.length > 0
          ? this.filteredValues
          : [],
    };
  },

  computed: {
    /**
     * filters categories according to the searched value
     * sets status checked if a value from a category was before selected
     * @returns {any}
     */
    filteredItems() {
      if (this.search !== "") {
        return this.options.filter((item) => {
          return item[this.label]
            .toString()
            .toLowerCase()
            .match(this.search.toString().toLowerCase());
        });
      }
      if (this.filteredValues) {
        const newOptions = this.options.map((item) => {
          const filtered = this.filteredValues.find(
            (val) => val.id === item.id
          );

          return {
            ...item,
            isChecked: filtered ? true : false,
          };
        });
        return newOptions;
      } else {
        return this.options;
      }
    },
  },

  methods: {
    handleChangeOption(event, option) {
      if (event) {
        if (option.segmentName) {
          option.id = this.$store.getters.segments.find(
            (seg) => seg.segmentName === option.segmentName
          ).id;
        } else if (option.branch) {
          option.id = this.$store.getters.regions.find(
            (branch) => branch.branch === option.branch
          ).id;
        }
        this.selectedOptions.push(option);
      } else {
        const index = this.selectedOptions.findIndex(
          (item) => item.id === option.id
        );
        this.selectedOptions.splice(index, 1);
      }
      this.$emit("updateOption", this.selectedOptions);
    },
  },
};
</script>
<style lang="scss" scoped>
.v-input {
  margin: 0 !important;
}

.checkbox-list {
  border-bottom: $base-border;
  padding: 0.3rem 0 0.4rem 1rem;
  margin: 0 !important;

  &:first-child {
    border-top: $base-border;
  }

  &.v-item--active {
    background-color: $light-blue;
  }
}

.search {
  padding: 0.5rem 1rem;
}
</style>
