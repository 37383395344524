import axios from "axios";
import {
  fromMgM3ToPpb,
  fromMgM3ToPpm,
  fromPpbToMgM3,
  fromPpmToMgM3,
  totalGas,
  weightOfMedia,
} from "../../constants/formulas.js";

export class Gas {
  unit;
  gasData;
  inletConcentration;
  constructor(obj, index = undefined) {
    if (obj) {
      this.unit = obj?.unit;
      this.gasData = obj?.gasData;
      this.inletConcentration = obj?.inletConcentration;
      this.index = index;
    } else {
      this.unit = "";
      this.gasData = {};
      this.inletConcentration = "";
      this.index = undefined;
    }
  }
}
export class GasModel {
  constructor(obj) {
    if (obj && obj.length) {
      for (let i = 0; i < obj.length; i++) {
        this[`gas${i}`] = new Gas(obj[i], i);
      }
    }

    for (let i = 0; i < 4; i++) {
      if (this[`gas${i}`] === undefined) {
        this[`gas${i}`] = new Gas(undefined, i);
      }
    }
  }
}

const state = {
  gases: [],
  selectedGases: new GasModel(),
  gasUnits: ["mg/m3", "ppm", "ppb"],
  selectedGasUnit: GasModel,
  selectedGasConcentrations: {
    gas0: {
      "mg/m3": "",
      ppm: "",
      ppb: "",
    },
    gas1: {
      "mg/m3": "",
      ppm: "",
      ppb: "",
    },
    gas2: {
      "mg/m3": "",
      ppm: "",
      ppb: "",
    },
    gas3: {
      "mg/m3": "",
      ppm: "",
      ppb: "",
    },
  },
  totalGasYear: GasModel,
  opHours: 24,
  opDays: 7,
  opWeeks: 50,
  operatingTimeYear: 8400,
  concentration: GasModel,

  gClassThresholds: [],
  gasesGClass: {},
  removalCapacity: [],
  weightOfMedia: [],
};

const getters = {
  gases: (state) => state.gases,
  selectedGases: (state) => state.selectedGases,
  gasUnits: (state) => state.gasUnits,
  selectedGasUnit: (state) => state.selectedGasUnit,
  selectedGasConcentrations: (state) => state.selectedGasConcentrations,
  totalGasYear: (state) => state.totalGasYear,
  opHours: (state) => state.opHours,
  opDays: (state) => state.opDays,
  opWeeks: (state) => state.opWeeks,
  operatingTimeYear: (state) => state.operatingTimeYear,
  concentration: (state) => state.concentration,
  gClassThresholds: (state) => state.gClassThresholds,
  gasesGClass: (state) => state.gasesGClass,
  removalCapacity: (state) => state.removalCapacity,
  weightOfMedia: (state) => state.weightOfMedia,
};

const mutations = {
  setSelectedGas(state, payload) {
    if (payload && payload.length > 0) {
      state.selectedGases = new GasModel(payload);
    } else if (payload && payload.length === undefined) {
      state.selectedGases = payload;
    } else {
      state.selectedGases = new GasModel();
    }
  },

  setSelectedGasUnit(state, payload) {
    state.selectedGasUnit = payload;
  },

  setConcentrations(state, payload) {
    state.selectedGasConcentrations = payload;
  },

  setOpHours(state, payload) {
    state.opHours = payload;
  },

  setOpDays(state, payload) {
    state.opDays = payload;
  },

  setOpWeeks(state, payload) {
    state.opWeeks = payload;
  },

  updateOperatingTime(state) {
    state.operatingTimeYear = state.opHours * state.opDays * state.opWeeks;
  },

  setTotalGasYear(state, payload) {
    state.totalGasYear = payload;
  },

  setConcentration(state, payload) {
    state.concentration = payload;
  },

  setGClassThresholds(state, payload) {
    state.gClassThresholds = payload;
  },

  setGClass(state, payload) {
    state.gasesGClass = payload;
  },

  setRemovalCapacity(state, payload) {
    state.removalCapacity = payload;
  },

  setWeightOfMedia(state, payload) {
    state.weightOfMedia = payload;
  },

  setCapacityData(state, payload) {
    state.gases = payload;
  },

  setGases(state, payload) {
    state.selectedGases[`gas${payload.index}`] = payload.removedGas;
  },
};

const actions = {
  setSelectedGases(context, payload) {
    context.commit("setSelectedGas", payload);

    Object.values(context.getters.selectedGases).forEach((gas) => {
      if (Object.keys(gas.gasData).length > 0) {
        context.dispatch("addGClassThreshold", {
          gasId: gas.gasData.id,
          gasIndex: gas.index,
        });

        context.dispatch("addRemovalCapacity", {
          gasId: gas.gasData.id,
          gasIndex: gas.index,
        });
      }
    });
  },

  setSelectedGas(context, payload) {
    let newGases = context.getters.selectedGases;
    const gasIndex = payload.gasIndex;
    const value = payload.value;

    newGases[`gas${gasIndex}`].gasData = context.getters.gases.find(
      (gas) => gas.id === value
    );
    context.commit("setSelectedGas", newGases);
  },

  setSelectedGasUnit(context, payload) {
    let newGasUnits = context.getters.selectedGases;
    const gasIndex = payload.gasIndex;
    const value = payload.value;

    newGasUnits[`gas${gasIndex}`].unit = value;

    context.commit("setSelectedGasUnit", newGasUnits);
  },

  setConcentrations(context, payload) {
    const { selectedGasConcentrations: newGasConcentrations } = context.getters;
    const { gasIndex, gas, inlet, unitMeasure } = payload;
    const gasId = `gas${gasIndex}`;

    const gasMolarMass = gas.gasData.molarMass;

    switch (unitMeasure) {
      case "mg/m3":
        newGasConcentrations[gasId]["mg/m3"] = inlet;
        newGasConcentrations[gasId]["ppm"] = fromMgM3ToPpm(inlet, gasMolarMass);
        newGasConcentrations[gasId]["ppb"] = fromMgM3ToPpb(inlet, gasMolarMass);
        break;
      case "ppm":
        newGasConcentrations[gasId]["mg/m3"] = fromPpmToMgM3(
          inlet,
          gasMolarMass
        );
        newGasConcentrations[gasId]["ppm"] = inlet;
        newGasConcentrations[gasId]["ppb"] = inlet * 1000;
        break;
      case "ppb":
        newGasConcentrations[gasId]["mg/m3"] = fromPpbToMgM3(
          inlet,
          gasMolarMass
        );
        newGasConcentrations[gasId]["ppm"] = inlet / 1000;
        newGasConcentrations[gasId]["ppb"] = inlet;
        break;
    }

    context.commit("setConcentrations", newGasConcentrations);
  },

  resetAllGasValues(context) {
    for (let i = 0; i < 4; i++) {
      context.dispatch("resetGasValues", { gasIndex: i });
    }
  },

  resetGasValues(context, payload) {
    const { gasIndex } = payload;
    const {
      selectedGasConcentrations: newGasConcentrations,
      totalGasYear: newTotalGasYear,
      gasesGClass: newGasesGClass,
    } = context.getters;
    const gas = `gas${gasIndex}`;

    newGasConcentrations[gas]["mg/m3"] = "";
    newGasConcentrations[gas]["ppm"] = "";
    newGasConcentrations[gas]["ppb"] = "";
    newTotalGasYear[gas] = 0;
    newGasesGClass[gas] = "";

    context.commit("setConcentrations", newGasConcentrations);
    context.commit("setTotalGasYear", newTotalGasYear);
    context.commit("setGClass", newGasesGClass);
  },

  setTotalGasYear(context, payload) {
    const {
      totalGasYear: newTotalGasYear,
      operatingTimeYear,
      selectedGasConcentrations,
      airflow,
    } = context.getters;

    const gasIndex = payload.gasIndex;
    const gas = `gas${gasIndex}`;

    const gasConcentration = selectedGasConcentrations[gas]["mg/m3"];
    newTotalGasYear[gas] = totalGas(
      gasConcentration,
      airflow,
      operatingTimeYear
    );

    context.commit("setTotalGasYear", newTotalGasYear);
  },

  setOpHours(context, payload) {
    context.commit("setOpHours", payload);
  },

  setOpDays(context, payload) {
    context.commit("setOpDays", payload);
  },

  setOpWeeks(context, payload) {
    context.commit("setOpWeeks", payload);
  },

  updateOperatingTime(context) {
    context.commit("updateOperatingTime");
  },

  setConcentration(context, payload) {
    let newGases = context.getters.selectedGases;
    const gasIndex = payload.gasIndex;
    const value = payload.value;

    newGases[`gas${gasIndex}`].inletConcentration = value;

    context.commit("setSelectedGas", newGases);
  },

  recalculateTotalGasYear(context) {
    const {
      totalGasYear: newTotalGasYear,
      operatingTimeYear,
      airflow,
      selectedGasConcentrations,
    } = context.getters;

    for (let i = 0; i <= 3; i++) {
      if (newTotalGasYear[`gas${i}`] !== "") {
        const gasConcentration = selectedGasConcentrations[`gas${i}`]["mg/m3"];
        newTotalGasYear[`gas${i}`] = totalGas(
          gasConcentration,
          airflow,
          operatingTimeYear
        );
      }
    }

    context.commit("setTotalGasYear", newTotalGasYear);
  },

  addGClassThreshold(context, payload) {
    const { gClassThresholds: newGClassThresholds, gases } = context.getters;
    const { gasId, gasIndex } = payload;
    const gas = gases.find((gas) => gas.id === gasId);
    let isUpdate = false;
    if (!gas) {
      return;
    }

    if (newGClassThresholds.length !== 0) {
      newGClassThresholds.forEach((item) => {
        if (item.gasIndex === gasIndex) {
          isUpdate = true;
          item.gasName = gas.gasName;
          item.G1 = gas.G1;
          item.G2 = gas.G2;
          item.G3 = gas.G3;
          item.GX = gas.GX;
          item.GXPlus = gas.GXPlus;
        }
      });
    }

    if (isUpdate === false) {
      const objToAdd = {
        gasIndex,
        gasName: gas.gasName,
        G1: gas.G1,
        G2: gas.G2,
        G3: gas.G3,
        GX: gas.GX,
        GXPlus: gas.GXPlus,
      };

      newGClassThresholds.push(objToAdd);
    }

    context.commit("setGClassThresholds", newGClassThresholds);
  },

  setGClass(context, payload) {
    const { gasesGClass: newGasesGClass, selectedGasConcentrations } =
      context.getters;
    const { gas, gasIndex } = payload;
    const concentration = Number(
      selectedGasConcentrations[`gas${gasIndex}`]["ppb"]
    );

    const { G1, G2, G3, GX, GXPlus } = gas.gasData;
    if (G1 !== null) {
      if (concentration >= G3) {
        if (concentration >= GX) {
          if (concentration >= GXPlus) {
            newGasesGClass[`gas${gasIndex}`] = "GX+";
          } else {
            newGasesGClass[`gas${gasIndex}`] = "GX";
          }
        } else {
          newGasesGClass[`gas${gasIndex}`] = "G3";
        }
      } else {
        if (concentration < G2) {
          newGasesGClass[`gas${gasIndex}`] = "G1";
        } else {
          newGasesGClass[`gas${gasIndex}`] = "G2";
        }
      }
    } else {
      newGasesGClass[`gas${gasIndex}`] = null;
    }

    context.commit("setGClass", newGasesGClass);
  },

  addRemovalCapacity(context, payload) {
    const { removalCapacity: newRemovalCapacity, gases } = context.getters;
    const { gasId, gasIndex } = payload;
    const {
      gasName,
      CCP104,
      CCP108,
      CCP210,
      CCP310,
      CCP320,
      CCP510,
      CCP610,
      CCP630,
      CCP810,
      CCP830,
      CCP840,
      CCP840plus,
      CCP860,
      CCP903,
      WVIS,
      HM,
      CCP_Dummy,
    } = gases.find((gas) => gas.id === gasId);

    let isUpdate = false;

    if (newRemovalCapacity.length !== 0) {
      newRemovalCapacity.forEach((item) => {
        if (item.gasIndex === gasIndex) {
          isUpdate = true;
          item.gasName = gasName;
          item.CCP104 = CCP104 ? CCP104 + "%" : "";
          item.CCP108 = CCP108 ? CCP108 + "%" : "";
          item.CCP210 = CCP210 ? CCP210 + "%" : "";
          item.CCP310 = CCP310 ? CCP310 + "%" : "";
          item.CCP320 = CCP320 ? CCP320 + "%" : "";
          item.CCP510 = CCP510 ? CCP510 + "%" : "";
          item.CCP610 = CCP610 ? CCP610 + "%" : "";
          item.CCP630 = CCP630 ? CCP630 + "%" : "";
          item.CCP810 = CCP810 ? CCP810 + "%" : "";
          item.CCP830 = CCP830 ? CCP830 + "%" : "";
          item.CCP840 = CCP840 ? CCP840 + "%" : "";
          item.CCP840plus = CCP840plus ? CCP840plus + "%" : "";
          item.CCP860 = CCP860 ? CCP860 + "%" : "";
          item.CCP903 = CCP903 ? CCP903 + "%" : "";
          item.WVIS = WVIS ? WVIS + "%" : "";
          item.HM = HM ? HM + "%" : "";
          item.CCP_Dummy = CCP_Dummy ? CCP_Dummy + "%" : "";
        }
      });
    }

    if (isUpdate === false) {
      const objToAdd = {
        gasIndex,
        gasName,
        CCP104: CCP104 ? CCP104 + "%" : "",
        CCP108: CCP108 ? CCP108 + "%" : "",
        CCP210: CCP210 ? CCP210 + "%" : "",
        CCP310: CCP310 ? CCP310 + "%" : "",
        CCP320: CCP320 ? CCP320 + "%" : "",
        CCP510: CCP510 ? CCP510 + "%" : "",
        CCP610: CCP610 ? CCP610 + "%" : "",
        CCP630: CCP630 ? CCP630 + "%" : "",
        CCP810: CCP810 ? CCP810 + "%" : "",
        CCP830: CCP830 ? CCP830 + "%" : "",
        CCP840: CCP840 ? CCP840 + "%" : "",
        CCP840plus: CCP840plus ? CCP840plus + "%" : "",
        CCP860: CCP860 ? CCP860 + "%" : "",
        CCP903: CCP903 ? CCP903 + "%" : "",
        WVIS: WVIS ? WVIS + "%" : "",
        HM: HM ? HM + "%" : "",
        CCP_Dummy: CCP_Dummy ? CCP_Dummy + "%" : "",
      };

      newRemovalCapacity.push(objToAdd);
    }

    context.commit("setRemovalCapacity", newRemovalCapacity);
  },

  addWeightOfMedia(context, payload) {
    const { weightOfMedia: newWeightOfMedia, totalGasYear } = context.getters;
    const { gas, gasIndex } = payload;
    const {
      gasName,
      CCP104,
      CCP108,
      CCP210,
      CCP310,
      CCP320,
      CCP510,
      CCP610,
      CCP630,
      CCP810,
      CCP830,
      CCP840,
      CCP840plus,
      CCP860,
      CCP903,
      WVIS,
      HM,
      CCP_Dummy,
    } = gas.gasData;

    let isUpdate = false;
    const totalGas = totalGasYear[`gas${gasIndex}`];

    if (newWeightOfMedia.length !== 0) {
      newWeightOfMedia.forEach((item) => {
        if (item.gasIndex === gasIndex) {
          isUpdate = true;
          item.gasName = gasName;
          item.CCP104 = CCP104 ? weightOfMedia(totalGas, CCP104) : "";
          item.CCP108 = CCP108 ? weightOfMedia(totalGas, CCP108) : "";
          item.CCP210 = CCP210 ? weightOfMedia(totalGas, CCP210) : "";
          item.CCP310 = CCP310 ? weightOfMedia(totalGas, CCP310) : "";
          item.CCP320 = CCP320 ? weightOfMedia(totalGas, CCP320) : "";
          item.CCP510 = CCP510 ? weightOfMedia(totalGas, CCP510) : "";
          item.CCP610 = CCP610 ? weightOfMedia(totalGas, CCP610) : "";
          item.CCP630 = CCP630 ? weightOfMedia(totalGas, CCP630) : "";
          item.CCP810 = CCP810 ? weightOfMedia(totalGas, CCP810) : "";
          item.CCP830 = CCP830 ? weightOfMedia(totalGas, CCP830) : "";
          item.CCP840 = CCP840 ? weightOfMedia(totalGas, CCP840) : "";
          item.CCP840plus = CCP840plus
            ? weightOfMedia(totalGas, CCP840plus)
            : "";
          item.CCP860 = CCP860 ? weightOfMedia(totalGas, CCP860) : "";
          item.CCP903 = CCP903 ? weightOfMedia(totalGas, CCP903) : "";
          item.WVIS = WVIS ? weightOfMedia(totalGas, WVIS) : "";
          item.HM = HM ? weightOfMedia(totalGas, HM) : "";
          item.CCP_Dummy = CCP_Dummy ? weightOfMedia(totalGas, CCP_Dummy) : "";
        }
      });
    }

    if (isUpdate === false) {
      const objToAdd = {
        gasIndex,
        gasName,
        CCP104: CCP104 ? weightOfMedia(totalGas, CCP104) : "",
        CCP108: CCP108 ? weightOfMedia(totalGas, CCP108) : "",
        CCP210: CCP210 ? weightOfMedia(totalGas, CCP210) : "",
        CCP310: CCP310 ? weightOfMedia(totalGas, CCP310) : "",
        CCP320: CCP320 ? weightOfMedia(totalGas, CCP320) : "",
        CCP510: CCP510 ? weightOfMedia(totalGas, CCP510) : "",
        CCP610: CCP610 ? weightOfMedia(totalGas, CCP610) : "",
        CCP630: CCP630 ? weightOfMedia(totalGas, CCP630) : "",
        CCP810: CCP810 ? weightOfMedia(totalGas, CCP810) : "",
        CCP830: CCP830 ? weightOfMedia(totalGas, CCP830) : "",
        CCP840: CCP840 ? weightOfMedia(totalGas, CCP840) : "",
        CCP840plus: CCP840plus ? weightOfMedia(totalGas, CCP840plus) : "",
        CCP860: CCP860 ? weightOfMedia(totalGas, CCP860) : "",
        CCP903: CCP903 ? weightOfMedia(totalGas, CCP903) : "",
        WVIS: WVIS ? weightOfMedia(totalGas, WVIS) : "",
        HM: HM ? weightOfMedia(totalGas, HM) : "",
        CCP_Dummy: CCP_Dummy ? weightOfMedia(totalGas, CCP_Dummy) : "",
      };

      newWeightOfMedia.push(objToAdd);
    }

    context.commit("setWeightOfMedia", newWeightOfMedia);
  },

  async fetchCapacityData(context) {
    try {
      const res = await axios.get("/api/capacity-data");
      if (context.state.gases.length === 0) {
        context.commit("setCapacityData", res.data);
      }
    } catch (err) {
      console.log(err, "err");
    }
  },

  resetGas({ commit }, payload) {
    const removedGas = new Gas({
      unit: "",
      gasData: {},
      inletConcentration: "",
    });

    commit("setGases", { removedGas, index: payload });
  },

  resetRemovalCapacity({ getters, commit }, payload) {
    const removalCapacity = getters.removalCapacity;
    const removedGas = removalCapacity.find(
      (item) => item.gasIndex === payload
    );

    if (removedGas) {
      const index = removalCapacity.indexOf(removedGas);
      removalCapacity.splice(index, 1);
      commit("setRemovalCapacity", removalCapacity);
    }
  },

  resetWeightOfMedia({ getters, commit }, payload) {
    const weightOfMedia = getters.weightOfMedia;
    const removedGas = weightOfMedia.find((item) => item.gasIndex === payload);

    if (removedGas) {
      const index = weightOfMedia.indexOf(removedGas);
      weightOfMedia.splice(index, 1);
      commit("setWeightOfMedia", weightOfMedia);
    }
  },

  resetGClassThresholds({ getters, commit }, payload) {
    const gClassThresholds = getters.gClassThresholds;
    const removedGas = gClassThresholds.find(
      (item) => item.gasIndex === payload
    );

    if (removedGas) {
      const index = gClassThresholds.indexOf(removedGas);
      gClassThresholds.splice(index, 1);
      commit("setGClassThresholds", gClassThresholds);
    }
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
