<template>
  <div>
    <segmented-button
      :options="airflowOptions"
      :selectedKey="selectedAirflowType"
      @option-change="handleOptionChange"
    />
    <div v-if="selectedAirflowType === 'available'" class="mt-8 airflow-form">
      <div class="mr-2">
        <text-input
          v-if="$store.getters.projectUnitMeasure === `Metric`"
          label="Airflow"
          width="8rem"
          :value="airflowValue"
          @change="handleAirflowValueChange"
        />
      </div>
      <div class="mr-2">
        <text-input
          v-if="$store.getters.projectUnitMeasure === `Imperial`"
          label="Airflow"
          width="8rem"
          :value="airflowValueImperial"
          @change="handleAirflowImperialValueChange"
        />
      </div>
      <div class="vertical-align">
        <span>{{ selectedAirflowMeasureUnitKey.text }}</span>
      </div>
    </div>
    <div v-else class="mt-8 airflow-form">
      <div>
        <div class="d-flex flex-wrap">
          <div class="airflow-form">
            <div class="mr-2">
              <text-input
                label="Room volume"
                width="8rem"
                :value="roomValue_"
                @change="handleRoomValueChange"
              />
            </div>
            <div class="vertical-align">
              <span>
                {{ roomValueSelectedUnitMeasure.text }}
              </span>
            </div>
          </div>

          <div class="mr-8 ml-8 w-20rem">
            <label class="input-label">System type:</label>

            <v-select
              outlined
              item-color="rgba(214, 225, 236, 1)"
              class="fft-dropdown"
              v-model="selectedSystemType"
              :full-width="false"
              :items="systemTypeOptions"
              item-text="systemName"
              item-value="id"
            ></v-select>
          </div>
          <div class="w-20rem">
            <label class="input-label">Room condition:</label>

            <v-select
              outlined
              item-color="rgba(214, 225, 236, 1)"
              class="fft-dropdown"
              v-model="selectedRoomCondition"
              :full-width="false"
              :items="roomConditionOptions"
              item-text="conditionName"
              item-value="id"
            ></v-select>
          </div>
        </div>
        <div class="d-flex mt-8">
          <div class="column-flex">
            <span> Recommended air changes per hour: </span>
            <span>
              {{
                $store.getters.recommendedAirChangesPerHour !== ""
                  ? $store.getters.recommendedAirChangesPerHour
                  : "-"
              }}
            </span>
          </div>
          <div class="column-flex ml-14">
            <label class="input-label">Recommended air flow: </label>
            <div class="d-flex">
              <span class="mr-2">
                {{
                  recommendedAirflowValue !== "" ? recommendedAirflowValue : "-"
                }}
              </span>

              <div class="vertical-align">
                <span>
                  {{ selectedAirflowMeasureUnitKey.text }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SegmentedButton from "../../components/base/SegmentedButton.vue";
import TextInput from "../../components/base/TextInput.vue";
import { units } from "../../constants/measuringUnits.js";

export default {
  name: "AirflowDefinition",

  components: { SegmentedButton, TextInput },

  data() {
    return {
      airflowOptions: [
        {
          key: "available",
          text: "Airflow available",
        },
        {
          key: "calculation",
          text: "Airflow calculation",
        },
      ],

      airflowUnits: {
        [units.Metric]: {
          text: "m³/h",
        },
        [units.Imperial]: {
          text: "ft³/min",
        },
      },

      roomUnits: {
        [units.Metric]: {
          text: "m³",
        },
        [units.Imperial]: {
          text: "ft³",
        },
      },
    };
  },

  computed: {
    airflowValue: {
      get() {
        return this.$store.getters.airflow;
      },
      // set(newValue) {
      //   this.$store.commit("changeAirflow", newValue);
      // },
    },

    airflowValueImperial: {
      get() {
        return this.$store.getters.airflowImperial;
      },
    },

    selectedAirflowType: {
      get() {
        return this.$store.getters.selectedAirflowType;
      },
      set(newValue) {
        this.$store.commit("selectedAirflowType", newValue);
      },
    },

    airflowMeasureUnitOptions: {
      get() {
        return this.$store.getters.allAirflowUnits;
      },
      set(newValue) {
        this.$store.dispatch("setAirflowUnitsList", newValue);
      },
    },

    roomValueMeasureUnitOptions: {
      get() {
        return this.$store.getters.allRoomUnits;
      },
      set(newValue) {
        this.$store.dispatch("setRoomUnitsList", newValue);
      },
    },

    roomConditionOptions: {
      get() {
        return this.$store.getters.allRoomConditions;
      },
      set(newValue) {
        this.$store.dispatch("setRoomConditionsList", newValue);
      },
    },

    systemTypeOptions: {
      get() {
        return this.$store.getters.allSystemTypes;
      },
      set(newValue) {
        this.$store.dispatch("setSystemTypesList", newValue);
      },
    },

    roomValueSelectedUnitMeasure: {
      get() {
        if (this.$store.getters.projectUnitMeasure == "Imperial") {
          return this.roomUnits[units.Imperial];
        }
        return this.roomUnits[units.Metric];
      },
    },

    selectedAirflowMeasureUnitKey: {
      get() {
        if (this.$store.getters.projectUnitMeasure == "Imperial") {
          return this.airflowUnits[units.Imperial];
        }
        return this.airflowUnits[units.Metric];
      },
    },

    selectedSystemType: {
      get() {
        return this.$store.getters.selectedSystemType;
      },
      set(newValue) {
        this.handleChangeSystemType(newValue);
      },
    },

    selectedRoomCondition: {
      get() {
        return this.$store.getters.selectedRoomCondition;
      },
      set(newValue) {
        this.handleChangeRoomCondition(newValue);
      },
    },

    roomValue_: {
      get() {
        return this.$store.getters.roomValue;
      },
      set(newValue) {
        this.$store.commit("changeRoomValue", newValue);
      },
    },

    recommendedAirflowValue: {
      get() {
        return this.$store.getters.recommendedAirflow;
      },
      set(newValue) {
        this.$store.commit("changeRecommendedAirflow", newValue);
      },
    },
  },

  async mounted() {
    this.handleRecommendedAirChangesPerHourChange();
    const imperial = this.$options.filters.roundFormatter(
      this.$options.filters.unitConvertor(this.airflow, this.$imperials.AIRFLOW)
    );

    if (this.airflowValueImperial === "") {
      this.$store.commit("changeAirflowImperial", imperial);
    }
  },

  methods: {
    handleOptionChange(selectedKey) {
      // this.selectedAirflowType = selectedKey;
      this.$store.commit("selectedAirflowType", selectedKey);
      if (this.selectedAirflowType === "available") {
        this.$store.commit("resetCalculation", "");
      }
    },

    handleAirflowValueChange(newAirflow) {
      if (!isNaN(newAirflow)) {
        this.$store.commit(
          "changeAirflowImperial",
          newAirflow * this.$imperialConstants[this.$imperials.AIRFLOW]
        );
        this.$store.commit("changeAirflow", newAirflow);
        this.$store.dispatch("updateMasterlistCalcs", newAirflow);
      }
    },

    handleAirflowImperialValueChange(newAirflowImperial) {
      this.$store.commit(
        "changeAirflow",
        newAirflowImperial * this.$metricConstants[this.$metrics.AIRFLOW]
      );
      this.$store.commit("changeAirflowImperial", newAirflowImperial);
    },

    handleRecommendedAirChangesPerHourChange() {
      this.$store.commit(
        "changeRecommendedAirChangesPerHour",
        this.$store.getters.systemTypeFactor *
          this.$store.getters.roomConditionChanges
      );

      this.handleRecommendedAirflowValueChange();
    },

    handleRecommendedAirflowValueChange() {
      if (this.$store.getters.projectUnitMeasure === "Imperial") {
        this.$store.commit(
          "changeRecommendedAirflow",
          (this.roomValue_ * this.$store.getters.recommendedAirChangesPerHour) /
            60
        );
      } else {
        this.$store.commit(
          "changeRecommendedAirflow",
          this.roomValue_ * this.$store.getters.recommendedAirChangesPerHour
        );
      }

      if (
        this.recommendedAirflowValue !== 0 &&
        !isNaN(this.recommendedAirflowValue) &&
        this.$store.getters.projectUnitMeasure === "Metric"
      ) {
        this.handleAirflowValueChange(this.recommendedAirflowValue);
      }
      if (
        this.recommendedAirflowValue !== 0 &&
        !isNaN(this.recommendedAirflowValue) &&
        this.$store.getters.projectUnitMeasure === "Imperial"
      ) {
        this.handleAirflowImperialValueChange(this.recommendedAirflowValue);
      }
    },

    handleRoomValueChange(newRoomValue) {
      this.$store.commit("changeRoomValue", newRoomValue);
      this.handleRecommendedAirflowValueChange();
    },

    handleChangeSystemType(newSystemType) {
      this.$store.commit("changeSelectedSystemType", newSystemType);
      this.handleRecommendedAirChangesPerHourChange();
    },

    handleChangeRoomCondition(newRoomCondition) {
      this.$store.commit("changeSelectedRoomCondition", newRoomCondition);
      this.handleRecommendedAirChangesPerHourChange();
    },
  },
};
</script>
<style lang="scss" scoped>
.airflow-form {
  display: flex;
  align-items: flex-end;
}

::v-deep .fft-dropdown .v-input__control {
  width: 7rem !important;
}

.column-flex {
  display: flex;
  flex-direction: column;
}

.vertical-align {
  height: 30px;
}
</style>
