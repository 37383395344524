<template>
  <div class="d-flex flex-column">
    <span class="custom-subtitle bold">
      Removal capacity by media type [% w/w]
    </span>

    <v-data-table
      :headers="allFitsHeaders"
      :items="removalCapacity"
      class="base-table text-center"
      hide-default-footer
    />

    <div class="mt-8">
      <span class="custom-subtitle bold">
        Weight of media required to remove the gases [{{
          selectedWeightUnitMeasure
        }}/year]
      </span>
      <v-data-table
        :headers="allFitsHeaders"
        :items="weightOfMedia"
        class="base-table text-center"
        hide-default-footer
      >
      </v-data-table>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import _ from "lodash";

import { units } from "../../constants/measuringUnits.js";
import { allFitsHeaders } from "../../constants/gasAndMedia/allFitsHeaders.js";

export default {
  created() {},
  name: "AllFits",

  data() {
    return {
      weightUnits: {
        [units.Metric]: {
          text: "kg",
        },
        [units.Imperial]: {
          text: "lbs",
        },
      },
      allFitsHeaders,
    };
  },

  computed: {
    ...mapGetters(["removalCapacity", "projectUnitMeasure"]),

    selectedWeightUnitMeasure: {
      get() {
        if (this.projectUnitMeasure === "Imperial") {
          return this.weightUnits[units.Imperial].text;
        }
        return this.weightUnits[units.Metric].text;
      },
    },

    weightOfMedia: {
      get() {
        const weightOfMedia = this.$store.getters.weightOfMedia;

        return this.updateWeightOfMedia(weightOfMedia);
      },
    },
  },

  methods: {
    calculateMediaWeight(item) {
      return item !== ""
        ? this.projectUnitMeasure === "Metric"
          ? item
          : (item * this.$imperialConstants[this.$imperials.MASS]).toFixed(1)
        : "";
    },

    /**
     * iterates over each gas and its media types and updates media types weights according to the selected unit measure
     * @param {array}  - selected gases
     * @returns {array}
     */
    updateWeightOfMedia(array) {
      const updatedWeightOfMedia = [];

      array.forEach((item) => {
        const updatedItem = _.clone(item);

        Object.keys(item).forEach((key) => {
          if (key !== "gasName" && key !== "gasIndex") {
            updatedItem[key] = this.calculateMediaWeight(item[key]);
          }
        });

        updatedWeightOfMedia.push(updatedItem);
      });

      return updatedWeightOfMedia;
    },
  },
};
</script>
