<template>
  <div class="table-grid" :style="this.containerGridTemplate">
    <div
      v-for="column in columns"
      :key="column.key"
      :style="{
        backgroundColor: column.color,
        color: column.fontColor,
        fontWeight: column.type,
      }"
      class="text-center"
    >
      {{ column.name }}
    </div>
    <template v-for="rowsArray in rows">
      <div v-for="row in rowsArray" :key="row.id" class="text-center">
        {{ row.value }}
      </div>
    </template>
  </div>
</template>
<script>
export default {
  name: "BasicTable",

  props: {
    columns: Array,
    rows: Array,
    columnsNumber: Number,
  },

  computed: {
    containerGridTemplate() {
      const grid = Array(this.columnsNumber).fill("minmax(0, 1fr)");
      this.columns?.forEach((col, index) => {
        if (col.width) {
          grid[index] = col.width;
        }
      });
      return {
        gridTemplateColumns: grid.join(" "),
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.table-grid {
  display: grid;
  border-right: $base-border;
  border-top: $base-border;
}

.table-grid div {
  border-left: $base-border;
  border-bottom: $base-border;
  padding: 0.3rem;
}
</style>
