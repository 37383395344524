import { render, staticRenderFns } from "./AirflowDefinition.vue?vue&type=template&id=58ef21dc&scoped=true&"
import script from "./AirflowDefinition.vue?vue&type=script&lang=js&"
export * from "./AirflowDefinition.vue?vue&type=script&lang=js&"
import style0 from "./AirflowDefinition.vue?vue&type=style&index=0&id=58ef21dc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58ef21dc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VSelect})
