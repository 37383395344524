import axios from "axios";
import projectUnitMeasure from "../modules/projectData";
const state = {
  airflowDefinition: {
    roomValue: "",
    roomValueSelectedUnitMeasure: "",
    airflow: "",
    selectedAirflowMeasureUnitKey: "",
    selectedSystemType: {
      systemName: "",
      factor: "",
      id: "",
    },
    selectedRoomCondition: {
      conditionName: "",
      changesPerHour: "",
      id: "",
    },
    selectedAirflowType: "available",
  },
  airflowImperial: "",
  recommendedAirflow: "",
  recommendedAirChangesPerHour: "",
  airflowUnits: [],
  roomUnit: [],
  roomConditions: [],
  systemTypes: [],
};

const getters = {
  airflow: (state) => state.airflowDefinition.airflow,
  airflowImperial: (state) => state.airflowImperial,
  selectedAirflowType: (state) => state.airflowDefinition.selectedAirflowType,
  selectedAirflowMeasureUnitKey: (state) =>
    state.airflowDefinition.selectedAirflowMeasureUnitKey,
  roomValue: (state) => state.airflowDefinition.roomValue,
  roomValueSelectedUnitMeasure: (state) =>
    state.airflowDefinition.roomValueSelectedUnitMeasure,
  selectedRoomCondition: (state) =>
    state.airflowDefinition.selectedRoomCondition != null
      ? state.airflowDefinition.selectedRoomCondition.id
      : null,
  selectedSystemType: (state) =>
    state.airflowDefinition.selectedSystemType != null
      ? state.airflowDefinition.selectedSystemType.id
      : null,
  recommendedAirflow: (state) => state.recommendedAirflow,
  allAirflowUnits: (state) => state.airflowUnit,
  allRoomUnits: (state) => state.roomUnit,
  allRoomConditions: (state) => state.roomConditions,
  allSystemTypes: (state) => state.systemTypes,
  recommendedAirChangesPerHour: (state) => state.recommendedAirChangesPerHour,
  systemTypeFactor: (state) =>
    state.airflowDefinition.selectedSystemType != null
      ? state.airflowDefinition.selectedSystemType.factor
      : 0,
  roomConditionChanges: (state) =>
    state.airflowDefinition.selectedRoomCondition != null
      ? state.airflowDefinition.selectedRoomCondition.changesPerHour
      : 0,
};

const mutations = {
  changeAirflowDefinition(state, airflowDefinition) {
    state.airflowDefinition.airflow = airflowDefinition?.airflow;
    state.airflowDefinition.roomValue = airflowDefinition?.roomValue;
    state.airflowDefinition.selectedAirflowMeasureUnitKey =
      airflowDefinition?.airflowUnit;
    state.airflowDefinition.roomValueSelectedUnitMeasure =
      airflowDefinition?.roomUnit;
    state.airflowDefinition.selectedSystemType = state.systemTypes.find(
      (value) => value.id === airflowDefinition?.systemTypeId
    );
    state.airflowDefinition.selectedRoomCondition = state.roomConditions.find(
      (value) => value.id === airflowDefinition?.roomConditionId
    );
    if (airflowDefinition && airflowDefinition.type !== "")
      state.airflowDefinition.selectedAirflowType = airflowDefinition.type;
  },
  changeAirflow(state, airflow) {
    state.airflowDefinition.airflow = airflow;
  },
  changeAirflowImperial(state, airflowImperial) {
    state.airflowImperial = airflowImperial;
  },
  changeRoomValue(state, roomValue) {
    state.airflowDefinition.roomValue = roomValue;
  },
  changeSelectedAirflowMeasureUnitKey(state, selectedAirflowMeasureUnitKey) {
    state.airflowDefinition.selectedAirflowMeasureUnitKey =
      selectedAirflowMeasureUnitKey;
  },
  changeRoomValueSelectedUnitMeasure(state, roomValueSelectedUnitMeasure) {
    state.airflowDefinition.roomValueSelectedUnitMeasure =
      roomValueSelectedUnitMeasure;
  },
  changeSelectedSystemType(state, selectedSystemType) {
    state.airflowDefinition.selectedSystemType = state.systemTypes.find(
      (value) => value.id === selectedSystemType
    );
  },
  changeRecommendedAirChangesPerHour(state, recommendedAirChangesPerHour) {
    state.recommendedAirChangesPerHour = recommendedAirChangesPerHour;
  },
  changeSelectedRoomCondition(state, selectedRoomCondition) {
    state.airflowDefinition.selectedRoomCondition = state.roomConditions.find(
      (value) => value.id === selectedRoomCondition
    );
  },
  changeRecommendedAirflow(state, recommendedAirflow) {
    state.recommendedAirflow = recommendedAirflow;
  },
  resetCalculation(state, newValue) {
    if (
      state.recommendedAirflow !== "" &&
      state.recommendedAirflow !== 0 &&
      projectUnitMeasure === "Metric"
    ) {
      state.airflowDefinition.airflow = state.recommendedAirflow;
    }
    if (
      state.recommendedAirflow !== "" &&
      state.recommendedAirflow !== 0 &&
      projectUnitMeasure === "Imperial"
    ) {
      state.airflowImperial = state.recommendedAirflow;
    }
    state.recommendedAirflow = newValue;
    state.airflowDefinition.roomValue = newValue;
    state.airflowDefinition.selectedSystemType = {
      systemName: "",
      factor: "",
      id: "",
    };
    state.airflowDefinition.selectedRoomCondition = {
      conditionName: "",
      changesPerHour: "",
      id: "",
    };
    state.recommendedAirChangesPerHour = newValue;
  },
  setAirflowUnitsList(state, payload) {
    state.airflowUnit = payload;
  },
  setRoomUnitsList(state, payload) {
    state.roomUnit = payload;
  },
  setRoomConditionsList(state, payload) {
    state.roomConditions = payload;
  },
  setSystemTypesList(state, payload) {
    state.systemTypes = payload;
  },
  selectedAirflowType(state, payload) {
    state.airflowDefinition.selectedAirflowType = payload;
  },
};

const actions = {
  // getAirflowUnitsList(context) {
  //   context.commit("setAirflowUnitsList", allAirflowUnits);
  // },

  // getRoomUnitsList(context) {
  //   context.commit("setRoomUnitsList", allRoomUnits);
  // },

  async getRoomConditionsList(context) {
    try {
      const res = await axios.get("/api/room-conditions");
      if (context.state.roomConditions.length === 0) {
        context.commit("setRoomConditionsList", res.data);
      }
    } catch (err) {
      console.log(err, "err");
    }
  },

  async getSystemTypesList(context) {
    try {
      const res = await axios.get("/api/system-types");
      if (context.state.systemTypes.length === 0) {
        context.commit("setSystemTypesList", res.data);
      }
    } catch (err) {
      console.log(err, "err");
    }
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
