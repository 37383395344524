import { PublicClientApplication } from "@azure/msal-browser";
import axios from "axios";
import Vue from "vue";
import Vuex from "vuex";
import { allSteps } from "../constants/steps.js";
import airflowDefinition from "./modules/airflowDefinition";
import commercialComparison from "./modules/commercialComparison";
import catalogData from "./modules/dashboard/catalogData";
import userAdmin from "./modules/dashboard/userAdmin";
import gasAndMediaSelection from "./modules/gasAndMediaSelection";
import projectData from "./modules/projectData";
import resetComparisonOptions from "./modules/resetData/resetComparisonOptions";
import resetDataCommercial from "./modules/resetData/resetDataCommercial";
import resetGasAndMedia from "./modules/resetData/resetDataGasAndMedia";
import resetDataTechnical from "./modules/resetData/resetDataTechnical";
import systemOptions from "./modules/systemOptions";
import technicalComparison from "./modules/technicalComparison";

Vue.use(Vuex);

export const defaultOptions = [
  { id: 0, title: "A" },
  { id: 1, title: "B" },
  { id: 2, title: "C" },
  { id: 3, title: "D" },
];

export const store = new Vuex.Store({
  modules: {
    projectData,
    airflowDefinition,
    technicalComparison,
    gasAndMediaSelection,
    commercialComparison,
    systemOptions,
    catalogData,
    userAdmin,
    resetDataTechnical,
    resetDataCommercial,
    resetGasAndMedia,
    resetComparisonOptions,
  },
  state: {
    projects: [],
    selectedProject: {},
    customers: [],
    locations: [],
    segmentList: [],
    branchList: [],
    projectTypes: [],
    filters: {},
    filteredProjects: [],
    externalViewSwitch: true,
    steps: allSteps,
    options: defaultOptions,
    msalConfig: {
      auth: {
        clientId: "4237b826-11e2-4a2c-8f5c-ae27fac810a1", //process.env.VUE_APP_AD_CLIENT_ID,
        authority:
          "https://login.microsoftonline.com/" +
          "c7b07781-06f3-41d7-b40f-5b2de1018509" +
          "/",
        postLogoutRedirectUri: window.location.origin,
      },
      cache: {
        cacheLocation: "localStorage",
      },
    },
    accessToken: "",
    selectedLayouts: [],
    masterlist: [],
    dwgsCosts: [],
    editMode: false,
    account: undefined,
    projectHasChanges: false,
  },

  getters: {
    projects: (state) => state.projects,
    selectedProject: (state) => state.selectedProject,
    customers: (state) => state.customers,
    locations: (state) => state.locations,
    segmentList: (state) => state.segmentList,
    branchList: (state) => state.branchList,
    projectTypes: (state) => state.projectTypes,
    filters: (state) => state.filters,
    filteredProjects: (state) => state.filteredProjects,
    layouts: (state) => state.layouts,
    options: (state) => state.options,
    externalViewSwitch: (state) => state.externalViewSwitch,
    steps: (state) => state.steps,
    selectedLayouts: (state) => state.selectedLayouts,
    masterlist: (state) => state.masterlist,
    dwgsCosts: (state) => state.dwgsCosts,
    editMode: (state) => state.editMode,
    account: (state) => {
      // not the safest, but a workaround for development mode
      // where there is no access to AD
      if (!state || !state.account) {
        return { localAccountId: "ALL" };
      }

      return state.account;
    },
    accessToken: (state) => state.accessToken,
    projectHasChanges: (state) => state.projectHasChanges,
  },

  mutations: {
    setProjectChangesState(state, payload) {
      state.projectHasChanges = payload;
    },

    setAccount(state, payload) {
      state.account = payload;
    },

    setEditModeState(state, payload) {
      state.editMode = payload;
    },

    setAccessToken(state, token) {
      state.accessToken = token;
    },
    addProject(state, payload) {
      state.projects.push(payload);
    },

    fetchProjects(state, payload) {
      state.projects = payload;
    },

    updateProject(state, payload) {
      const projectIndex = state.projects.findIndex(
        (project) => project.projectId === payload.projectId
      );

      state.projects[projectIndex] = payload;
    },

    setSelectedProject(state, payload) {
      state.selectedProject = payload;
    },

    setCustomers(state, payload) {
      state.customers = payload;
    },

    setLocations(state, payload) {
      state.locations = payload;
    },

    setProjectTypes(state, payload) {
      state.projectTypes = payload;
    },

    setSegments(state, payload) {
      state.segmentList = payload;
    },

    setBranches(state, payload) {
      state.branchList = payload;
    },

    setFilters(state, list) {
      if (list.payload) {
        state.filters = list.payload;
      }

      const customerFilter =
        state.filters.customer && state.filters.customer.length > 0
          ? state.filters.customer
          : false;
      const locationFilter =
        state.filters.location && state.filters.location.length > 0
          ? state.filters.location
          : false;
      const segmentFilter =
        state.filters.segmentName && state.filters.segmentName.length > 0
          ? state.filters.segmentName
          : false;
      const branchFilter =
        state.filters.branch && state.filters.branch.length > 0
          ? state.filters.branch
          : false;
      const projectTypeFilter =
        state.filters.projectType && state.filters.projectType.length > 0
          ? state.filters.projectType
          : false;

      const filteredProjects = state.projects.filter(
        ({ customer, location, segmentId, projectType, regionId }) => {
          return (
            (customerFilter
              ? customerFilter.find((item) => item.customer === customer)
              : true) &&
            (locationFilter
              ? locationFilter.find((item) => item.location === location)
              : true) &&
            (segmentFilter
              ? segmentFilter.find((item) => item.id === segmentId)
              : true) &&
            (projectTypeFilter
              ? projectTypeFilter.find(
                  (item) => item.projectType === projectType
                )
              : true) &&
            (branchFilter
              ? branchFilter.find((item) => item.id === regionId)
              : true)
          );
        }
      );

      state.filteredProjects = filteredProjects;
    },

    changeOptions(state, payload) {
      if (payload.action === "remove") {
        state.options = state.options.filter(
          (option) => option.title !== payload.value
        );
      } else if (payload.action === "add") {
        state.options.push({ id: payload.id, title: payload.value });
        state.options.sort((a, b) =>
          a.title > b.title ? 1 : b.title > a.title ? -1 : 0
        );
      }
    },

    setExternalView(state, payload) {
      state.externalViewSwitch = payload;
    },

    setSteps(state, payload) {
      state.steps = payload;
    },

    addSelectedLayouts(state, payload) {
      if (!payload || !state.masterlist || !state.masterlist.length) {
        return;
      }

      const newSL = state.selectedLayouts.filter(
        (layout) => layout?.option !== ""
      );
      state.selectedLayouts = newSL;
      state.masterlist.forEach((item) => {
        if (item.option === payload.option) {
          item.option = "";
        }
      });
      const layout = state.masterlist.find(
        (item) => item.id === payload.itemId
      );
      layout.option = payload.option;

      state.selectedLayouts.push(layout);
    },

    removeSelectedLayout(state, payload) {
      const newSL = state.selectedLayouts.filter(
        (layout) => layout.id !== payload.itemId
      );

      state.selectedLayouts = newSL;
    },

    setMasterlist(state, payload) {
      const airflow = payload.airflow;

      if (airflow === "") {
        state.masterlist = payload.data;
      } else {
        state.masterlist = payload.data.map((item) => {
          let airFlowPerElement = airflow / item.elementsPerStage;
          let faceVelocityAtBed =
            airFlowPerElement / item.bedAreaPerElem / 3600;
          return {
            ...item,
            airFlowPerElement: airFlowPerElement,
            faceVelocityAtBed: faceVelocityAtBed,
            residenceTimePerBed:
              item.bedDepthPerElem / 1000 / faceVelocityAtBed,
          };
        });
      }
    },

    // setCasingMaterials(state, payload) {
    //   state.casingMaterialsData = payload;
    // },

    setDwgsCosts(state, payload) {
      state.dwgsCosts = payload;
    },

    resetOptions(state) {
      state.options = defaultOptions;
      state.selectedLayouts = [];
    },

    updateMasterlist(state, payload) {
      state.masterlist = payload;
    },
  },

  actions: {
    updateEditModeState({ commit }, payload) {
      commit("setEditModeState", payload);
    },

    async fetchProjects({ commit, getters }) {
      await axios
        .get("/api/projects", {
          params: { context: getters.account.localAccountId },
        })
        .then((res) => {
          commit("fetchProjects", res.data);
        })
        .catch((err) => {
          throw err;
        });
    },

    async fetchProjectsStatistics({ commit }) {
      await axios
        .get("/api/projects-admin")
        .then((res) => {
          commit("fetchProjects", res.data);
        })
        .catch((err) => {
          throw err;
        });
    },

    initialDataLoad(context) {
      context.dispatch("getSystemTypesList");
      context.dispatch("getRoomConditionsList");
      context.dispatch("fetchMasterlist");
      context.dispatch("fetchCapacityData");
      context.dispatch("fetchDwgsCosts");
      context.dispatch("getRegionsList");
    },

    addProject(context, project) {
      context.commit("addProject", project);
    },

    updateProject(context, project) {
      context.commit("updateProject", project);
    },

    resetSelectedProject(context) {
      context.dispatch("setSelectedProject", {
        airflowDefinition: {
          roomValue: "",
          roomUnit: "",
          airflow: "",
          airflowUnit: "",
          type: "",
          systemTypeId: "",
        },
        gases: [],
        options: defaultOptions,
        airflowUnit: "",
        roomConditionId: "",
        roomUnit: "",
        roomValue: "",
        systemTypeId: "",
        type: "",
        createdOn: "",
        customer: "",
        location: "",
        projectId: "",
        projectName: "",
        projectType: "",
        segmentId: "",
        status: "",
        unitOfMeasure: "",
        branchRegion: "",
        branchId: "",
        id: "",
      });

      context.commit("setSelectedProject", {});

      context.dispatch("resetAllGasValues");
      context.commit("resetOptions");
    },

    setObject(context, project) {
      try {
        context.commit("setSelectedProject", project);
        context.commit("changeProject", project);
        context.commit("changeAirflowDefinition", project.airflowDefinition);
        context.commit("setOpHours", project.operatingHours);
        context.commit("setOpDays", project.operatingDays);
        context.commit("setOpWeeks", project.operatingWeeks);
        context.commit("changeSelectedInletType", project.inletType);
        context.commit("changeSelectedOutletType", project.outletType);
        context.commit("changeSelectedPositionInlet", project.inletPosition);
        context.commit("changeSelectedPositionOutlet", project.outletPosition);
        context.commit("changeSelectedPd", project.pdMeasurement);
        context.dispatch("updateSelectedUnitSize", project.selectedUnit);
        context.commit("setUnitSelectedOption", project.selectedUnit);
        context.commit("setBtsFactor", project.breakThroughSafetyFactor || 85);
        context.commit("resetOptions");
        context.dispatch("updateExchangeRate", project.exchangeRate);
        context.dispatch("setRemarks", project.remarks);
        if (project.selectedCurrency) {
          context.dispatch("updateSelectedCurrency", project.selectedCurrency);
        }

        context.dispatch("setSelectedGases", project.gases);

        project.options.forEach((option) => {
          const masterlistItem = context.getters.masterlist.find(
            (item) => item.id === option.itemId
          );

          if (!option.title) {
            context.dispatch("setIsOptionSelected", {
              isSelected: true,
              option: option.option,
            });

            const concatenate = [
              masterlistItem?.unitModel,
              option.casingMaterial,
            ].join("-");

            context.dispatch("setSelectedCassingMaterials", {
              option: option.option,
              value: concatenate,
            });

            context.dispatch("setCode", {
              value: [masterlistItem?.unitModel, option.casingMaterial],
              option: option.option,
            });

            context.commit(
              `changeSelectedCasingMaterial${option.option}`,
              option.casingMaterial
            );
            context.commit("changeOptions", {
              value: option.option,
              action: "remove",
            });
            context.commit("addSelectedLayouts", option);

            //set Media
            if (option.stage1Media) {
              context.dispatch("calculateMediaCapacity", {
                mediaTypeId: option.stage1Media,
                option: option.option,
                stage: "stage1",
                stageIndex: 1,
              });
            }

            if (option.stage2Media) {
              context.dispatch("calculateMediaCapacity", {
                mediaTypeId: option.stage2Media,
                option: option.option,
                stage: "stage2",
              });
            }

            if (option.stage3Media) {
              context.dispatch("calculateMediaCapacity", {
                mediaTypeId: option.stage3Media,
                option: option.option,
                stage: "stage3",
              });
            }

            if (option.stage4Media) {
              context.dispatch("calculateMediaCapacity", {
                mediaTypeId: option.stage4Media,
                option: option.option,
                stage: "stage4",
              });
            }
            //set Filters
            context.dispatch("setComparisonOptions", {
              option: `option${option.option}`,
              comparisonItem: "prefilterModel",
              value: option.preFilter,
            });

            context.dispatch("updateFilterImg", {
              value: option.preFilter,
              comparisonItem: "prefilterImgUrl",
              option: `option${option.option}`,
            });

            context.dispatch("updateFiltersClass", {
              value: option.preFilter,
              comparisonItem: "prefilterClass",
              option: `option${option.option}`,
            });

            context.dispatch("setComparisonOptions", {
              option: `option${option.option}`,
              comparisonItem: "finalFilterModel",
              value: option.finalFilter,
            });

            context.dispatch("updateFiltersClass", {
              value: option.finalFilter,
              comparisonItem: "finalFilterClass",
              option: `option${option.option}`,
            });

            context.dispatch("updateFilterImg", {
              value: option.finalFilter,
              comparisonItem: "finalImgUrl",
              option: `option${option.option}`,
            });

            // residence
            context.dispatch("updateTotalResidenceTimePerUnit", {
              option: `option${option.option}`,
              item: option.option,
            });
          }
        });
        return project;
      } catch (err) {
        console.log(err, "err");
      }
    },

    setSelectedProject(context, param) {
      if (typeof param === "object") {
        context.dispatch("setObject", param);
      } else {
        axios.get("/api/project/" + param).then(
          (project) => {
            context.dispatch("setObject", project.data);
          },
          (error) => {
            console.log(error);
          }
        );
      }
    },

    async copyProject(context, param) {
      axios.get("/api/copy-project/" + param).then(
        (project) => {
          context.dispatch("setObject", project.data);
        },
        (error) => {
          console.log(error);
        }
      );
    },

    getCustomers(context) {
      const { projects } = context.getters;
      const customers = [];
      projects.forEach((item) => {
        if (item.customer !== "") {
          customers.push({ id: item.projectId, customer: item.customer });
        }
      });
      context.commit("setConstructionModel", customers);
      return customers;
    },

    getLocations(context) {
      const { projects } = context.getters;
      const locations = [];
      projects.forEach((item) => {
        if (item.location !== "") {
          locations.push({ id: item.projectId, location: item.location });
        }
      });
      context.commit("setLocations", locations);
      return locations;
    },

    getProjectTypes(context) {
      const { projects } = context.getters;
      const projectTypes = [];
      projects.forEach((item) => {
        if (item.projectType !== "") {
          projectTypes.push({
            id: item.projectId,
            projectType: item.projectType,
          });
        }
      });
      context.commit("setProjectTypes", projectTypes);
      return projectTypes;
    },

    getSegments(context) {
      const { projects, segments } = context.getters;
      const segmentsFiltered = [];
      projects.forEach((item) => {
        if (item.segmentId !== "") {
          segmentsFiltered.push({
            projectId: item.projectId,
            id: item.segmentId,
            segmentName: segments.find(
              (segment) => segment.id === item.segmentId
            )?.segmentName,
          });
        }
      });
      context.commit("setSegments", segmentsFiltered);
      return segmentsFiltered;
    },

    getBranches(context) {
      const { projects, regions } = context.getters;
      const branchesFiltered = [];
      projects.forEach((item) => {
        if (item.regionId !== "") {
          branchesFiltered.push({
            projectId: item.projectId,
            id: item.regionId,
            branch: regions.find((branch) => branch.id === item.regionId)
              ?.branch,
          });
        }
      });
      context.commit("setBranches", branchesFiltered);
      return branchesFiltered;
    },

    setFilters(context, payload) {
      const { projects } = context.getters;
      const list = { payload: payload, projects: projects };
      context.commit("setFilters", list);
    },

    filterOptions(context, payload) {
      context.commit("changeOptions", payload);
    },

    setExternalView(context, payload) {
      context.commit("setExternalView", payload);
    },

    changeStepsVisibility(context, payload) {
      const filteredSteps = context.getters.steps;
      filteredSteps[5].isHidden = payload;
      context.commit("setSteps", filteredSteps);
    },

    resetSteps(context) {
      const resetedSteps = context.getters.steps;

      resetedSteps.forEach((step) => {
        step.isActive = false;
      });

      context.commit("setSteps", resetedSteps);
    },

    addSelectedLayouts(context, payload) {
      context.commit("addSelectedLayouts", payload);
    },

    removeSelectedLayout(context, payload) {
      context.commit("removeSelectedLayout", payload);
    },

    async fetchMasterlist(context) {
      try {
        const res = await axios.get("/api/masterlist");
        context.commit("setMasterlist", {
          data: res.data,
          airflow: context.getters.airflow,
        });
        return res.data;
      } catch (err) {
        console.log(err, "err");
      }
    },

    updateMasterlistCalcs({ getters, state, commit }) {
      const airflow = getters.airflow;

      if (airflow === "") {
        return;
      } else {
        let newMasterlist = state.masterlist.map((item) => {
          let airFlowPerElement = airflow / item.elementsPerStage;
          let faceVelocityAtBed =
            airFlowPerElement / item.bedAreaPerElem / 3600;
          return {
            ...item,
            airFlowPerElement: airFlowPerElement,
            faceVelocityAtBed: faceVelocityAtBed,
            residenceTimePerBed:
              item.bedDepthPerElem / 1000 / faceVelocityAtBed,
          };
        });

        commit("updateMasterlist", newMasterlist);
      }
    },

    async fetchDwgsCosts({ getters, commit }) {
      try {
        const region = getters.projectRegion;
        const res = await axios.get("/api/dwgs-costs", {
          params: { branch: region },
        });

        commit("setDwgsCosts", res.data);
        return res.data;
      } catch (err) {
        console.log(err, "err");
      }
    },

    async persist({ state, commit, getters }) {
      let res;
      try {
        const requestBody = {
          ...state.projectData.project,
          ...state.airflowDefinition.airflowDefinition,
          opHours: state.gasAndMediaSelection.opHours,
          opDays: state.gasAndMediaSelection.opDays,
          opWeeks: state.gasAndMediaSelection.opWeeks,
          selectedUnit: state.technicalComparison.unitSelectedOption,
          gasAndMedia: state.gasAndMediaSelection.selectedGases,
          allOptions: state.options.map((op) => op.title),
          createdBy: getters.account.localAccountId,
          options: state.selectedLayouts.map((layout) => {
            let stages = Object.keys(state.technicalComparison.stages).map(
              (stage) => {
                return state.technicalComparison.stages[stage][
                  `option${layout.option}`
                ];
              }
            );
            const prefilter =
              state.technicalComparison.comparisonOptions[
                `option${layout.option}`
              ].prefilterModel;

            const finalFilter =
              state.technicalComparison.comparisonOptions[
                `option${layout.option}`
              ].finalFilterModel;

            const casingMaterial =
              state.technicalComparison[
                `selectedCasingMaterial${layout.option}`
              ];

            return {
              option: layout.option,
              id: layout.id,
              stages: stages,
              preFilter: prefilter,
              finalFilter: finalFilter,
              casingMaterial: casingMaterial,
            };
          }),
          unit: state.technicalComparison.unitSelectedOption,
          btsFactor: state.technicalComparison.btsFactor,
          inletType: state.technicalComparison.selectedInletType,
          outletType: state.technicalComparison.selectedOutletType,
          pdMeasurement: state.technicalComparison.selectedPd,
          inletPosition: state.technicalComparison.selectedPositionInlet,
          outletPosition: state.technicalComparison.selectedPositionOutlet,
          remarks: state.technicalComparison.remarks,
          exchangeRate: state.commercialComparison.exchangeRate,
          selectedCurrency: state.commercialComparison.selectedCurrency,
        };

        if (requestBody.projectId) {
          res = await axios.put("/api/project", requestBody);
        } else {
          res = await axios.post("/api/project", requestBody);
        }

        commit("changeProjectId", res);
      } catch (err) {
        console.log(err, "err", err.response.data);
        if (err.response && err.response.data) {
          throw err.response.data;
        }

        throw err;
      }

      return res;
    },

    async updateAccount({ commit, dispatch }, payload) {
      await dispatch("postForUser", payload)
        .then(() => {
          commit("setAccount", payload);
        })
        .catch((err) => {
          console.log(err, "err");
          throw err;
        });
    },

    updateProjectChangesState({ commit }, payload) {
      commit("setProjectChangesState", payload);
    },

    async getAccessToken({ state, commit }, account = undefined) {
      let request = {
        scopes: ["api://1ba683e6-8947-4a59-85ef-9ce6d797e604/fft-api-access"],
        account: account ? account : state.account,
      };
      const msalInstance = new PublicClientApplication(state.msalConfig);
      try {
        await msalInstance
          .acquireTokenSilent(request)
          .then((response) => {
            console.log("Successful acquire");
            commit("setAccessToken", response.accessToken);

            axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${state.accessToken}`;
          })
          .catch(async (error) => {
            console.error(error);
            console.error(
              "Silent token acquisition failed. Using interactive mode"
            );
            let tokenResponse = await msalInstance.acquireTokenPopup(request);
            console.log(
              `Access token acquired via interactive auth ${tokenResponse.accessToken}`
            );
            commit("setAccessToken", tokenResponse.accessToken);
          });
      } catch (error) {
        console.error(error);
      }
    },
  },
});
