import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import handleError from "../../../constants/handleError.js";

Vue.use(Vuex);

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async getData(context, table) {
    console.log(context);
    try {
      const response = await axios.get(`/api/${table}-admin`);
      return response.data;
    } catch (err) {
      console.log(err, "err");
    }
  },

  async dataPersist(context, payload) {
    console.log(context);
    try {
      let response = undefined;
      if (
        payload.rowModel["id"] === undefined ||
        payload.rowModel["id"] === ""
      ) {
        response = await axios.post(`/api/${payload.table}`, payload.rowModel);
      } else {
        response = await axios.put(`/api/${payload.table}`, payload.rowModel);
      }
      return response.data;
    } catch (err) {
      const errorMessage = handleError(err)?.data;
      return errorMessage;
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
