<template>
  <div class="d-flex helper-container">
    <v-icon> mdi-help-circle </v-icon>
    <div class="ml-2">{{ text }}</div>
  </div>
</template>
<script>
export default {
  name: "HelperBox",

  props: {
    text: String,
  },
};
</script>
<style lang="scss" scoped>
.helper-container {
  background-color: $helper-container-blue;
  padding: 0.4rem;
}

.helper-container .v-icon {
  color: $accent-blue;
}
</style>
